import React from 'react';

const SurveyRating1to5 = ({
  description,
  leftDescription,
  rightDescription,
  index,
  options,
  leftWidth,
  textAlign,
  setAnswerData,
  answerData,
}) => {
  const handleChange = (option) => {
    const existingIndex = answerData.answers.findIndex(
      (answer) => answer.survey_question_id === index
    );

    if (existingIndex !== -1) {
      // If an existing answer is found, update its option_id
      const updatedAnswers = [...answerData.answers];
      updatedAnswers[existingIndex] = {
        ...updatedAnswers[existingIndex],
        option_id: option.id,
      };
      setAnswerData({ ...answerData, answers: updatedAnswers }); // Update only the answers array
    } else {
      // If no existing answer is found, add a new answer to the array
      const newAnswer = { survey_question_id: index, option_id: option.id };
      setAnswerData({
        ...answerData,
        answers: [...answerData.answers, newAnswer],
      }); // Add the new answer to the answers array
    }
  };

  // console.log('options', options);

  return (
    <div key={index}>
      <div className="text-center fw-bold">{description}</div>
      <div className="survey-rating-layout">
        <div></div>
        <div
          style={{
            textAlign: textAlign ? textAlign : 'center',
            width: leftWidth ? leftWidth : 'auto',
          }}
          className="primary-text-color"
        >
          {leftDescription}
        </div>
        <div className="d-flex justify-content-around">
          {options
            ?.sort((a, b) => a.sequence - b.sequence)
            .map((option) => (
              <div key={option.id} className="rating-number-alignment">
                <input
                  type="radio"
                  required
                  value={option.id}
                  name={'rating' + index}
                  onChange={() => handleChange(option)}
                  className="radio-btn"
                />
                <div className="me-1 primary-text-color">{option.details}</div>
              </div>
            ))}
        </div>

        <div style={{ textAlign: 'center' }} className="primary-text-color">
          {rightDescription}
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default SurveyRating1to5;
