import React from 'react';
import { Modal } from 'antd';
import warning from '../images/warning-icon.png';

const ModuleIncomplete = ({ isModalOpen, setIsModalOpen }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <div className="text-center">
        <img
          alt="cert"
          style={{ objectFit: 'contain', width: '100%', height: '60px' }}
          src={warning}
        />

        <div style={{ fontWeight: '700', fontSize: '20px' }} className="mt-4">
          Module Completion Required.
        </div>
        <div className="mt-3">
          You must complete all the modules before
          <br />
          you can access the web-based app.
        </div>

        <div className="text-center">
          <button
            className="custom-button mt-5"
            style={{
              background: '#1B2E48',
              padding: '5px 20%',
              color: '#fff',
            }}
            onClick={() => handleCancel()}
          >
            Ok
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModuleIncomplete;
