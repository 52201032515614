import React, { useEffect } from 'react';
import SurveyHeader from '../components/SurveyHeader';
import { useDispatch } from 'react-redux';
import { checkUserSession } from '../reducer/userReducer';

const SurveyWrapper = ({ children }) => {
  const locationPath = window.location.pathname;
  // console.log('locationPath', locationPath);
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      locationPath.includes('survey/pre') ||
      locationPath.includes('survey/post/')
    ) {
      const token = localStorage.getItem('dlp-hcp-doctor-token');
      // console.log('token', token);
      if (token) {
        dispatch(checkUserSession());
      }
    }
  }, [dispatch, locationPath]);

  return (
    <div className="main-container">
      <SurveyHeader />
      {children}
    </div>
  );
};

export default SurveyWrapper;
