import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import SurveyRating1to5 from '../../../components/SurveyRating1to5';
import { useSelector } from 'react-redux';

function SurveyPreSection6() {
  const navigate = useNavigate();
  const location = useLocation();
  const [answerData, setAnswerData] = useState(location.state?.answerData);
  const [questions, setQuestions] = useState(''); // eslint-disable-line no-unused-vars
  const answerLength = location.state?.answerLength;
  const userData = useSelector((state) => state.user);

  // console.log('userData', userData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/survey/surveys?section=6&type=pre', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const { data } = await response.json();
        if (data.length) {
          setQuestions(data);
        } else {
          setQuestions(data);
        }
      } catch (error) {
        // console.error('Error submitting survey:', error.response.data);
        if (error.response.data) {
          message.error('An error occurred. Please try again.');
        }
      }
    };

    fetchData();
  }, [navigate]);

  // console.log(location.state);

  const submitPreSurvey = async () => {
    try {
      if (answerData.answers.length <= answerLength) {
        message.error('Please select an option to submit');
      } else if (answerData.answers.length < Number(answerLength) + 11) {
        message.error('Please select all options to submit');
      } else {
        const answer = {
          user_id: userData.user?.id,
          ...answerData,
        };
        if (!userData.user?.id) {
          return message.error('Please login to submit survey');
        }
        // console.log('answer', answer);
        const response = await fetch('/api/survey/submit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(answer), // Send answerData as the request body
        });

        // Check if the response is successful (status code 200)
        if (response.ok) {
          message.success('Survey submitted successfully');
          setTimeout(() => {
            navigate('/modules');
          }, 2000);
        } else {
          // If the response is not successful, log the error message
          console.error('Failed to submit survey:', response.statusText);
          if (response.statusText) {
            const err = await response.json();

            if (err) {
              message.error(err.error);
            }
          }
        }
      }
    } catch (error) {
      // Handle any errors that occur during the fetch request
      console.error('Error submitting survey:', error);
      message.error('An error occurred. Please try again.');
    }
  };

  // console.log('question', questions);

  return (
    <div style={{ overflowX: 'auto' }}>
      <div className="margin-big-width mb-5">
        <div className="survey-title pt-5 pb-3">Take a Survey</div>
        <div>
          <div style={{ overflow: 'hidden' }} className="w-100 questions-bg">
            <div className="mx-5">
              <span>6.</span> How often do you provide education to your
              patients with diabetes on the following?
            </div>
            <div className="options" style={{ marginTop: '20px' }}>
              {questions?.length
                ? questions
                    .sort((a, b) => a.sequence - b.sequence)
                    .map((question, idx) => (
                      <div key={idx}>
                        <SurveyRating1to5
                          setAnswerData={setAnswerData}
                          answerData={answerData}
                          index={question.id}
                          description={question.description}
                          options={question.options}
                          leftDescription={'Never'}
                          rightDescription={'Always'}
                        />
                      </div>
                    ))
                : null}
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <button
              onClick={submitPreSurvey}
              className="btn btn-radius btn-yellow-bg px-5 font-weight-boldest dark-blue-title btn-next"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SurveyPreSection6;
