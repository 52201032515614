import axios from 'axios';
import { tokenConfig } from './helperFunc';

const questionApi = {
  getModules: async () =>
    await axios.get('/api/question/modules', tokenConfig()),

  getAssessment: async (query) =>
    await axios.get(
      `/api/question/assessment?course_id=${query}`,
      tokenConfig()
    ),

  getAskTheExperts: async (query) =>
    await axios.get(`/api/question/resource?course_id=${query}`, tokenConfig()),

  courseStatusAction: async (data) =>
    await axios.post('/api/question/courseStatus', data, tokenConfig()),

  materialStatusAction: async (data) =>
    await axios.post('/api/question/materialStatus', data, tokenConfig()),

  answerSubmitAction: async (data) =>
    await axios.post('/api/question/answerSubmit', data, tokenConfig()),

  getModuleCompletion: async () =>
    await axios.get('/api/question/getCompletedModules', tokenConfig()),

  getResources: async (id) =>
    await axios.get(`/api/question/resources?module_id=${id}`, tokenConfig()),

  updateMaterialStatus: async (data) =>
    await axios.post(`/api/question/materialStatus`, data, tokenConfig()),

  getAskTheExpertsPosts: async (id) =>
    await axios.get(
      `/api/question/askTheExperts?course_id=${id}`,
      tokenConfig()
    ),

  submitPost: async (data) =>
    await axios.post(`/api/question/addPost`, data, tokenConfig()),
};

export default questionApi;
