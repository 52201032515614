import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import fetch from 'node-fetch';

function ThreeMonthsSurveySection1() {
  const location = useLocation();
  const navigate = useNavigate();
  const [answerData, setAnswerData] = useState({
    answers: [],
    type: 'post3mth',
  });
  const [question, setQuestion] = useState('');
  const [searchParams] = useSearchParams();
  const surveyToken = searchParams.get('token');
  const user_id = surveyToken?.split('-')[1];
  const [completed, setCompleted] = useState(false);
  // http://localhost:3000/survey/post3months/one?token=%lks8474j=1234#-2778-%23t54##+kjldiiheiddi=sljdisseiohl

  useEffect(() => {
    if (user_id) {
      // todo fetch post3mth survey data
      const fetchData = async () => {
        try {
          const response = await fetch(
            `/api/survey/getSurvey?user_id=${user_id}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          const data = await response.json();
          // console.log('data', data);
          if (!data.user) {
            return message.error(
              'Invalid url provided. Please open your email to try again.'
            );
          }

          if (data.post3monthsSurvey) {
            setCompleted(true);
            return message.success('You had completed this survey!');
          }
        } catch (error) {
          // console.error('Error submitting survey:', error.response.data);
          if (error.response.data) {
            return message.error('An error occurred. Please try again.');
          }
        }
      };
      fetchData();
      // if got then show completed message
    }
  }, [user_id]);

  useEffect(() => {
    if (surveyToken) {
      // set the user id in local storage
      localStorage.setItem('survey_user_token', surveyToken);
    }
  }, [surveyToken]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          '/api/survey/surveys?section=1&type=post3mth',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const { data } = await response.json();
        if (data.length) {
          setQuestion(data[0]);
        } else {
          setQuestion(data);
        }
      } catch (error) {
        // console.error('Error submitting survey:', error.response.data);
        if (error.response.data) {
          message.error('An error occurred. Please try again.');
        }
      }
    };

    fetchData();
  }, [navigate]);

  // console.log('questions', question);

  const handleChange = (option) => {
    const existingIndex = answerData.answers.findIndex(
      (answer) => answer.survey_question_id === question.id
    );

    if (existingIndex !== -1) {
      // If an existing answer is found, update its option_id
      const updatedAnswers = [...answerData.answers];
      updatedAnswers[existingIndex] = {
        ...updatedAnswers[existingIndex],
        option_id: option.id,
      };
      setAnswerData({ ...answerData, answers: updatedAnswers }); // Update only the answers array
    } else {
      // If no existing answer is found, add a new answer to the array
      const newAnswer = {
        survey_question_id: question.id,
        option_id: option.id,
      };
      setAnswerData({
        ...answerData,
        answers: [...answerData.answers, newAnswer],
      }); // Add the new answer to the answers array
    }
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <div className="margin-big-width primary-text-color">
        <div className="survey-title pt-5 pb-3">
          DLP 3-month Post-training Survey
        </div>
        <div>
          <div style={{ overflow: 'hidden' }} className="w-100 questions-bg">
            <div>
              <span> 1. </span> {question?.description}
            </div>
            <div className="options" style={{ marginTop: '20px' }}>
              {question?.options
                ?.sort((a, b) => a.sequence - b.sequence)
                .map((option) => (
                  <div key={option.id} className="ms-3">
                    <label>
                      <div className="d-flex">
                        <input
                          className="align-self-start radio-btn"
                          type="radio"
                          name="question"
                          value={option.id}
                          onChange={() => handleChange(option)}
                        />
                        <div style={{ textAlign: 'start' }}>
                          {option.details}
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
            </div>
          </div>

          {completed ? null : (
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-radius btn-yellow-bg px-5 font-weight-boldest dark-blue-title btn-next"
                onClick={() => {
                  if (answerData.answers.length === 0) {
                    message.error('Please select an option to continue');
                  } else {
                    navigate('/survey/post3months/two', {
                      state: { data: location.state?.data, answerData },
                    });
                  }
                }}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ThreeMonthsSurveySection1;
