import React, { useEffect, useState } from 'react';
import Sidebar from '../components/sidebar';
import questionApi from '../services/questionActions';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getModuleData, checkUserSession } from '../reducer/userReducer';
import { message } from 'antd';
import StateManager from './StateManager';

function DashboardWrapper({ children }) {
  //
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const curModule = searchParams.get('module');
  const [modules, setModules] = useState([]);
  const dispatch = useDispatch();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const getModules = async () => {
      try {
        const res = await questionApi.getModules();
        if (res) {
          // console.log('res', res);
          setModules(res.data.data);
        }
      } catch (error) {
        if (error.response?.data.error) {
          message.error(error.response?.data.error);
        }
      }
    };
    getModules();
  }, []);
  // console.log('modules', modules);

  useEffect(() => {
    if (curModule) {
      dispatch(getModuleData({ module: curModule }));
      setCurrentIndex(Number(curModule));
    }
  }, [curModule, dispatch]);

  useEffect(() => {
    const token = localStorage.getItem('dlp-hcp-doctor-token');
    if (token) {
      dispatch(checkUserSession());
    }
  }, [dispatch]);

  const { error } = useSelector((state) => state.user);

  useEffect(() => {
    if (error) {
      // console.log('error', error);
      if (
        error === 'Token expired' ||
        error === 'Token expired, please login again' ||
        error === 'Unauthorized Request'
      ) {
        navigate('/login');
      }
    }
  }, [error, navigate]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
    window.location.href = `/video?module=${currentIndex - 1}`;
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
    window.location.href = `/video?module=${currentIndex + 1}`;
  };

  // console.log('modules', modules);

  return (
    <div className="main-container">
      <Sidebar
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        currentIndex={currentIndex}
        modulesLength={modules.length}
        isCollapsed={isCollapsed}
        toggleCollapse={toggleCollapse}
      />
      <div className={isCollapsed ? 'isCollapsed' : 'isNotCollapsed'}>
        <>{children}</>
      </div>
    </div>
  );
}

export default DashboardWrapper;
