import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import fetch from 'node-fetch';

function ThreeMonthsSurveySection6() {
  const location = useLocation();
  const navigate = useNavigate();
  const [answerData, setAnswerData] = useState(location.state?.answerData);
  const [question, setQuestion] = useState(''); // eslint-disable-line no-unused-vars
  const [navigateUrl, setNavigateUrl] = useState('/survey/post3months/seven');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          '/api/survey/surveys?section=6&type=post3mth',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const { data } = await response.json();
        if (data.length) {
          setQuestion(data[0]);
        } else {
          setQuestion(data);
        }
      } catch (error) {
        // console.error('Error submitting survey:', error.response.data);
        if (error.response.data) {
          message.error('An error occurred. Please try again.');
        }
      }
    };

    fetchData();
  }, [navigate]);

  const handleChange = (option) => {
    // Yes (proceed to next question)
    // No (skip next question)
    // I did not apply the learnings from DLP in my practice (skip to Q9)
    if (option.details.includes('Yes')) {
      setNavigateUrl('/survey/post3months/seven');
    } else if (option.details.includes('No')) {
      setNavigateUrl('/survey/post3months/eight');
    } else if (option.details.includes('I did not apply')) {
      setNavigateUrl('/survey/post3months/nine');
    }
    const existingIndex = answerData.answers.findIndex(
      (answer) => answer.survey_question_id === question.id
    );

    if (existingIndex !== -1) {
      // If an existing answer is found, update its option_id
      const updatedAnswers = [...answerData.answers];
      updatedAnswers[existingIndex] = {
        ...updatedAnswers[existingIndex],
        option_id: option.id,
      };
      setAnswerData({ ...answerData, answers: updatedAnswers }); // Update only the answers array
    } else {
      // If no existing answer is found, add a new answer to the array
      const newAnswer = {
        survey_question_id: question.id,
        option_id: option.id,
      };
      setAnswerData({
        ...answerData,
        answers: [...answerData.answers, newAnswer],
      }); // Add the new answer to the answers array
    }
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <div className="margin-big-width primary-text-color">
        <div className="survey-title pt-5 pb-3">
          DLP 3-month Post-training Survey
        </div>
        <div>
          <div style={{ overflow: 'hidden' }} className="w-100 questions-bg">
            <div>
              <span>6. </span> {question?.description}
            </div>
            <div className="options" style={{ marginTop: '20px' }}>
              {question?.options
                ?.sort((a, b) => a.sequence - b.sequence)
                .map((option) => (
                  <div key={option.id} className="ms-3">
                    <label>
                      <div className="d-flex">
                        <input
                          className="align-self-start radio-btn"
                          type="radio"
                          name="question"
                          value={option.id}
                          onChange={() => handleChange(option)}
                        />
                        <div style={{ textAlign: 'start' }}>
                          {option.details}
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
            </div>
          </div>

          <div className=" d-flex justify-content-center">
            <button
              className="btn btn-radius btn-yellow-bg px-5 font-weight-boldest dark-blue-title btn-next"
              onClick={() => {
                if (answerData.answers.length < 23) {
                  message.error('Please select an option to continue');
                } else {
                  navigate(navigateUrl, {
                    state: {
                      data: location.state?.data,
                      answerData,
                      answerLength: answerData.answers.length,
                      queryQuestions: navigateUrl.includes('eight')
                        ? ['1', '2', '3', '4', '5', '6', '8', '9']
                        : navigateUrl.includes('nine')
                        ? ['1', '2', '3', '4', '5', '6', '9']
                        : ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
                    },
                  });
                }
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThreeMonthsSurveySection6;
