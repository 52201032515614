import axios from 'axios';
import { tokenConfig } from './helperFunc';

const userApi = {
  register: async (data) => await axios.post('/api/auth/register', data),

  login: async (data) => await axios.post('/api/auth/login', data),

  forgotPassword: async (data) =>
    await axios.post('/api/auth/password/forgot', data),

  logout: async (data) =>
    await axios.post('/api/auth/logout', { user: '' }, tokenConfig()),

  registerDoctorAtDlp: async (data) => {
    // how to get window url full name from url
    axios.post('/api/auth/updateWebRegister', data, tokenConfig());

    const location = window.location.host;
    const urlPath = location.includes('onlinecourse.mydlp.my')
      ? 'https://lifestylechange.mydlp.my/api/doctor/addDoctorFromDoctorPortal'
      : 'https://dlp.thedgroup.com.my/api/doctor/addDoctorFromDoctorPortal';
    return await axios.post(urlPath, data, tokenConfig());
  },
};

export default userApi;
