import React, { useEffect, useState } from 'react';
import { Modal, message } from 'antd';
import completeCert from '../images/completed-image.png';
import userApi from '../services/userActions';

const CompletionCongrat = ({
  isModalOpen,
  setIsModalOpen,
  closeAllModals,
  user,
}) => {
  const [open, setOpen] = useState(isModalOpen);
  const [loading, setLoading] = useState(false);
  const location = window.location.host;
  // console.log('environment', environment);
  useEffect(() => {
    if (isModalOpen) {
      setOpen(isModalOpen);
    }
  }, [isModalOpen]);

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const registerUserAndCloseModal = async () => {
    setLoading(true);
    try {
      // console.log('user', user);
      const addedUser = await userApi.registerDoctorAtDlp(user);
      console.log('addedUser', addedUser);
      if (addedUser) {
        message.success('You have been registered at DLP');
        setLoading(false);
        window.location.href = location.includes('onlinecourse.mydlp.my')
          ? 'https://lifestylechange.mydlp.my/'
          : 'https://dlp.thedgroup.com.my/';

        closeAllModals();
        setOpen(false);
      }
    } catch (error) {
      setLoading(false);
      // console.log('error', error.response?.data.error);
      // 'Sorry, This user has been registered.'
      if (
        error.response?.data.error === 'Sorry, This user has been registered.'
      ) {
        window.location.href = location.includes('onlinecourse.mydlp.my')
          ? 'https://lifestylechange.mydlp.my/'
          : 'https://dlp.thedgroup.com.my/';

        closeAllModals();
        setOpen(false);
      } else {
        message.error(error.response?.data.error);
        closeAllModals();
        setOpen(false);
      }
    }
  };

  // console.log('open', open);
  return (
    <Modal
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <div className="text-center">
        <img
          alt="cert"
          style={{ objectFit: 'contain', width: '100%', height: '200px' }}
          src={completeCert}
        />

        <div style={{ fontWeight: '700', fontSize: '20px' }} className="mt-4">
          Congratulations
        </div>
        <div className="mt-3">
          You are now the Diabetes Lifestyle
          <br />
          Advocate, click <b> "Get Started"</b> to register
          <br />
          on the DLP web-based app.
        </div>

        <div className="text-center">
          <button
            className="custom-button my-3 fw-bold"
            style={{
              background: '#F0D474',
              padding: '7px 5rem',
              color: '#3B638C',
            }}
            onClick={() => {
              registerUserAndCloseModal();
            }}
          >
            {loading ? 'Loading ...' : 'Get Started!'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CompletionCongrat;
