import React from 'react';
import Header from '../components/header';
import dlpLogo from '../assets/dlp_logo.png';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import overlayImg from '../assets/login-texbox.png';
import axios from 'axios';
import { message, Form, Input } from 'antd';

function Login() {
  //
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    const { email, password } = e;
    try {
      const body = {
        email: email,
        password: password,
      };
      const response = await axios.post('/api/auth/login', body);
      // console.log(response.data);
      if (response) {
        // console.log('response', response);
        localStorage.setItem('dlp-hcp-doctor-token', response.data.token);
        navigate('/modules');
      }
    } catch (error) {
      console.error(error.response?.data.error);
      if (error.response?.data.error) {
        message.error(error.response?.data.error);
      }
    }
  };

  useEffect(() => {
    const remember = localStorage.getItem('dlp-hcp-doctor-remember');
    const token = localStorage.getItem('dlp-hcp-doctor-token');

    const checkUser = async () => {
      try {
        const body = {
          token: token,
        };
        const response = await axios.post('/api/auth/checkUser', body);
        // console.log(response.data);
        if (response) {
          // console.log('response', response);
          if (remember) {
            navigate('/modules');
          }
        }
      } catch (error) {
        if (error.response?.data.error) {
          message.error(error.response?.data.error);
        }
      }
    };
    if (token) {
      checkUser();
    }
  }, []);

  const handleRemember = (event) => {
    if (event) {
      // remember
      localStorage.setItem('dlp-hcp-doctor-remember', 1);
    } else {
      localStorage.removeItem('dlp-hcp-doctor-remember');
    }
  };

  return (
    <div className="main-container">
      <Header />
      <div className="register-container">
        <div className="left-part-container-login">
          <div className="auth-image">
            <div className="text-start ms-5">
              <img
                src={dlpLogo}
                style={{ width: 'auto', height: '170px' }}
                alt="logo"
              />
            </div>
            <img
              className="mt-3"
              src={overlayImg}
              alt="img"
              style={{ width: '100%', height: '400px', objectFit: 'contain' }}
            />
          </div>
        </div>

        <div className="show-logo-in-mobile text-center">
          <img src={dlpLogo} style={{ width: '50%' }} alt="logo" />
        </div>

        <div className="right-container">
          <div className="welcome-text mt-3">Welcome to</div>
          <div className="welcome-text">Diabetes Lifestyle Programme</div>
          <div className="create-acc-text mt-3">Log in to your account</div>
          <Form className="register-form mt-5" onFinish={handleSubmit}>
            <label className="register-label" htmlFor="email">
              Email
            </label>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input className="py-2" />
            </Form.Item>

            <label className="register-label" htmlFor="password">
              Password
            </label>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password className="py-2" />
            </Form.Item>

            <div className="d-flex justify-content-between">
              <div>
                <input
                  type="checkbox"
                  onChange={(e) => handleRemember(e.target.checked)}
                />
                <span className=""> Remember me </span>
              </div>

              <div onClick={() => navigate('/recover')}>Forgot password?</div>
            </div>

            <br />
            <br />
            <button className="register-btn" type="submit">
              Login
            </button>
          </Form>
          <p className="already-text">
            New user?{' '}
            <strong
              style={{ color: '#F0D474', cursor: 'pointer' }}
              onClick={() => navigate('/register')}
            >
              Register Now
            </strong>
          </p>

          <div className="show-textbox-in-mobile">
            <img
              className="mt-5"
              src={overlayImg}
              alt="img"
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
