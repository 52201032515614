import React, { useState } from 'react';
import olcBanner from '../assets/olc_banner_no_logo.png';
import Header from '../components/stickyHeader';

import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
const { Panel } = Collapse;

function Faq() {
  const panelStyle = {
    marginBottom: 24,
    backgroundColor: '#F0D474',
    borderRadius: 20,
    border: 'none',
    color: '#3B638C',
    textAlign: 'left',
  };

  const [open, setOpen] = useState(0);
  const [open2, setOpen2] = useState(0);

  const handleSubmit = (inputTab) => {
    if (open === 0) {
      setOpen(inputTab);
    } else {
      setOpen(0);
    }
    setOpen2(0);
  };

  const handleSubmit2 = (inputTab) => {
    if (open2 === 0) {
      setOpen2(inputTab);
    } else {
      setOpen2(0);
    }
    setOpen(0);
  };

  // console.log('open', open);

  return (
    <div className="main-container">
      <Header />
      <div className="text-center">
        <img className="olc-banner" src={olcBanner} alt="banner" />
      </div>

      <div className="faq-title">Frequently Asked Question</div>

      <div className="faq-container ">
        <div className="faq-left-container">
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            accordion
            activeKey={[open]}
          >
            <Panel
              header="Module 1: Essentials of Healthy Eating for Diabetes"
              key="1"
              style={panelStyle}
              className="panel-header"
              onClick={() => handleSubmit('1')}
            >
              <div>
                <div className="sub-header-faq">
                  What to watch out for patient on meal replacement?
                </div>
                <div className="faq-question">
                  I have a diabetes patient (on metformin/dimicron MR) on meal
                  replacement (non diabetes specific) .. Mainly replace
                  breakfast/lunch/snack, mainly high protein and low carbo. His
                  dinner portion is with 1 cup of rice + protein/vege. So far,
                  he doesn't have any hypoglycemic episode. Anything need to
                  watch out? He is 100kg
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Since the patient is using DSF - monitor his post-meal glucose
                  as Meal Replacement that is non-DSF has usually added sucrose.
                </div>
                <div>
                  Besides, check on his fluid intake and if he drastically
                  losing weight, monitor his uric acid levels too. Do ensure
                  sufficient fluid intake.
                </div>

                <div className="divider my-3"></div>
              </div>
              <div>
                <div className="sub-header-faq">
                  What are the difference between different types of sugar
                  sources?
                </div>
                <div className="faq-question">
                  1. Stevia is a plant-based sweetener. How does it differ from
                  sugar since sugar is also plant (sugar cane)?
                </div>

                <div className="faq-ans">
                  Answer: Yes, Stevia is a plant-based sweetener but what makes
                  them different from sugar cane is that it contains a compound
                  called steviol glycosides that are about 150-300 times sweeter
                  than sugar. Hence, it gives the sweetness as sugar but in a
                  very small amount which is technically little-to-no calories.
                </div>

                <div className="faq-question">
                  2. What about monk fruit? I read monk fruit is similar to
                  stevia, plant based sweetener.
                </div>
                <div className="faq-ans">
                  Answer: Monk fruit is also a natural sweetener like Stevia.
                  Monk fruit is a small, green gourd that resembles a melon.
                  It’s grown in Southeast Asia.
                </div>

                <div className="faq-question">
                  3. Coconut sugar claimed to have lower GI. Is this true?
                </div>
                <div className="faq-ans">
                  Answer: Coconut sugar has a GI of around 50-54 essentially the
                  same as white sugar. But some data shows around 35, depending
                  on the brand but I am not really familiar with that.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Diet advice for 12 hours shift factory worker
                </div>
                <div className="faq-question">
                  I have many patients work in factory and most of them have AM
                  and PM shifts. Most of them will skip breakfast and have late
                  dinner as the factory bus pick up by 6am and by the time they
                  reach home is after 8pm (for those on AM shift). Excuse given
                  include "no time to prepare own breakfast" and "I only eat
                  Nasi Lemak for breakfast". If they work night shift, they will
                  eat "Nasi Lemak" which is easily available on their way home
                  and go to sleep; and take suppers while at work.
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Thank you for your question, Very relevant and valid.
                </div>
                <div className="faq-ans">
                  It is known that patients with type 2 diabetes who have
                  irregular working hours would also have uncontrolled blood
                  sugar. This is because the management is even more difficult,
                  not only to have appropriate mealtime based on circadian
                  rhythm but to match with the medication. The study has shown
                  that people who worked overnight reported issues such as
                  higher body mass index (BMI), shorter sleep duration and
                  higher daily intake of calories.
                </div>

                <div className="faq-ans">
                  The Endocrine Society suggest that a person working night or
                  varying shifts should manage their type 2 diabetes by: eating
                  a healthful diet, taking all diabetes medication according to
                  the prescription & engaging in regular exercise.
                </div>

                <div>
                  As for food intake, it is not an easy job. But we can try the
                  the following to assist our patients to plan ahead for
                  appropriate mealtime:
                  <br />
                  1) Meal plan - get a dietitian to help them plan a 'night
                  shift' eating plan. Give them option based on the meals
                  available at their work place
                  <br />
                  2) Eat "main meal" before night shift - This will help keep
                  them fuelled for the next 12 hours. Try having the "main meal"
                  before they leave for the night shift, between 5-6 pm. Then
                  have a small additional meal and snacks throughout the night.
                  <br />
                  3) Have a small "breakfast" or snack after a night shift
                  <br />
                  4) They can still enjoy nasi lemak, but not most of the days
                  in a week. Plan ahead what to eat after night shift would help
                  them to manage their diet
                  <br />
                  5) Be active
                </div>

                <div className="divider my-3"></div>
              </div>
              <div>
                <div className="sub-header-faq">
                  Advise to adopt the Plate Mode
                </div>
                <div className="faq-question">
                  For diabetics who do not eat veggies, how would you advise
                  them regarding the best food choices for their plate model? I
                  assume by replacing the veggies portion with only fruits could
                  actually worsen their sugar control.. How would these kind of
                  diabetics achieve satiety? Many thanks
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Vegetables are an important component of food in a daily meal
                  plan for patients with diabetes. Vegetables are rich in fibre,
                  phytochemicals, vitamins, and minerals. Dietary fibre
                  contributes to various health benefits, such as improving
                  bowel transit, providing early satiety and thus controlling
                  carbohydrate intake. A reduced-carbohydrate intake at a
                  particular mealtime would help to manage blood glucose levels.
                  Fruits do contain fibre, vitamins and minerals but as they
                  contain carbohydrates, fruits intake must be portion and count
                  as part of carbohydrate. Hence, explaining the health benefits
                  of vegetables would help them to understand the role of
                  vegetables as part of dietary management in diabetes.
                </div>
                <div className="faq-ans">
                  Here are some suggestions to help them to start eating
                  vegetables. Start by choosing the most preferred vegetable and
                  eat them consistently.
                </div>

                <div>
                  - They can choose ulam Pair with foods they like: Start by
                  adding a vegetable in an egg or a tomato with a sardine
                  sandwich
                  <br />
                  - Play with texture: If they cant go with mushy vegetables,
                  try raw, shredded, roasted or stir-fried versions instead.
                  Love crunchy foods? Try baked kale or beet “chips.”
                  <br />
                  - Enjoy more soups: If the texture of both raw AND cooked
                  vegetables is their thing, puree them instead. Prepare blended
                  soups or smoothies to increase vegetable consumption.
                  <br />
                  - Add a spectacular flavour: A little olive oil and garlic, a
                  drizzle of balsamic vinegar, a squeeze of lemon, or a handful
                  of fresh herbs can add excitement to veggies.
                  <br />
                  The study has also shown that eating vegetables first and
                  carbs last help to improve their blood glucose levels.
                  <a href="https://www.actascientific.com/ASNH/pdf/ASNH-04-0587.pdf">
                    https://www.actascientific.com/ASNH/pdf/ASNH-04-0587.pdf
                  </a>
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">Diabetic meal recipes</div>
                <div className="faq-question">
                  Sharing diabetic meal recipes would make the patients easier
                  to control their glucose level thus better control of HbA1c.
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Thank you for your question. Absolutely, having to show them
                  the ideas of preparing their meal would assist them to plan
                  and make the right decision on choosing a healthier meal.
                </div>
                <div className="faq-ans">
                  In our previous experience conducting a clinical trial using
                  MNT and diabetes-specific formula as part of meal replacement,
                  we provided at least a 14-day menu plan for the patients to
                  enjoy. The improvement in HbA1c was intriguing. In clinical
                  practice, as a dietitian, we equip them with a menu plan, what
                  to exchange within a similar amount of carbs, and a choice of
                  healthier food (choose this instead of that). Hope it is
                  helpful. Thank you.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  What is your opinion on the diabetic rice (Drice) or white
                  rice with low calorie?
                </div>
                <div className="faq-question">
                  what is your opinion on the diabetic rice (Drice) or white
                  rice with low calorie? The taj mahal rice almost half of the
                  normal white or brown rice calorie.
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Most rice does not differ much in carbohydrate content - the
                  difference is between 10-25% for most rice varieties. We still
                  recommend 3/4 to 1 cup rice per meal as total carbohydrate
                  matters in diabetes. Calorie wise is also similar
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Protein diet advice for CKD patient
                </div>
                <div className="faq-question">
                  How would you advice low protein diet in a patient with
                  uncontrol DM with CKD stage 3-4 with proteinuria (in a simple
                  way)?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Patients with CKD should be prescribed 0.6-0.8 g/kg BW of
                  proteins. It requires assessment by a dietitian for more
                  specific advice based on the assessment of how much protein to
                  prescribe, and to also consider sources of proteins from other
                  food sources besides meats/fish/chicken; sodium intake would
                  also need to be restricted to slow down CKD and carbohydrate
                  counting is needed to control blood sugar - all these
                  contribute to CKD progression.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">Stevia VS regular sugar</div>
                <div className="faq-question">
                  A lot of my patients take stevia on daily basis as sugar
                  replacement in their daily morning/evening coffee/tea. I asked
                  opinion from my PSP and dietitian, both agree that stevia and
                  regular sugar have no difference, and both provide in
                  increasing the glucose level in the body. What is your opinion
                  in this matter?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Stevia is a plant-based sweetener. It is not sugar at all.
                  Stevia is 200-400X the sweetness of sugar. It has negligible
                  calories. Stevia does not affect blood glucose levels
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">Timing of meal intake</div>
                <div className="faq-question">
                  how to advise patient whom work with no fix schedule such as
                  lorry driver/healthcare workers and etc to adjust his/her
                  timing to eat? And what is the advisable time for the last
                  meal of the day?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  This is indeed challenging when a patient does not have
                  regular meal times. Regular mealtimes lead to lower glucose
                  variability and weight control. Dietitians would try and
                  establish some pattern of regular mealtime and suitable
                  choices of food when eating out or bringing food from home for
                  persons with such occupation. The general rule of thumb is to
                  eat dinner as early as possible after 6 pm - studies have
                  shown that late-night eating is associated with weight gain
                  and poor blood sugar control.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">Specific race-based diet</div>
                <div className="faq-question">
                  Is there a chance to formulate specific race based diet
                  because our races eat different diets
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  The principles would be the same however you are right that it
                  should culturally suitable and examples should be tailored to
                  the culture.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">Millets</div>
                <div className="faq-question">What about millets?</div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Millet is a cereal, rich in fibre, B vitamins and low GI.
                  However it is a carbohydrate and like rice, noodles - will
                  need to be counted as part of carbohydrate allowance.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  {' '}
                  Definition of a serving & 1 carbohydrate exchange
                </div>
                <div className="faq-question">
                  What does it meant by a serving and 1 carb exchange (with
                  examples)? How many exchange is recommended/day?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  In general, 1 exchange is equal to 15-gram carbs while 1
                  serving, if based on the Malaysian food pyramid, is about 2-3
                  exchanges or 30-45 grams carbs. The serving size means the
                  standard/usual amount of food eaten by the patient.
                </div>
                <div className="faq-ans">
                  For example, capati 1 serving is about 1 piece and contain
                  about 3 exchange of carbs (or 45 g carbs). In a day, if the
                  patient requires 1200 kcal with 50% carbs from total calories.
                </div>
                <div className="faq-ans">
                  50% x 1200 = 600 kcal/4 kcal
                  <br />
                  (1 g = 4kcal carbs) = 150g/15g
                  <br />
                  (1 exch carbs =15g carbs) = 10 exchanges carbs
                  <br />
                  *1 exch = 1/2 cup rice/1 slide bread/1 glass milk/1 apple
                  <br />
                  1500 kcal/day = 12.5 exchanges
                  <br />
                  1800 kcal/day = 15 exchanges
                  <br />
                  2000 kcal/day = 17 exchanges
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Is oatmeal good for breakfast?
                </div>
                <div className="faq-question">
                  Any benefits of suggesting oatmeal as breakfast to diabetic
                  pt?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Oats is a low glycemic index and high fibre so, it is a
                  healthy food plus it contains soluble fibre that lowers
                  cholesterol level.
                </div>

                <div className="divider my-3"></div>
              </div>
              <div>
                <div className="sub-header-faq">
                  {' '}
                  Is overnight oats helpful?
                </div>
                <div className="faq-question">
                  Is overnight oats helpful for patients in weight reduction?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Overnight oats are healthier than nasi lemak! For weight
                  reduction, it depends on what the patient eats the rest of the
                  meals. Remember, energy deficit for weight loss, but a healthy
                  breakfast is associated with increased awareness of eating
                  healthy the rest of the day
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">Can diabetic eat durian?</div>
                <div className="faq-question">
                  Can diabetic eat durian? What's the highest contents in it?
                  High GI?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />1 seed of durian is about 20-30g carbs, and almost 100
                  kcal and high in monounsaturated fat. So DM patients should be
                  careful with durians - best to avoid if one cannot stop at 1-2
                  seeds each time!
                </div>

                <div className="faq-ans">
                  Although the GI of durian categorised as low GI food in one
                  study (GI= 49: Research Study can be found here:
                  <br />
                  <a href="https://apjcn.nhri.org.tw/server/APJCN/17/1/35.pdf">
                    https://apjcn.nhri.org.tw/server/APJCN/17/1/35.pdf
                  </a>
                  ), it does not mean that DM patients can eat excessively.
                  Remember the 3T concept- controlling the amount is the key
                  management strategy in MNT for diabetes.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Advocating patients on calorie counting & carbohydrate
                  exchange
                </div>
                <div className="faq-question">
                  Which selection of patients would you advocate for calorie
                  counting and carbo exchange?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  All DM patients should be taught carbohydrate foods and
                  portion sizes. As they progressed, more specific and advanced
                  learning skills should be advocated for them. These include
                  carb exchange, carbs counting, and even calorie counting,
                  which can be taught by a dietitian. Strengthening fundamental
                  basic skills of carbohydrate management is critical at the
                  point of diagnosis and ensures patients can sustain them most
                  of the time.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Advice for diabetic patient who likes to take fruit juice
                </div>
                <div className="faq-question">
                  Some diabetic pts like to take fruit juice during their meals.
                  What is your advice?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Fruit juice within the allowed portion of fruits is fine,
                  however, most fruit juices are made with >3-5 servings of
                  fruits and may increase carbohydrate intake.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Common factors that causes the non-adherence
                </div>
                <div className="faq-question">
                  Good evening speakers, what are the common factors that causes
                  the non-adherence to long term healthy diets among patients,
                  and how to effectively help them.
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Tough question. The sustainability of behaviour change depends
                  very much on individual values and discipline. Common
                  adherence: ambivalent on the value of changing diet and
                  lifestyle, conflicting priorities, lack of knowledge, lack of
                  awareness, lack of self-efficacy. Using psychological
                  approaches such as Motivational Interviewing for ambivalent
                  patients or Cognitive Behaviour Therapy to change negative
                  thinking or behaviour may help. Frequency of seeing the
                  patient also matters.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Meal intake recommendations
                </div>
                <div className="faq-question">
                  Drs which is better, small frequent meals OR controlled 3 main
                  meals? Both have similar calories and nutritional intakes?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  There is no one technique that suits everyone. It depends on
                  individual preference. What matters is total calorie consumed
                  vs expenditure for weight control. For diabetes, it really
                  depends on the portion size and types of food consumed
                  especially carbohydrate-based foods during the meals
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Is zero calorie carbonated drinks a good choice?
                </div>
                <div className="faq-question">
                  What is your opinion on zero calorie carbonated drinks
                  labelled as pilihan sihat? Can we drink it regularly if we
                  like carbonated drink?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  These drinks are zero calories and contain artificial
                  sweeteners. There is no concrete evidence that the sweeteners
                  are unsafe. The safety limit for aspartame, for example is
                  50mg/kg/body weight = 75 packets of aspartame ( by FDA, USA).
                  Artificial sweeteners do not impact blood glucose levels. So,
                  suppose people with diabetes choose artificially sweetened
                  beverages in an effort to control their condition by
                  displacing the added sugars in the diet. In that case, they do
                  tend to reduce total calories and support weight loss.
                </div>
                <div className="faq-ans">
                  Nonetheless, if the patient chooses artificially sweetened
                  beverages and then consumes high-calorie foods such as cake or
                  ice cream, the benefits are not there.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Nutritional recommendations for elderly
                </div>
                <div className="faq-question">
                  How do we modify the current recommendations for the elderly?
                  Would they need adjustments or current suku suku separuh will
                  suffice?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Yes, the Healthy Plate model would be applicable and suffice.
                  Older adults, in general, have unique nutritional needs and
                  may need to make changes to their diets as the years go by.
                  Muscle mass can decrease as a natural part of ageing, and
                  people do not burn calories at the same rate as they do during
                  their younger years. A healthier diet and a nutrition-dense
                  diet are even critical for older patients with diabetes.
                  Healthy plate #suku-suku separuh can still be applied. The
                  most vital nutrient is protein-based foods. Choose lean
                  protein such as a boiled egg/ pan-fried egg/ tauhu / tempe/
                  beans. As they usually can't eat much at one time due to the
                  normal ageing process, small, frequent, nutrient-dense meals
                  would benefit them.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Malaysian breakfast suggestions for diabetic patients
                </div>
                <div className="faq-question">
                  For patients who do not take "Western" breakfast (eg oats,
                  milk, plain yogurt, cheese, salad), but used to take milo,
                  crackers, roti kaya, "kuih muih", "mee/ bihun goreng" with
                  little vegetables, what could we suggest them? Thank you.
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Eating a balanced breakfast is essential, especially with
                  patients with diabetes and but figuring out exactly what to
                  eat can be tricky.
                </div>

                <div className="faq-ans">
                  I agree that Malaysian breakfast is exciting yet relatively
                  unsuitable for a patient with T2DM.
                </div>
                <div className="faq-ans">
                  I advocate using the Healthy Plate Concept- #suku-suku separuh
                  in all main mealtimes in my clinic. For example, if they have
                  Bihun goreng, the size of the bihun is one quarter of the
                  plate (or about one hand-fist). Add lean protein, including a
                  boiled egg/ pan-fried egg/ tauhu / tempe/ beans. Half of the
                  portion of the plate for vegetables such as cucumber, bayam,
                  sayur sawi, tomatoes and ulaman prepared with less oil. The
                  same goes when they eat Nasi Lemak, capati, Nasi Goreng and
                  Mee Goreng. Advocate them to portion according to Healthy
                  Plate Concept. To optimize the MNT further, I asked the
                  patient to start eating vegetables first, lean protein, and
                  mix them. The bottom line, eat carbs last.
                </div>

                <div className="divider my-3"></div>
              </div>
            </Panel>
            <Panel
              header="Module 3: Physical Activity: From Theory to Practice (Part 1)"
              key="3"
              onClick={() => handleSubmit('3')}
              style={panelStyle}
            >
              <div>
                <div className="sub-header-faq">Exercise recommendations</div>
                <div className="faq-question">
                  Volume of exercise is mentioned to be 150 minutes for weight
                  maintenance. Is this daily or weekly?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  The volume of exercise per week is a function of its duration,
                  intensity and frequency of exercise. Research has shown that
                  adults are more likely to maintain their weight if they have
                  an active lifestyle, reduce sedentary behaviour and adhere to
                  recommended dietary guidelines. Active lifestyle, in the true
                  sense means that they are achieving the minimum recommended
                  targets for exercise. Lets take a look at the following
                  figures.
                </div>

                <div className="faq-ans">
                  Recommendations are based on ACSM position stand of
                  Appropriate Physical Intervention Strategies for weight loss
                  and prevention of weight gain for Adults (Goals: Time/week)
                </div>

                <div>
                  - Maintain and improving health: 150mins/week
                  <br />
                  - Prevention of weight gain: 150-250mins/week
                  <br />
                  - Promote clinically significant weight loss: 225-420mins/week
                  <br />- Prevention of weight gain after weight loss:
                  200-300mins/week
                </div>

                <div className="faq-ans">
                  Looking at the above figures, it can be noted that the volume
                  of exercise needed to maintain weight ( i.e prevention of
                  weight gain ) is more than the recommendation of exercise for
                  health. These figures are for a weekly basis.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  How to reduce visceral fat?
                </div>
                <div className="faq-question">
                  How accurate is commercial body composition analyzer?
                  <div className="py-3">
                    I have a patient have started exercise/dietary program since
                    3 month, mainly doing HIIT (4 minutes) and resistant
                    exercise (10minutes), he lost significant amount of weight
                    and body fat.
                  </div>
                  However, his visceral fat from the body composition still
                  remain stuborn. Any advice? Can I suggest him to add aerobic
                  exercise?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  A) BODY COMPOSITION ANALYSIS
                </div>
                <div className="faq-ans">
                  There are many varieties of body composition analysers in the
                  market. I have briefly discussed regarding the BIA machines as
                  below. You had mentioned that his visceral fat was measured.
                  Then he must have used a high-end BIA machine as the more
                  common BIA machines don't have segmental analysis. Accuracy of
                  BIA depends on a few factors. Devices that use multiple
                  frequencies are more accurate than single frequency devices It
                  should give more information than just body fat %.
                </div>

                <div className="faq-ans">
                  Possible sources of error – physical activity, nutrition
                  status, hydration level, not adhering to pre-test protocols
                  (.eg fasted state, refrain from exercise/caffeine/alcohol etc
                  ), input of data such as gender, age, weight and height as
                  variables
                </div>

                <div>
                  Types of BIA:
                  <br />
                  1) Hand-held BIA – measure impedance in upper body and use it
                  to estimate lower half of body and based on certain
                  assumptions
                  <br />
                  2) Home scale BIA – measure impedance for lower body and use
                  it to estimate for upper body based on certain assumptions
                  <br />
                  3) "Whole body BIA” – more accurate than ( 1 ) and ( 2) but it
                  measures data from the arm and leg that the electrode is
                  placed on and must still estimate for the rest of the body.
                  <br />
                  4) DSM-BIA – direct segmental multifrequency – analyse in both
                  arm, both legs and torso. These are based on direct
                  measurements and not estimations.
                  <div className="py-2">
                    Accuracy of the commercial BIA depends which type of machine
                    is being used, with no ( 4 ) being the most accurate.
                  </div>
                  B) VISCERAL FAT
                  <br />
                  Generally, when we exercise, it will help to reduce overall
                  fat which also includes visceral fat. However, changes in
                  visceral fat volume as a response to physical exercise vary
                  considerably and that it is not possible to identify a clear
                  correlation between amount of physical exercise and reduction
                  in visceral fat. Any reduction of visceral fat tissue deposits
                  relates to reduction of the total amount of fat tissue.
                  <div className="py-2"></div>
                  Factors that may be contributing to "stubborn" visceral fat:
                  <br />
                  1) Genetics – Asians demonstrate greater visceral fat storage
                  despite lower BMI values
                  <br />
                  2) Stress – does he appear to be stressed? ( family/home/work
                  etc ) Research has shown that when there is chronic stress,
                  cortisol levels in the body rise resulting in preferential
                  deposition of fat in the abdomen. May need to look into
                  managing his stress if indeed it is a factor.
                  <br />
                  3) Sedentary - Is the nature of his job sedentary? Please note
                  that irrespective of his daily exercise program, he may be
                  considered sedentary if the nature of his job involves long
                  hours of desk based and he gets less than 5000 steps/day.
                  <br />
                  4) Type of exercise - There is mixed evidence between the
                  superiority of HIIT exercise and MICT (moderate intensity
                  continuous training) for weight loss. But generally both have
                  been found to be effective. The only difference is HIIT takes
                  less time. But whichever is done, the volume needs to be
                  adequate for any meaningful weight loss ( i.e the total energy
                  expenditure). Some studies have found that a combination of
                  HIIT and MICT was the most effective. Perhaps he can try
                  increasing the volume of exercise and combining moderate
                  intensity exercise to his current regime.
                  <br />
                  5){' '}
                  {`Sleep - How many hours does he sleep every night? Studies have found that chronic sleep deprivation (< 7hours/night) increases cortisol secretion and hence leads to increased visceral adiposity.`}
                  <br />
                  6) Resistance training - How many days does he do his
                  resistance training ? Has he been gradually progressing his
                  load? Resistance training is important as it helps to keep his
                  metabolism elevated. Maybe can suggest to him to do it more
                  frequently. Can even do it daily if targeting different muscle
                  groups. As long as there is a 48 hour gap when exercising the
                  SAME muscle group it should be fine. And importantly he must
                  be gradually progressing the intensity i.e either the load or
                  the number of repetitions per exercise
                  <br />
                  7) Timing of his exercise - When does he perform his exercise?
                  Maybe can suggest to him to perform exercise first thing in
                  the morning (fasted state). Research has shown that exercising
                  during the fasting state increases adipose tissue lipolysis
                  and peripheral fat oxidation via higher plasma adrenaline and
                  cortisol concentrations and lower circulating insulin levels.
                  However, this may not be the best option if he is a diabetic.
                  <div className="mt-3">
                    So in response to your question, yes, you may suggest for
                    him to add moerate intensity continous aerobic exercise to
                    his training program and also re-evaluate at his resistane
                    training workout.
                  </div>
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Is gardening considered as exercise?
                </div>
                <div className="faq-question">
                  Hi Dr. My patient always say that he do daily gardening in his
                  fruit farm about 3-4H a day and do all types of chores such as
                  cleaning, weeded, hoed and others. Is that consider as daily
                  exercise? Are there specific chores that they need to do
                  during gardening to be considered as doing moderate exercise?
                  Thank you.
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  It is good that your patient does a lot of physical activity
                  in his garden. However, you need to stress upon him that while
                  that is beneficial for him, he still needs to perform a
                  structured exercise program to improve on his cardiovascular
                  risk profile and overall health. Since your question is quite
                  similar to an earlier question I would like to duplicate my
                  response here.
                </div>
                <div className="faq-ans">
                  There are 4 domains of physical activity - occupational,
                  domestic, transportation and leisure time. Gardening is
                  considered as part of occupational or domestic related
                  activities. Exercise will fall under the category of leisure
                  time activities.
                </div>

                <div className="faq-ans">
                  Yes, this is a common situation in which people who perform
                  physically demanding jobs either at work or at home assume
                  that they can consider it as exercise. In actual fact, they
                  can consider themselves as being less sedentary but cannot
                  consider themselves as physically active. In the true sense of
                  meaning, being physically active means that your exercise
                  regime is meeting the recommended target. But most people (
                  lay public and HCW ) tend to use the term physically active in
                  a loose and general manner.
                </div>

                <div className="faq-ans">
                  Coming back to the question, it is good that he does gardening
                  everyday, which means he spends less time being sedentary as
                  prolonged sedentary time has deleterious effect on metabolic
                  health with poorer glycemic control. But we need to remember
                  that these 3 aspects, i.e time spent in sedentary behaviour,
                  physical activity levels and physical fitness are INDEPENDENT
                  PREDICTORS of mortality. Which means, doing well in 1 aspect (
                  less sedentary time in this case ) but lacking in the other 1
                  or 2 aspects still puts him at a higher risk of morbidity and
                  mortality. So being active at work will help to improve his
                  glycemic control but it will not be enough to confer him the
                  benefits of regular exercise done at moderate intensity. So,
                  he still needs to engage in a structured and regular exercise
                  program as this is where he will improve his fitness as well
                  derive the majority of health benefits. He should strive to
                  perform moderate intensity aerobic exercise for 150 mins/week
                  as well as resistance training to benefit from improved
                  diabetes management, improve his cardiovascular risk profile
                  and improve his overall health and wellbeing.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Precautions for exercise at night
                </div>
                <div className="faq-question">
                  Is there any specific precautions on time suitable for
                  exercise, some patients only manage to exercise at night.. Any
                  issues with that?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Unfortunately, there is no straightforward answer to that
                  question. Currently, none of the available guidelines
                  including ADA position stand 2016, specifically make any
                  reference to exercise timing in diabetic patients. There has
                  been conflicting studies into the optimal timing for exercise.
                  Most but not all studies show that exercising after meals has
                  a greater benefit on postprandial glycemia compared to premeal
                  exercise. In fact many studies have found that exercise in the
                  fasted state may predispose to hyperglycemia. For people with
                  type 1 Diabetes, glucose control during exercise is
                  challenging as exercise can cause either hypoglycemia or
                  hyperglycemia. Type 2 diabetic patients are less prone to
                  episodes of hypoglycemia.
                </div>

                <div className="faq-ans">
                  The glucose response post-exercise depends on many variables:-
                  1) Diabetes type
                  <br />
                  2) Type of medication
                  <br />
                  3) Timing of medications
                  <br />
                  4) Blood glucose level before exercise
                  <br />
                  5) Serum insulin level at time of exercise
                  <br />
                  6) Exercise factors
                  <br />
                  7) Type of exercise
                  <br />
                  8) Duration of exercise
                  <br />
                  9) Exercise intensity
                  <br />
                  10) Level of fitness
                  <br />
                  11) Food intake – timing , amount , type
                </div>
                <div className="faq-ans">
                  Undoubtedly, exercise at any time of the day is encouraged.
                  Regular exercise significantly decreased time spent in
                  hyperglycemia despite not having a significant effect on
                  fasting glucose concentration. It also confers benefits with
                  regards to cardiovascular risk factors. Traditionally,
                  exercise at night has generally not been recommended as it can
                  cause hypoglycemia overnight due to increased insulin
                  sensitivity. Interestingly, however a recent review suggested
                  that exercise after the evening meal would have the greatest
                  impact on correcting the inverted circadian physiology found
                  in diabetic individuals. This was however based on the premise
                  that dinner was the largest meal for the day.
                </div>

                <div className="faq-ans">
                  Based on the numerous reviews, it seems that exercising 10-15
                  mins after every meal may be a better option. If exercising in
                  the evening, better to exercise 30 minutes post-meal to take
                  advantage of the blunting of the glucose peak and the high
                  insulin to glucagon ratio rather than exercising late in the
                  post-prandial period which has a higher risk of hypoglycemia.
                  Importantly, the patient will need to find a time that is
                  convenient and suitable for them so adherence will be better.
                  In view of that, it will be a good idea for them to track the
                  blood glucose pre and post exercise for a few hours to see
                  what the trend is like as every person responds differently to
                  exercise.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Exercise recommendations and advice for morbid obesity patient
                </div>
                <div className="faq-question">
                  Any recommend exercise type and advice for Morbid obesity
                  patient? (Maybe around 40-50 years old)
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Research has shown that adults are more likely to maintain
                  their weight if they have an active lifestyle and reduce
                  sedentary behaviour. High physical activity (PA) levels or
                  exercise training should be an integral part of any treatment
                  plan for obese individuals irregardless of weight loss goals
                  and is associated with numerous benefits. High levels of PA
                  and cardiorespiratory fitness (CRF) are inversely associated
                  with cardiovascular disease and all-cause mortality. High CRF
                  is associated with greater survival in all BMI categories. So
                  clinicians should always encourage their patients to perform
                  exercise regardless of the weight loss achieved.
                </div>
                <div className="faq-ans">
                  The most common dilemma in the clinical setting is how much
                  exercise is needed to lose weight and what type of exercise
                  should be performed. It must be stated that exercise is less
                  effective than diet as a sole weight loss intervention.
                  Addition of exercise to a dietary intervention may help to
                  enhance weight loss. The role of exercise in obesity is as
                  follows to prevent further weight gain, maintain weight loss
                  indued by dietary means & improved body composition -
                  decreased visceral adiposity. The main aim of exercise is to
                  improve CRF.
                </div>

                <div className="faq-ans">
                  Lets take a look at the following figures ( Recommendations
                  are based on ACSM position stand of Appropriate Physical
                  Intervention Strategies for weight loss and prevention of
                  weight gain for Adults ): Goals (Time/week)
                  <br />
                  - Maintain and improving health (150mins/week)
                  <br />
                  - Prevention of weight gain (150-250mins/week)
                  <br />
                  - Promote clinically significant weight loss
                  (225-420mins/week)
                  <br />- Prevention of weight gain after weight loss
                  (200-300mins/week)
                </div>

                <div className="faq-ans">
                  Looking at the above figures, it is to be noted that the
                  volume of exercise needed to induce weight loss is much more
                  than the recommendation of exercise for health. Though it is
                  possible to do, it is generally beyond the reach and
                  capability of most adults and may not be practical or
                  sustainable. As far as possible, we should encourage them to
                  follow the same aerobic and strengthening exercise
                  recommendation for the general public. Strengthening exercise
                  helps to increase their metabolic rate. Please ensure that
                  these individuals undergo evaluation to exclude
                  contraindications and to assess other comorbidities that may
                  require special considerations and modifications.
                </div>
                <div className="faq-ans">
                  The following are recommendations strategies for obese
                  patients:
                  <br />
                  1) Reduce sedentary time and increase physical activity - can
                  use pedometers to count steps. Aim for >8000 steps/day.
                  <br />
                  2) Go for a walk after meals Strive for aerobic exercise >5
                  days /week to maximise caloric expenditure.
                  <br />
                  3) Type of exercise - No specific type. It can be lower body
                  like walking, brisk walking, cycling or upper body like
                  rowing, arm ergometry. In place exercise - pls refer to
                  example in my slides. Generally speaking lower body exercise
                  burns more calories because of the bigger muscle bulk. Perform
                  activity in bouts of 10 min before undertaking 1 continuous
                  session ( to minimise joint stress and reduce potential muscle
                  soreness ).
                  <br />
                  4) Aim for 45 - 60 mins/day gradually over a period of upto 12
                  weeks. Strengthening exercise - e.g push up, squats, dumbbell
                  exercise for upper limbs, calf raise, lunges etc. Alternate
                  the workouts to avoid boredom. i.e alternate the types of
                  aerobic and strengthening exercise so as to avoid doing the
                  same exercise daily
                </div>
                <div className="faq-ans">
                  It is important that we emphasize to the patients that
                  exercise alone may not lead to weight loss ( at least in the
                  initial stages when we haven't reached the targets ). The
                  importance of improving CRF and that numerous health benefits
                  occur in the abscence of weight loss. Maintenance of an active
                  lifestyle will reduce risk of future weight gain. This is
                  important so that they will have an appreciation of the role
                  of exercise and not be discouraged from the lack of weight
                  loss. The important thing is consistency in their exercise
                  program.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Can physiotherapists in Klinik Kesihatan prescribe exercise
                  for DM patients?
                </div>
                <div className="faq-question">
                  Are physiotherapists stationed at Klinik Kesihatan trained to
                  prescribe exercises for DM patients?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Our physiotherapy colleagues can certainly prescribe exercises
                  for most diabetes patients. However, in your evaluation, if
                  the patient has complications, co-morbids or any signs or
                  symptoms that suggest cardiac involvement please perform a
                  detailed assessment and determine what are the precautions or
                  modifications that may be needed. This information must then
                  be communicated to the physiotherapist so that the most
                  suitable program taking into account the precautions,
                  modifications and limitations of the patients can be
                  prescribed to ensure safety of exercise participation for the
                  patient.
                </div>
                <div className="faq-ans">
                  Consider the following example: A 55 year old male T2DM
                  patient with obesity, hypertension, knee osteoarthritis and
                  having peripheral neuropathy
                </div>

                <div className="faq-ans">
                  Medications: Metformin, Beta blocker
                </div>
                <div className="faq-ans">
                  Evaluation: No symptoms suggestive of cardiac involvement
                </div>

                <div className="faq-ans">
                  Issues: Knee osteoarthritis - considering patient is obese,
                  excessive weight bearing may accelerate degeneration
                  peripheral neuropathy - prone to foot ulcers with excessive
                  weight bearing activities Beta blocker - may cause
                  hypoglycemia unawareness, blunt heart rate response and cause
                  sudden reductions in post-exercise blood pressure
                </div>

                <div className="faq-ans">
                  Recommendations:
                  <br />
                  1) Low impact activities - walking or NWB e.g cycling, arm
                  ergometer, chair exercises depending on patient's condition
                  <br />
                  2) Rehab program for knee osteoarthritis - stretching,
                  strengthening and neuromotor exercises may need to monitor
                  exercise intensity using BORG scale or talk test instead of HR
                  <br />
                  3) In view of blunted heart rate response, please advise to
                  check blood glucose before and after exercise for a few days
                  to look for BG trend and especially if increasing exercise
                  intensity
                  <br />
                  4) In view of hypoglycemia unawareness, may need an extended
                  cool down period
                  <br />
                  5) In view of possible reduction in post-exercise blood
                  pressure
                </div>
                <div className="faq-ans">
                  Plan: Inform physiotherapist colleagues on recommendations.
                  They will be able to plan a suitable program specifically for
                  this patient. If patient has not started a formal exercise
                  program, always start low and go slow. Generally speaking
                  exercise at low intensity is safe for most people. To achieve
                  the benefits of an exercise program, aerobic exercise needs to
                  be progressed to moderate intensity over a period of time.
                  This needs to be done in a progressive manner taking into
                  account patient's tolerance and limitations.
                </div>
                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">Pre-breakfast cortisol</div>
                <div className="faq-question">
                  How does pre-breakfast cortisol increase/affect insulin
                  sensitivity?
                </div>
                <div className="py-3">
                  Answer:
                  <br />I am assuming this is related to exercise timing for
                  type 2 diabetic individuals. Circulating glucose
                  concentrations reflect the balance between the rate of arrival
                  ( from exogenous and endogenous sources ) and glucose
                  utilization. Based on a recent systematic review [Shaun et al
                  (2018). Exercise Timing in Type 2 Diabetes Mellitus: A
                  Systematic Review. Medicine & Science in Sports & Exercise.
                  2018; 50. 1. 10.] it was found that exercise performed in the
                  post-prandial period appeared to result in more consistent
                  reductions in blood glucose concentrations. However, there are
                  many studies that showed conflicting results with regards to
                  exercise timing in T2DM. Due to the complexities and
                  controversies surrounding the exercise timing, the 2019 ADA
                  Diabetes Diagnosis and Treatment standard recommendations on
                  physical activity have not specifically made any
                  recommendations with regards to that in patients with
                  diabetes. There are conflicting views regarding exercise in a
                  fasted state. One theory is that the glycogen depletion in the
                  fasted state improves insulin sensitivity. On another hand,
                  physiologically , exercising in the fasted state prolongs the
                  fasting period and may promote an even greater release in
                  counter-regulatory hormones like cortisol which antagonizes
                  the action of insulin. Cortisol stimulates gluconeogenesis and
                  increases mobilization of free fatty acids to ensure an
                  adequate fuel supply. According to research by Roden M et al
                  Mechanism of free fatty acid-induced insulin resistance in
                  humans. J Clin Invest. 1996;97:2859–2865 suggest that increase
                  FFA in plasma may cause reduced insulin sensitivity in
                  skeletal muscle.
                </div>
                <div className="faq-ans">
                  While exercising 1/2 hour post meal seems to provide more
                  consistent reductions in blood glucose, there seems to be pros
                  and cons for exercise at varying times of the day - both in
                  fasted , premeal and post meal. So, based on available
                  evidence it appears that manipulating exercise timing does not
                  appear to be critical for eliciting greater improvements in
                  glycemia control. Patients need to find timings that are
                  suitable for them and that they can adhere to to elicit the
                  long term benefits of a sustained exercise program. It will be
                  advisable for patients to monitor their blood glucose levels
                  pre and post exercise for a few days to observe the glucose
                  trend.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Difference between aerobic, flexibility & resistance exercise
                </div>
                <div className="faq-question">
                  How to differentiate aerobic, flexibility from resistance
                  exercise? What are their differences?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Aerobic, strengthening and flexibility are different
                  categories of exercise. For a comprehensive exericse program,
                  it will be beneficial to add all 3 components. There is a 4th
                  category of exercise, known as neuromotor exercise that can be
                  advised for a specific target group.
                </div>
                <div className="faq-ans">
                  I have prepared a brief summary of the three types of exercise
                  for better understanding.
                </div>

                <div className="faq-ans">
                  A) AEROBIC EXERCISE
                  <br />
                  These are activities that use large muscle groups and can be
                  maintained continuosly and is rhythmic in nature. It raises
                  your heart rate and breathing to cardiorespiratory system to
                  send more oxygen to the muscles. Main aim is to improve
                  cardiorespiratory fitness. Any movement pattern, involving
                  upper limb or lower limb, if done repetitively for 20-30
                  minutes can be considered aerobic.
                </div>

                <div className="faq-ans">
                  B) RESISTANCE EXERCISE
                  <br />
                  Exercise that causes the muscles to contract against an
                  external resistance. The main aim is to strengthen muscles and
                  improve function. It can be done using dumbbells, resistance
                  bands, machines or even our own body weight. The contraction
                  must be held for 5-10secs and perform 1-2 sets ( each set ~10
                  repetitions ).
                </div>

                <div className="faq-ans">
                  C) FLEXIBILITY EXERCISE
                  <br />
                  Exercises that stretch the muscles and improve range of motion
                  at the joints. Can be performed without any special equipment
                  though certain tools can help to enhance the stretch The
                  stretch has to be held for 10-30seconds with 2-4 repetitions
                  per muscle.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Patient said “I can’t exercise during covid pandemic."
                </div>
                <div className="faq-question">
                  Any tips in advising patients who give excuse that “I can’t
                  exercise during covid pandemic” because he insisted exercise
                  is outdoor activity only.
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Many people feel that they can exercise better outdoor ( e.g
                  walking, jogging, cycling ) due to the following reasons:
                  <br />
                  - ample space for them to exercise
                  <br />
                  - fresh air
                  <br />
                  - exposure to sunshine & natural surroundings
                  <br />
                  - less stress
                  <br />- can socialise
                </div>
                <div className="faq-ans">
                  While the experience of outdoor exercise can't be totally
                  replaced, he can still derive the benefits of exercise from
                  performing it indoors. It is especially important that in
                  current times of Covid pandemic where it may not be advisable
                  or allowable to exercise outdoors, to continue with the
                  exercise program as otherwise he risks losing whatever fitness
                  gains he has made if he stops his exercise sessions for too
                  long a time.
                </div>

                <div className="faq-ans">
                  Some of the benefits of indoor exercise include the following:
                  <br />
                  - can exercise even with unfavourable weather conditions
                  <br />
                  - can use commercial equipment ( if available ) - they can be
                  programmable to suit client needs
                  <br />
                  - can follow online exercise videos
                  <br />- can alternate between resistance and aerobic exercise
                </div>

                <div className="faq-ans">
                  Indoor aerobic exercises can range from beginner to
                  intermediate to advanced level ( depending on age, physical
                  condition and limitations ).
                </div>

                <div className="faq-ans">
                  Some examples are as follows:
                  <br />
                  i) Beginner - jumping jacks, jumping jills, lateral shuffle,
                  butt kicks, high knees
                  <br />
                  ii) Intemediate - squat jumps,box jums
                  <br />
                  iii) Advanced - burpees, mountain climbing
                </div>
                <div className="faq-ans">
                  There are also chair based exercises for those who need to
                  perform low impact exercise. The important thing to note is
                  that these exercise can be performed for few minutes and
                  alternated with strength exercises e.g lifitng weights,
                  squats, lunges etc. It is totally upto the creativity of the
                  individual and they can be mixed and matched! As with any
                  exercise, start low and go slow! You can visit this website to
                  get an idea of examples of exercises that can be performed at
                  home:-
                  <a href="https://www.healthline.com/health/cardio-exercises-at-home">
                    https://www.healthline.com/health/cardio-exercises-at-home
                  </a>
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Why do patient get headache 2 hours after aerobic exercise?
                </div>
                <div className="faq-question">
                  I have a patient that get headache 2 hours after aerobic
                  exercise, roughly once per week. May I know why?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  You mentioned that she gets headache after aerobic exercise
                  roughly once per week. More information is needed to better
                  understand the condition:
                </div>
                <div className="faq-ans">
                  - The nature of the headache and any associated symptoms?
                  <br />
                  - Does she have underlying migraine?
                  <br />
                  - Does this headache ONLY occur after exercise or does she
                  also experience it at other times?
                  <br />
                  - Does it occur after EVERY exercise session or only some
                  sessions?
                  <br />
                  - Is she exercising outdoor or indoor and at what time.
                  <br />
                  - Is it hot when she is exercising?
                  <br />
                  - Does she drink adequate quantities of water before she
                  exercises?
                  <br />- How long does she exercise and roughly at what
                  intensity?
                </div>

                <div className="faq-ans">
                  BACKGROUND
                  <br />
                  Post exercise headache may be a symptom of dehydration which
                  diabetics have an increased risk of. It may be associated with
                  thirst, light-headedness, dry mouth etc. Exercise can also be
                  a trigger for migranous headache. Headache post exercise is
                  known as exercise headache, though previously it was known as
                  exertional headache. It may be primary ( no underlying
                  abnormality ) or secondary ( malignancies, cerebrovascular
                  abnormalities etc ). That is why careful assessment and
                  evaluation is needed for people presenting with post-exercise
                  headache to rule out serious underlying pathologies.
                </div>

                <div className="faq-ans">
                  PRIMARY EXERCISE HEADACHE
                  <br />
                  It is a benign condition, characterised by throbbing or
                  pulsatile pain which is bilateral. The headache tends to build
                  up over minutes or longer. These usually start 30 min after
                  the start of exercise and can last 5 min to 48 h, although
                  half will resolve within 60 min. It is usually NOT accompanied
                  by nausea or vomiting. It may occur after sustained ,
                  strenuous physical activity especially during hot weather. For
                  patients with new onset or have never been investigated for
                  exercise headache- will need evaluation and further imaging if
                  warranted. Another interesting note to consider is evaluation
                  for CHD in patients who have cardiovascular risk factors as
                  there are a few instances ( mainly case reports ) of
                  myocardial ischemia in patients presenting with post-exercise
                  headache but without chest pain or evidence of ischemia on
                  ECG. Currently, there is scare evidence regarding treatment of
                  primary exercise headache. The following may be recommended to
                  the patient as prevention - extended warm up period, gradual
                  increase in activity level over weeks or months avoiding hot
                  weather and ensuring proper hydration.
                </div>

                <div className="faq-ans">
                  FOR THIS PATIENT, it will be hard to tell whether her headache
                  is due to dehydration, a part of migraine ( if she has ) or
                  Exercise headache ( which could be primary or secondary ).
                  Although most cases of exercise headache are primary and
                  benign, it would be prudent to evaluate this patient to
                  exclude secondary causes especially if it a new onset or
                  atypical presentation.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Why high intensity exercise cause hyperglycaemia?
                </div>
                <div className="faq-question">
                  Why high intensity exercise cause hyperglycaemia? Any caution
                  / advise we should tell our DM patient who do high intensity
                  exercise?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  {`Plasma glucose concentration during exercise depends on a balance between glucose uptake by exercising muscles and glucose release by the liver. As a result of a variety of unpredictable factors, exercise may cause either hypoglycemia or hyperglycemia in persons with diabetes. With regards to exercise induced hyperglycemia, it is more common in T1DM compared to T2DM. This could be due to insulin omission or reduction and overconsumption of carbohydrates. Hyperglycemia may also occur if the pre-exercise blood glucose levels are elevated. In T2DM, the effect is different for moderate intensity exercise ( < 60% VO2max ) and high intensity exercise ( >80% VO2max). The amount of glucose released by the liver depends on the exercise intensity and duration. As intensity increases, so does the rate of catecholamine release. With moderate intensity exercise ( <60% VO2max ) glucose uptake is matched by that of production. In intense exercise (>80% VO2max), glucose is the exclusive fuel for muscle and must be mobilized from muscle and liver glycogen. Catecholamine levels rise markedly, causing glucose production to rise 7-8 fold while glucose utilization is only increased 3-4 fold. This results in post-exercise hyperglycemia.`}
                </div>
                <div className="faq-ans">
                  The following actions can be advised to T2DM patients to
                  reduce the risk of hyperglycemia.
                  <br />
                  1) Check pre-exercise BG especially if going to perform high
                  intensity exercise.
                  <br />
                  2) Avoid intense exercise if BG levels >14mmol/L as BG levels
                  may rise further.
                  <br />
                  3) Advised to perform exercise at mild to moderate intensity.
                  <br />
                  4) Ensure proper hydration as dehydration can exacerbate the
                  hyperglycemia. This includes avoid exercising outdoor when it
                  is hot. They can also intersperse intense activities between
                  moderate intensity activities.
                  <br />
                  5) Corrections of insulin dose if they are on insulin.
                  <br />
                  6) Performing a proper cool down.
                </div>

                <div className="faq-ans">
                  In a nutshell, moderate intensity exercise does not usually
                  cause hyperglycemia in T2DM. However if exercise is performed
                  at high intensity, there is an increased risk of
                  hyperglycemia. Hence it is important to monitor BG levels when
                  starting or increasing the intensity of activity to see the
                  individual's response to exercise of varying intensities.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Are there any exercises that is NOT suitable for diabetic
                  patients?
                </div>
                <div className="faq-question">
                  Are there any exercises that is NOT suitable for diabetic
                  patients?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  As a general principle, most uncomplicated type 2 diabetic
                  patients can participate in most exercises without much
                  restriction. They can begin exercise at low intensity and
                  gradually progress to moderate intensity. However, certain
                  conditions may warrant modifications to the exercise program.
                  E.g Patients with peripheral neuropathy may need to avoid
                  weight bearing exercises such as walking or jogging and
                  instead do cycling or swimming or chair exercises. Patients
                  with arthritis also may need to avoid weight bearing exercise.
                  Patients with proliferative retinopathy will need to avoid
                  jumping and head down activities You may refer to the
                  following journal article for a more detailed discussion on
                  the above. 'Physical activity/Exercise and diabetes: A
                  position statement of the American Diabetes Association.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">What is aerobic capacity?</div>
                <div className="faq-question">What is aerobic capacity?</div>
                <div className="py-3">
                  Answer:
                  <br />
                  Aerobic capacity is also known as aerobic power. It refers to
                  the highest rate of oxygen consumption attainable during
                  maximal or exhaustive exercise. Maximal aerobic capacity is
                  the best objective measure of cardiorespiratory endurance.
                  Cardiorespiratory endurance is also known as aerobic endurance
                  refers to the ability to sustain prolonged, dynamic whole body
                  exercise using large muscle groups. In other words aerobic
                  capacity is a measurement that relates to our fitness level.
                  The higher the aerobic capacity, the higher the fitness level
                  and the better they are able to sustain prolonged exercise.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Examples of flexibility exercise
                </div>
                <div className="faq-question">
                  Pilates and Yoga, are they considered as flexibility exercise?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Both Pilates and Yoga do have components of flexibility but
                  they involve much more than that. They both include components
                  of strength, stability and flexibility with a focus on
                  breathing as well.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Snacks recommendations for diabetic patients for pre-workout
                  and post-workout.
                </div>
                <div className="faq-question">
                  My diabetic patient likes to cycle 20 km/day. However, during
                  the cycle he takes simple sugar drinks such as teh tarik
                  during the break. What snack should we recommend for diabetic
                  patients as pre-workout and post workout snack.
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  It is great that your patient is cycling regularly. For
                  diabetic patients who exercise, it is important that they fuel
                  up the right way before, during and after a workout. Generally
                  speaking most type 2 diabetic patients who exercise for less
                  than an hour need not take any supplemental snacks. However if
                  he cycles for more than an hour ( depending on his speed ) he
                  may need additional supplements to avoid hypoglycemia. However
                  there are other factors that may influence this:
                  <br />
                  1) Medications - whether he is on insulin or sulphonylureas
                  <br />
                  2) Timing of the meal Intensity of workout.
                  <br />
                  3) Whether he need to snack depends on his pre-exercise blood
                  glucose level and how long he plans to exercise.
                </div>
                <div className="faq-ans">
                  {`
                    The idea is to eat enough carbs to keep you from having a low reading but not so much as to cause his blood glucose level to spike. If he cycles more than an hour, ask him to check his blood glucose every 30 minutes, especially if he is increasing the intensity or duration of his activity. If pre-workout blood glucose is 5.5 - 15mmol/L - additional carbohydrate not likely needed if exercising for < 1hr. If exercising >1hr he may need slow acting carbohydrate supplementation and consider to include protein in the snack e.g a serving(15g) of peanut butter. Examples of 1 serving (15g) of slow acting carbohydrate like 250ml plain milk , 1 slice of bread 1 piece of fruit 2 sweet plain biscuits. Teh tarik ranges from 19-23g of carbohydrate. 
                    `}
                </div>

                <div className="faq-ans">
                  Ensure he is well hydrated before the exercise as diabetics
                  are prone to dehydration. Post-workout, he also needs to
                  monitor his blood glucose if he has been doing prolonged
                  exercise and to supplement accordingly if needed. Episodes of
                  hypoglycemia should be treated with fast acting carbohydrate.
                  It may take a few tries to figure out the right balance. Type
                  1 diabetic patients should consider discussing with a
                  dietitian regarding carbohydrate supplementation.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Stopping resistance training
                </div>
                <div className="faq-question">
                  If we stopped resistance training for a certain period, part
                  of body involved tend to gain easily eg core exercise. How to
                  approach this problem?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  My apologies, but the question isn't very clear. Do you mean,
                  if we stop doing resistance training like core exercises, we
                  will gain weight? If that is the question, here is my
                  response:-
                </div>
                <div className="faq-ans">
                  Core exercises train the muscles in your pelvis, lower back,
                  hips and abdomen. The core muscles transfer force from the
                  lower limb to the upper limb and vice versa as well as protect
                  the spine from excessive load. However, core stability
                  training for healthy and athletic populations has recently
                  been questioned and challenged in scientific literature. There
                  is also a lack of standardized agreement on the anatomical
                  structure and function of the core. However, having a stable
                  core leads to better balance and stability and can prevent
                  injuries. Regarding resistance training in general, it helps
                  to improve your muscular fitness, which is the strength and
                  endurance of the muscles. It also helps to prevent the natural
                  loss of lean muscle mass that occurs due to aging. Resistance
                  training, combined with aerobic exercise and a good diet may
                  help to reduce body fat. It does not however specifically lead
                  to "spot reduction" of fat in the targeted area. If you were
                  to stop your resistance training, you will begin to lose your
                  muscle mass and strength over a period of weeks to months
                  depending on your muscle fitness level at the beginning. The
                  longer you go without training, the more you will lose.
                  Ideally resistance training should be done 2-3 times a week if
                  you are working out the same muscle group. But if there is a
                  lack of time, then suggest to at least 1-2 days a week. This
                  may not be enough to increase the strength but at least it may
                  help to maintain their strength gains from the earlier
                  training sessions. In general, the more frequent the sessions,
                  the greater the strength gains.
                </div>

                <div className="faq-ans">
                  NOTE : Dear Dr, if this was not your intended question, please
                  do send in your question again and I will be happy to answer
                  accordingly.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Advice for patients with osteoarthritis or physically disabled
                  diabetics
                </div>
                <div className="faq-question">
                  How do we advice patients with osteoarthritis or physically
                  disabled diabetics?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Patients with osteoarthritis, especially of the knees will
                  benefit from performing both aerobic and strengthening
                  exercise. The aerobic exercise will help to improve their
                  fitness levels while resistance exercise will improve their
                  strength and reduce pain. These exercises will also help in
                  management of their other co-morbidities. In addition, they
                  should also perform stretching exercises which will help to
                  maintain and improve their joint range of motion as well as
                  perform exercises to improve their balance. Suggested aerobic
                  exercise will be low impact exercise - e.g walking , cycling,
                  swimming, water aerobics. If excessive walking exacerbates the
                  pain, then they may need to switch to other non-weight bearing
                  exercises as mentioned. They can also do multi-component
                  activities that include more than 1 type of activity like
                  yoga, tai chi etc.
                </div>
                <div className="faq-ans">
                  Patients with disability, are a heterogeneous group. For many
                  types of physical disabilities, physical activity reduces
                  pain, improves fitness, physical function and quality of life.
                  Generally even if they are confined to the wheelchair or use
                  assistive devices to ambulate, they should still try to
                  maintain an active lifestyle. They can perform arm cycling or
                  chair exercises which can be performed in multiple bouts of
                  shorter duration instead of a single session. You may refer to
                  the following website for further information.
                  <a href="https://www.cdc.gov/vitalsigns/disabilities/images/infographic_970px.jpg">
                    https://www.cdc.gov/vitalsigns/disabilities/images/infographic_970px.jpg
                  </a>
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  How does cotton socks lead to foot ulceration during exercise?
                </div>
                <div className="faq-question">
                  How does cotton socks lead to foot ulceration during exercise?
                  Have always thought cotton is the preferred material of
                  choice. TQ
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  There is controversy regarding the most suitable material for
                  socks in diabetic patients. But according to [ Physical
                  activity/Exercise and Diabetes. A position statement of ADA.
                  2016] it is not advisable to use pure cotton socks.
                </div>
                <div className="faq-ans">
                  Cotton is generally soft, breathable and comfortable which
                  makes it a very popular choice for socks. However, it becomes
                  a problem in diabetic patients especially in those with
                  peripheral neuropathy IF it becomes wet such as during
                  exercise.
                </div>

                <div className="faq-ans">
                  Diabetic patients especially with peripheral neuropathy have
                  to take proper care of their foot which includes keeping it
                  dry to prevent maceration of the skin. Maceration can occur at
                  anytime skin is exposed to moisture for extended periods. Pure
                  cotton socks are not advised as cotton absorbs moisture and
                  can easily become saturated in sweat making them vulnerable to
                  infections and ulceration. Hence polyester or blended socks
                  would be preferable. These materials will be able to wick away
                  the moisture. Other materials such as acrylic fiber, spandex
                  and nylon can also be used.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  What is resistance training?
                </div>
                <div className="faq-question">
                  Is weight lifting a resistance exercise or resistance bands
                  will do?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Resistence training also known as strength training is an
                  integral component of an exercise program for the general
                  public but more so for the diabetic patient. Resistance
                  training confers the following benefits:
                  <br />
                  - Increase in muscle mass
                  <br />
                  - Increase in muscle strength
                  <br />
                  - Improve BMD
                  <br />
                  - Improve glycemic control
                  <br />- Improved insulin sensitivity
                </div>
                <div className="faq-ans">
                  Infact, with regards to glycemic control, the impact of
                  resistance exercise is comparable to endurance exercise.
                  Resistance exercise can be done through the following ways:
                  <br />
                  1) Use of free weights - dumbbell or medicine ball
                  <br />
                  2) Use of resistance bands
                  <br />
                  3) Use of exercise machines - e.g leg press, knee extension
                  etc
                  <br />
                  4) Use of body weight - e.g squats , push ups etc
                </div>

                <div className="faq-ans">
                  RECOMMENDATION
                  <br />
                  The recommendation is to engage in resistance training 2-3
                  session/week. The aim is to have a 48 hour gap when training
                  the same group of muscles. Work out 8-10 different muscle
                  groups. Aim to perform at least 2 sets of 10 repetitions each
                  with 2-3 minute rest interval between sets. Just as with
                  aerobic exercise, the component of intensity is also important
                  in resistance exercise. This most commonly refers either the
                  magnitude of resistance ( commonly expressed as a % of 1RM )
                  the number of repetitions that can be performed. The number of
                  repetitions at a given load will determine the effect of
                  training. There are some differences between training with
                  resistance bands and free weights.
                </div>

                <div className="faq-ans">
                  FREE WEIGHTS ( LIFTING WEIGHTS E.g dumbbell )
                  <br />
                  Free weights exercise depends on the load and the resistance
                  is created by gravity. So, you must position yourself
                  correctly to work the muscle. You will not be able to work the
                  muscle if you lift the weight without opposing gravity.
                </div>

                <div className="faq-ans">
                  RESISTANCE BANDS
                  <br />
                  The resistance is created by the elastic force. Force is
                  created in the direction you are stretching the band. Because
                  of this, resistance band can provide force in any direction
                  and at any angle.
                </div>

                <div className="faq-ans">
                  COMPARISON
                  <br />
                  Briefly, these are a few of the diffferences between training
                  with free weights versus resistance bands. Resistance bands
                  works on the principle of variable resistance. As the band
                  gets stretched, it creates more resistance. Free weights have
                  a fixed weight, but they have a strength curve which means the
                  resistance will be different at different angles of the joint
                  range of motion. Principle of progressive overload. To make
                  strength gains, the workout must be made progressively
                  difficult ( i.e more sets, more repetitions or heavier weights
                  ). With free weight, this is easier as you know how much you
                  are lifting and it is easy to train progressively i.e by
                  increasing the weight. With resistance band, the resistance
                  depends on the degree of stretch and it it is hard to know how
                  much of load you are working with. One way however, is to use
                  bands of increasing resistance ( different colours ) to
                  progress. Resistance bands are safer than free weights.
                  Research has shown that heavier resistance training with free
                  weights and weight machines may improve glycemic control and
                  strength more. If you want to increase strength, resistance
                  bands are good way to start. However, you willl need to switch
                  to free weights as resistance bands won't be able to challenge
                  you in the same way as free weights. However, doing resistance
                  training of any intensity is recommended to improve strength,
                  balance and ability to engage in activities of daily living as
                  we age. With whatever method you chose, it is important to
                  begin the training program slowly. Progressing too quickly may
                  lead to injuries.
                </div>

                <div className="divider my-3"></div>
              </div>
            </Panel>

            <Panel
              header="Module 5: Engaging The Disengaged: Encouraging Behavior Change in People With Diabetes (Part 1)"
              key="5"
              onClick={() => handleSubmit('5')}
              style={panelStyle}
            >
              <div>
                <div className="sub-header-faq">
                  Demotivation for healthcare provider when using Motivational
                  Interviewing method
                </div>
                <div className="faq-question">
                  Hi Dr. Jamilah, when an open ended question is asked about
                  behavioral change (eg: What do you think you can do to cut
                  down the simple sugar intake?), the patient would only respond
                  with "I don't know", "I am not sure", "No idea", "If God wants
                  it to happen, it will happen." How would you encourage the
                  patient to come up with their own solution? Thanks.
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Yes. Some of my patients, especially those who still have
                  treatment resistance and have yet to establish a therapeutic
                  alliance, gave me the same answers. I agree, sometimes it
                  makes us feel demotivated or stuck and do not know how to
                  progress. However, sometimes, it was a genuine answer due to
                  their basic knowledge or limited readings. So, I would
                  normally use the below tips to move forward:
                </div>
                <div className="faq-ans">
                  1. To explore further what they have been learned or informed
                  previously?
                  <div>
                    2. To ask if it's OK if you suggest few options and we can
                    discuss it. End the session by asking your clients to
                    summarize what they have taken from the session and their
                    action plan.
                  </div>
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  HCPs have very limited time to counsel patients
                </div>
                <div className="faq-question">
                  Most of the time, it is pressure from hospital administrator
                  to see more patients, seeing more patients means spending less
                  time counsel. So how to go about this?
                </div>
                <div className="py-3">
                  Answer:
                  <br />I agree with you that this could be the hurdle and
                  barrier for effective communications with our patients. If you
                  can voice your concerns via your bosses, it would be good for
                  the authority to learn that it is important to have quality
                  time with our patients. However, if the above suggestions may
                  not be suitable, I suggest you break the skills you learned
                  from our webinar into smaller chunks and apply the
                  micro-skills. For example, by asking a simple question such as
                  "What is your thought about diabetes?" or "Apa yang Encik
                  fikirkan setiap kali Encik perlu makan ubat?" or "Do you think
                  'this' behavior helps attain your diabetes goal?". Do not
                  forget to have pause and listen effectively to their
                  responses. I believe in accumulated small positive behaviors
                  that are consistent.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  What to do when patient gives up managing their diabetes?
                </div>
                <div className="faq-question">
                  Sometimes patient just give up with his diabetes control and
                  say "takpelah doktor, saya pun dah lama hidup.." how to
                  response with this patient..?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Thank you for sharing. I would suggest you see the non-verbal
                  communications shared by the patient. Does the patient showed
                  some despair due to diabetes while saying that, or is the
                  patient is trying to tell you that they have had enough with
                  the session? If you think that is genuine despair, I would
                  suggest that you explore further what the patient is thinking
                  and feeling when they said that to you. Show them that you
                  care and want to understand more if diabetes has made them
                  feel hopeless and helpless. However, if you see that the
                  patient has some issue with the session, I suggest you ask
                  their opinion about the current treatment/sessions that they
                  received. You may know their thought about the session and
                  probably their belief system about the treatment, which blocks
                  their mindset from changing.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">Application of CBT & MI</div>
                <div className="faq-question">
                  Can we use CBT & MI to deal with diabetes burnt out?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  The simple is YES, absolutely! CBT was previously created to
                  deal with psychiatric disorders, and MI was initially created
                  for addiction/substance abuse. However, much literature has
                  found that both CBT and MI can now be used to help manage
                  other chronic illnesses, such as Diabetes (i.e., diabetes
                  distress and burnout).
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Who is suitable to use CBT?
                </div>
                <div className="faq-question">
                  Based on your experience, does CBT work better in someone with
                  higher levels of educations or the opposite? Sometimes, the
                  educated ones justify their behavior
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  This is a critical question. Based on the training and
                  literature I have reviewed, CBT works better with people with
                  higher educational/academic backgrounds. For our patients who
                  are educated and love to justify their actions, I suggest you
                  listen more to their justifications and learn to use
                  Motivational Interviewing to create ambivalence to their
                  arguments.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  How to prolong patient's positive change?
                </div>
                <div className="faq-question">
                  How do we make the positive change permanent or becomes a
                  habit (maintenance)?
                </div>
                <div className="py-3">
                  Answer:
                  <br />I want to start by stating - Nothing is permanent. It is
                  very normal to have lapses or relapses in our behavior change.
                  So yes, I agree to use the word maintaining the positive
                  behavioral change/habit. Below are some tips for maintaining
                  positive behavioral change:
                </div>
                <div className="faq-ans">
                  1. Make a goal and plan that are likely to stick. In other
                  words, more sustainable.
                  <br />
                  2. Start making small changes.
                  <br />
                  3. Change one behavior at a time. If we want our patients to
                  focus on changing their diet, focus on that first, Then,
                  slowly introduce the physical activity.
                  <br />
                  4. Involve other people in our plan and positive behavior
                  change.
                  <br />
                  5. Ask for support. This could include us, the healthcare
                  service providers (HCP).
                </div>

                <div className="faq-ans">
                  I am sharing with you the link for relevant reading material
                  on the above points.
                  <a href="https://www.apa.org/topics/behavioral-health/healthy-lifestyle-changes">
                    https://www.apa.org/topics/behavioral-health/healthy-lifestyle-changes
                  </a>
                </div>

                <div className="divider my-3"></div>
              </div>
            </Panel>

            <Panel
              header="Module 7: Discovering Strategies to Empower Patients in Self-monitoring (Part 1)"
              key="7"
              onClick={() => handleSubmit('7')}
              style={panelStyle}
            >
              <div>
                <div className="sub-header-faq">
                  Questions on blood sugar monitoring
                </div>
                <div className="faq-question">
                  Dr Tan, 1. You mentioned that post meal - premeal should be
                  less than 2. Please elaborate.
                </div>

                <div className="faq-ans">
                  Answer:
                  <br />
                  Q1: In nondiabetic individuals with adequate production of
                  insulin, the fasting blood glucose ranges between 4.1-5.5
                  mmol/L. The blood glucose level raises shortly and peak
                  approx. 60 mins to ≤ 7.8 mmol/L and returns to pre-prandial
                  level 2-3 hours later.
                </div>

                <div className="faq-ans">
                  However, due to pathophysiology of people with Type 2
                  diabetes, the peak insulin levels are delay and insufficient
                  to control the post-prandial hyperglycaemia (PPG). Based on
                  the paper by Parkin CG et al, 2011{' '}
                  <a
                    href="https://www.researchgate.net/profile/Christopher-Parkin/publication/288632469_Effective_Use_of_Structured_Self-Management_of_Blood_Glucose_in_Type_2_Diabetes_Lessons_From_the_STeP_Study/links/56fd593908aee995dde55c55/Effective-Use-of-Structured-Self-Management-of-Blood-Glucose-in-Type-2-Diabetes-Lessons-From-the-STeP-Study.pdf"
                    style={{
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                    }}
                  >
                    https://www.researchgate.net/profile/Christopher-Parkin/publication/288632469_Effective_Use_of_Structured_Self-Management_of_Blood_Glucose_in_Type_2_Diabetes_Lessons_From_the_STeP_Study/links/56fd593908aee995dde55c55/Effective-Use-of-Structured-Self-Management-of-Blood-Glucose-in-Type-2-Diabetes-Lessons-From-the-STeP-Study.pdf
                  </a>
                </div>
                <div className="faq-ans">
                  {`The author has defined 2 hours post-prandial hyperglycaemia as >2.8 mmol/L (50 mg/dl). I have used the difference between post-pre-meal as <3 for practical reason as most of our patients are with low literacy. It is difficult to remember decimal point.`}
                </div>

                <div className="faq-question">
                  2. Post meal RMS best measured after 1, 1/2 or 2 hours later?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Q2: Most literatures recommend 2 hours after the start of a
                  meal. (ref: ADA Consensus Statement Post-prandial glucose.
                  Diabetes Care 24: 77;775-778: 2001){' '}
                  <a
                    style={{
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                    }}
                    href="https://care.diabetesjournals.org/content/24/4/775"
                  >
                    https://care.diabetesjournals.org/content/24/4/775
                  </a>
                </div>

                <div className="faq-ans">
                  In the last decade some papers mention between 1-2 hours.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Post-Prandial Blood Glucose Target
                </div>
                <div className="faq-question">
                  For Malaysian, is 2hour post prandial less than 7.8 mmol for
                  the 3 meals taken okay?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />2 hours post prandial target is meant for all main
                  meals. It needs to be individualized and depends on the
                  guideline you use in your practice. Other factors to consider
                  the 2 hours post prandial targets are age of patients,
                  presence of other comorbidities, life expectancy, resources
                  and support system.
                </div>

                <div className="faq-ans">
                  {`The Malaysian T2DM CPG 2020 recommends 4.4-8.5 mmol/L for adults. For older person (>65yrs) who are functionally independent with no cognitive impairment, the International Diabetes Federation and Diabetes Canada recommend 5 to 10 mmol/L. However, those who are functionally dependent <12 mmol/L and <14 mmol/L for those who are frail and/or with dementia. For terminal patient, there is no recommended target.  The ADA recommends ≤ 10 mmol/L.`}
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Where to get affordable strips?
                </div>
                <div className="faq-question">
                  Any aid/resources for affordable devices/ strips especially
                  for the B40 group? Usually they can afford the device but not
                  the tester strips in the long term.
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  The B40 group can try to apply to social welfare or some NGO
                  e.g. Lion Club etc or write to the blood glucose meter
                  companies for free test strips. However, this is usually short
                  term aids. They can also try structured monitoring that was
                  discussed during the presentation based on 5-7 points tests on
                  3 consecutive days shortly before their consultation i.e. they
                  only needs 3-4 bottles of test strips a year.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Patient reluctant to do Self-Monitoring Blood Glucose (SMBG)
                </div>
                <div className="faq-question">
                  I started a patient on basal bolus regime, on SMBG. After
                  months of SMBG, he want to stop SMBG. At what sugar level can
                  i stop SMBG?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  It is important to explain to people with diabetes that SMBG
                  is like taking medicine. It goes on for life. However, the
                  frequency can be reduced when the glycaemic control is
                  achieved and maintained. The IDF low intensity (monitor 6
                  times a week) is a good recommendation. However, if they
                  prefer to do less, I usually negotiate with them to monitor
                  minimum of 4 times a week to ensure their condition is stable.
                  Blood glucose readings usually raised before other symptoms
                  appears e.g. complication, sick day etc.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Frequency of Home Monitoring of Blood Glucose (HMBG)
                </div>
                <div className="faq-question">
                  What is the frequency of HMBG for a patient with poor and good
                  sugar control respectively?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  For people with diabetes and out-of-target (poor) blood
                  glucose control, it is encouraged to monitor more frequently
                  to identify the causes of their out-of-target control e.g
                  dietary or medication or other issues. The IDF high intensity
                  monitoring is a good approach. The choices of the SMBG regimen
                  is individualized. If you suspect is dietary issues, then the
                  ‘C’ regimen provides the answers. If patient disagrees with
                  high intensity regimen, we can also use the IDF low intensity
                  monitoring regimen but take longer time to identify the causes
                  of high glucose readings. Once the people with diabetes is in
                  stable condition, good glucose control, the IDF low intensity
                  (monitor 6 times a week) is a good recommendation. Again if
                  they prefers to do less, I usually negotiate with them to
                  monitor minimum of 4 times a week.
                </div>

                <div className="faq-ans">
                  People with diabetes should be informed to increase their
                  frequency of monitoring for the following situations:
                  <br />
                  - During sick day
                  <br />
                  - When change of treatment (medications)
                  <br />
                  - Whenever there are persistent high glucose readings
                  <br />
                  - Whenever they have hypoglycaemia symptoms
                  <br />- When their lifestyle is different from usual days e.g.
                  travelling, festival
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Any recommended apps for SMBG?
                </div>
                <div className="faq-question">
                  Are there any recommended apps for SMBG monitoring (charting,
                  education)?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Some of commonly used blood glucose meters in the markets
                  (Roche Diabetes Care, Abbott Diabetes Care, Ascencia Diabetes
                  Care and Johnson and Johnson, there may be more of them) have
                  their own company apps that assist people with diabetes to
                  download and manage their own blood glucose data as well as
                  share with their healthcare providers. There is also an
                  independent app ‘Health2Sync’ that can connect certain meters
                  and share the data with the people with diabetes as well as
                  their healthcare providers.
                </div>

                <div className="divider my-3"></div>
              </div>
            </Panel>
          </Collapse>
        </div>

        <div className="faq-right-container">
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            accordion
            activeKey={[open2]}
          >
            <Panel
              header="Module 2: Understanding Current Eating Patterns & Trends for Diabetes Management"
              key="2"
              onClick={() => handleSubmit2('2')}
              style={panelStyle}
            >
              <div>
                <div className="sub-header-faq">
                  What is the difference between medical ketosis & nutritional
                  ketosis?
                </div>
                <div className="faq-question">
                  Dear Drs, what’s the different between medical ketosis &
                  nutritional ketosis and its dangers?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Nutritional ketosis is not a medical condition and does not
                  require a diagnosis. However, diabetes ketoacidosis is a
                  life-threatening condition: Diabetic ketoacidosis (DKA) is an
                  acute metabolic complication of diabetes characterized by
                  hyperglycemia, hyperketonemia, and metabolic acidosis. It
                  requires emergency treatment
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Diabetic-specific meal replacement formula
                </div>
                <div className="faq-question">
                  Q1: Prof, regarding meal replacement plan, what should we tell
                  our patients in clinic? They have to buy on own and any
                  particular brand of MRP that we can suggest to patients?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  A1: For patients who face challenges with food choices and are
                  willing to consider MRP, we can inform that it will help with
                  portion control and reduce glucose variability and
                  post-prandial blood glucose. They can use the MRP as partial
                  meal replacements, i.e. eat with protein or salad. I would
                  recommend diabetes-specific formulas only, and each service is
                  actually not that expensive, i.e. RM 4 - 5 only.
                </div>

                <div className="faq-question">
                  Q2: Thank you for your reply, Prof Nisak. Prof mentioned that
                  each service of diabetes-specific formula costs roughly RM 4 -
                  5, if patient is keen to give a try, do we refer patient to
                  dietitian for counselling or patient can just go to pharmacist
                  to get any diabetic specific meal replacement formula on own?
                  Thank you so much.
                </div>
                <div>
                  Answer:
                  <br />
                  Yes, the patient should be referred to a dietitian for a
                  proper meal replacement plan with a diabetes-specific formula
                  (DSF). Then, they can get the DSF at any pharmacy nearby to
                  them.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Intermittent fasting & Water fasting
                </div>
                <div className="faq-question">
                  May I know the evidence behind IF and water fasting in
                  managing T2DM?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  People with diabetes should not embark on intermittent fasting
                  without informing their physician. Anti-diabetic medications
                  will need adjustments, monitored closely and proactively to
                  reduce the risk of hypoglycaemia. Current evidence does not
                  show superiority of IF vs healthy eating for long term weight
                  loss and metabolic control. Water fasting certainly should not
                  be recommended for anyone
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Is long term intermittent fasting advisable?
                </div>
                <div className="faq-question">
                  How do we tailor the diet/advise those patients who are very
                  keen with intermittent fasting and will continue doing so even
                  when the HbA1c has been optimized?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  People with diabetes should not embark on intermittent fasting
                  without informing their physician. Anti-diabetic medications
                  will need adjustments, monitored closely and proactively to
                  reduce the risk of hypoglycaemia. Current evidence does not
                  show superiority of IF vs healthy eating for long term weight
                  loss and metabolic control. What the patient eats during his
                  window of eating still matters.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Recommendations for meal replacement formula
                </div>
                <div className="faq-question">
                  What type of products available in malaysia for meal
                  replacement?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  If for patients with diabetes, we prefer to use
                  diabetes-specific formula as there is clear evidence that it
                  would benefit weight and glucose outcomes as well as
                  cardiovascular risks.
                </div>
                <div>
                  Recommend diabetes-specific formula with evidence of efficacy.
                  A few brands available in pharmacies
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Suggestions for affordable meal replacement
                </div>
                <div className="faq-question">
                  Is there any available affordable meal replacement the
                  speakers can suggest as most of my patients are from rural
                  area?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />A challenging situation, unfortunately, evidence-based
                  MRPs are not affordable to poorer patients.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Examples of meal replacement products
                </div>
                <div className="faq-question">
                  Can Glucerna/Nutren Diabetic/other brand of Diabetic milk be
                  used as meal replacement?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Yes. All of these are categorised under the Diabetes-specific
                  formula.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Malaysian healthy plate model
                </div>
                <div className="faq-question">
                  For the summary of eating pattern, where is the position of
                  Malaysian healthy plate?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Malaysia healthy plate is promoted to reduce cereal intake,
                  increase vegetables and fruits and moderate amounts of lean
                  protein
                </div>

                <div className="divider my-3"></div>
              </div>
            </Panel>
            <Panel
              header="Module 4: Physical Activity: From Theory to Practice (Part 2)"
              key="4"
              onClick={() => handleSubmit2('4')}
              style={panelStyle}
              className="panel-header"
            >
              <div>
                <div className="sub-header-faq">Exercise recommendations</div>
                <div className="faq-question">
                  Volume of exercise is mentioned to be 150 minutes for weight
                  maintenance. Is this daily or weekly?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  The volume of exercise per week is a function of its duration,
                  intensity and frequency of exercise. Research has shown that
                  adults are more likely to maintain their weight if they have
                  an active lifestyle, reduce sedentary behaviour and adhere to
                  recommended dietary guidelines. Active lifestyle, in the true
                  sense means that they are achieving the minimum recommended
                  targets for exercise. Lets take a look at the following
                  figures.
                </div>

                <div className="faq-ans">
                  Recommendations are based on ACSM position stand of
                  Appropriate Physical Intervention Strategies for weight loss
                  and prevention of weight gain for Adults (Goals: Time/week)
                </div>

                <div>
                  - Maintain and improving health: 150mins/week
                  <br />
                  - Prevention of weight gain: 150-250mins/week
                  <br />
                  - Promote clinically significant weight loss: 225-420mins/week
                  <br />- Prevention of weight gain after weight loss:
                  200-300mins/week
                </div>

                <div className="faq-ans">
                  Looking at the above figures, it can be noted that the volume
                  of exercise needed to maintain weight ( i.e prevention of
                  weight gain ) is more than the recommendation of exercise for
                  health. These figures are for a weekly basis.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  How to reduce visceral fat?
                </div>
                <div className="faq-question">
                  How accurate is commercial body composition analyzer?
                  <div className="py-3">
                    I have a patient have started exercise/dietary program since
                    3 month, mainly doing HIIT (4 minutes) and resistant
                    exercise (10minutes), he lost significant amount of weight
                    and body fat.
                  </div>
                  However, his visceral fat from the body composition still
                  remain stuborn. Any advice? Can I suggest him to add aerobic
                  exercise?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  A) BODY COMPOSITION ANALYSIS
                </div>
                <div className="faq-ans">
                  There are many varieties of body composition analysers in the
                  market. I have briefly discussed regarding the BIA machines as
                  below. You had mentioned that his visceral fat was measured.
                  Then he must have used a high-end BIA machine as the more
                  common BIA machines don't have segmental analysis. Accuracy of
                  BIA depends on a few factors. Devices that use multiple
                  frequencies are more accurate than single frequency devices It
                  should give more information than just body fat %.
                </div>

                <div className="faq-ans">
                  Possible sources of error – physical activity, nutrition
                  status, hydration level, not adhering to pre-test protocols
                  (.eg fasted state, refrain from exercise/caffeine/alcohol etc
                  ), input of data such as gender, age, weight and height as
                  variables
                </div>

                <div>
                  Types of BIA:
                  <br />
                  1) Hand-held BIA – measure impedance in upper body and use it
                  to estimate lower half of body and based on certain
                  assumptions
                  <br />
                  2) Home scale BIA – measure impedance for lower body and use
                  it to estimate for upper body based on certain assumptions
                  <br />
                  3) "Whole body BIA” – more accurate than ( 1 ) and ( 2) but it
                  measures data from the arm and leg that the electrode is
                  placed on and must still estimate for the rest of the body.
                  <br />
                  4) DSM-BIA – direct segmental multifrequency – analyse in both
                  arm, both legs and torso. These are based on direct
                  measurements and not estimations.
                  <div className="py-2">
                    Accuracy of the commercial BIA depends which type of machine
                    is being used, with no ( 4 ) being the most accurate.
                  </div>
                  B) VISCERAL FAT
                  <br />
                  Generally, when we exercise, it will help to reduce overall
                  fat which also includes visceral fat. However, changes in
                  visceral fat volume as a response to physical exercise vary
                  considerably and that it is not possible to identify a clear
                  correlation between amount of physical exercise and reduction
                  in visceral fat. Any reduction of visceral fat tissue deposits
                  relates to reduction of the total amount of fat tissue.
                  <div className="py-2"></div>
                  Factors that may be contributing to "stubborn" visceral fat:
                  <br />
                  1) Genetics – Asians demonstrate greater visceral fat storage
                  despite lower BMI values
                  <br />
                  2) Stress – does he appear to be stressed? ( family/home/work
                  etc ) Research has shown that when there is chronic stress,
                  cortisol levels in the body rise resulting in preferential
                  deposition of fat in the abdomen. May need to look into
                  managing his stress if indeed it is a factor.
                  <br />
                  3) Sedentary - Is the nature of his job sedentary? Please note
                  that irrespective of his daily exercise program, he may be
                  considered sedentary if the nature of his job involves long
                  hours of desk based and he gets less than 5000 steps/day.
                  <br />
                  4) Type of exercise - There is mixed evidence between the
                  superiority of HIIT exercise and MICT (moderate intensity
                  continuous training) for weight loss. But generally both have
                  been found to be effective. The only difference is HIIT takes
                  less time. But whichever is done, the volume needs to be
                  adequate for any meaningful weight loss ( i.e the total energy
                  expenditure). Some studies have found that a combination of
                  HIIT and MICT was the most effective. Perhaps he can try
                  increasing the volume of exercise and combining moderate
                  intensity exercise to his current regime.
                  <br />
                  5){' '}
                  {`Sleep - How many hours does he sleep every night? Studies have found that chronic sleep deprivation (< 7hours/night) increases cortisol secretion and hence leads to increased visceral adiposity.`}
                  <br />
                  6) Resistance training - How many days does he do his
                  resistance training ? Has he been gradually progressing his
                  load? Resistance training is important as it helps to keep his
                  metabolism elevated. Maybe can suggest to him to do it more
                  frequently. Can even do it daily if targeting different muscle
                  groups. As long as there is a 48 hour gap when exercising the
                  SAME muscle group it should be fine. And importantly he must
                  be gradually progressing the intensity i.e either the load or
                  the number of repetitions per exercise
                  <br />
                  7) Timing of his exercise - When does he perform his exercise?
                  Maybe can suggest to him to perform exercise first thing in
                  the morning (fasted state). Research has shown that exercising
                  during the fasting state increases adipose tissue lipolysis
                  and peripheral fat oxidation via higher plasma adrenaline and
                  cortisol concentrations and lower circulating insulin levels.
                  However, this may not be the best option if he is a diabetic.
                  <div className="mt-3">
                    So in response to your question, yes, you may suggest for
                    him to add moerate intensity continous aerobic exercise to
                    his training program and also re-evaluate at his resistane
                    training workout.
                  </div>
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Is gardening considered as exercise?
                </div>
                <div className="faq-question">
                  Hi Dr. My patient always say that he do daily gardening in his
                  fruit farm about 3-4H a day and do all types of chores such as
                  cleaning, weeded, hoed and others. Is that consider as daily
                  exercise? Are there specific chores that they need to do
                  during gardening to be considered as doing moderate exercise?
                  Thank you.
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  It is good that your patient does a lot of physical activity
                  in his garden. However, you need to stress upon him that while
                  that is beneficial for him, he still needs to perform a
                  structured exericse program to improve on his cardiovascular
                  risk profile and overall health. Since your question is quite
                  similar to an earlier question I would like to duplicate my
                  repsonse here.
                </div>
                <div className="faq-ans">
                  There are 4 domains of physical activity - occupational,
                  domestic, transportation and leisure time. Gardening is
                  considered as part of occupational or domestic related
                  activities. Exercise will fall under the category of leisure
                  time activites.
                </div>

                <div className="faq-ans">
                  Yes, this is a common situation in which people who perform
                  physically demanding jobs either at work or at home assume
                  that they can consider it as exercise. In actual fact, they
                  can consider themselves as being less sedentary but cannot
                  consider themselves as physically active. In the true sense of
                  meaning, being physically active means that your exercise
                  regime is meeting the recommended target. But most people (
                  lay public and HCW ) tend to use the term physically active in
                  a loose and general manner.
                </div>

                <div className="faq-ans">
                  Coming back to the question, it is good that he does gardening
                  everyday, which means he spends less time being sedentary as
                  prolonged sedentary time has deleterious effect on metabolic
                  health with poorer glycemic control. But we need to remember
                  that these 3 aspects, i.e time spent in sedentary behaviour,
                  physical activity levels and physical fitness are INDEPENDENT
                  PREDICTORS of mortality. Which means, doing well in 1 aspect (
                  less sedentary time in this case ) but lacking in the other 1
                  or 2 aspects still puts him at a higher risk of morbidity and
                  mortality. So being active at work will help to improve his
                  glycemic control but it will not be enough to confer him the
                  benefits of regular exercise done at moderate intensity. So,
                  he still needs to engage in a structured and regular exercise
                  program as this is where he will improve his fitness as well
                  derive the majority of health benefits. He should strive to
                  perform moderate intensity aerobic exercise for 150 mins/week
                  as well as resistance training to benefit from improved
                  diabetes management, improve his cardiovascular risk profile
                  and improve his overall health and wellbeing.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Precautions for exercise at night
                </div>
                <div className="faq-question">
                  Is there any specific precautions on time suitable for
                  exercise, some patients only manage to exercise at night.. Any
                  issues with that?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Unfortunately, there is no straightforward answer to that
                  question. Currently, none of the available guidelines
                  including ADA position stand 2016, specifically make any
                  reference to exercise timing in diabetic patients. There has
                  been conflicting studies into the optimal timing for exercise.
                  Most but not all studies show that exercising after meals has
                  a greater benefit on postprandial glycemia compared to premeal
                  exercise. In fact many studies have found that exercise in the
                  fasted state may predispose to hyperglycemia. For people with
                  type 1 Diabetes, glucose control during exercise is
                  challenging as exercise can cause either hypoglycemia or
                  hyperglycemia. Type 2 diabetic patients are less prone to
                  episodes of hypoglycemia.
                </div>

                <div className="faq-ans">
                  The glucose response post-exercise depends on many variables:-
                  1) Diabetes type
                  <br />
                  2) Type of medication
                  <br />
                  3) Timing of medications
                  <br />
                  4) Blood glucose level before exercise
                  <br />
                  5) Serum insulin level at time of exercise
                  <br />
                  6) Exercise factors
                  <br />
                  7) Type of exercise
                  <br />
                  8) Duration of exercise
                  <br />
                  9) Exercise intensity
                  <br />
                  10) Level of fitness
                  <br />
                  11) Food intake – timing , amount , type
                </div>
                <div className="faq-ans">
                  Undoubtedly, exercise at any time of the day is encouraged.
                  Regular exercise significantly decreased time spent in
                  hyperglycemia despite not having a significant effect on
                  fasting glucose concentration. It also confers benefits with
                  regards to cardiovascular risk factors. Traditionally,
                  exercise at night has generally not been recommended as it can
                  cause hypoglycemia overnight due to increased insulin
                  sensitivity. Interestingly, however a recent review suggested
                  that exercise after the evening meal would have the greatest
                  impact on correcting the inverted circadian physiology found
                  in diabetic individuals. This was however based on the premise
                  that dinner was the largest meal for the day.
                </div>

                <div className="faq-ans">
                  Based on the numerous reviews, it seems that exercising 10-15
                  mins after every meal may be a better option. If exercising in
                  the evening, better to exercise 30 minutes post-meal to take
                  advantage of the blunting of the glucose peak and the high
                  insulin to glucagon ratio rather than exercising late in the
                  post-prandial period which has a higher risk of hypoglycemia.
                  Importantly, the patient will need to find a time that is
                  convenient and suitable for them so adherence will be better.
                  In view of that, it will be a good idea for them to track the
                  blood glucose pre and post exercise for a few hours to see
                  what the trend is like as every person responds differently to
                  exercise.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Exercise recommendations and advice for morbid obesity patient
                </div>
                <div className="faq-question">
                  Any recommend exercise type and advice for Morbid obesity
                  patient? (Maybe around 40-50 years old)
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Research has shown that adults are more likely to maintain
                  their weight if they have an active lifestyle and reduce
                  sedentary behaviour. High physical activity (PA) levels or
                  exercise training should be an integral part of any treatment
                  plan for obese individuals irregardless of weight loss goals
                  and is associated with numerous benefits. High levels of PA
                  and cardiorespiratory fitness (CRF) are inversely associated
                  with cardiovascular disease and all-cause mortality. High CRF
                  is associated with greater survival in all BMI categories. So
                  clinicians should always encourage their patients to perform
                  exercise regardless of the weight loss acheived.
                </div>
                <div className="faq-ans">
                  The most common dilemma in the clincial setting is how much
                  exercise is needed to lose weight and what type of exercise
                  should be performed. It must be stated that exercise is less
                  effective than diet as a sole weight loss intervention.
                  Addition of exercise to a dietary intervention may help to
                  enhance weight loss. The role of exercise in obesity is as
                  follows to prevent further weight gain, maintain weight loss
                  indued by dietary means & improved body composition -
                  decreased visceral adiposity. The main aim of exercise is to
                  improve CRF.
                </div>

                <div className="faq-ans">
                  Lets take a look at the following figures ( Recommendations
                  are based on ACSM position stand of Appropriate Physical
                  Intervention Strategies for weight loss and prevention of
                  weight gain for Adults ): Goals (Time/week)
                  <br />
                  - Maintain and improving health (150mins/week)
                  <br />
                  - Prevention of weight gain (150-250mins/week)
                  <br />
                  - Promote clinically significant weight loss
                  (225-420mins/week)
                  <br />- Prevention of weight gain after weight loss
                  (200-300mins/week)
                </div>

                <div className="faq-ans">
                  Looking at the above figures, it is to be noted that the
                  volume of exercise needed to induce weight loss is much more
                  than the recommendation of exercise for health. Though it is
                  possible to do, it is generally beyond the reach and
                  capability of most adults and may not be practical or
                  sustainable. As far as possible, we should encourage them to
                  follow the same aerobic and strengthening exercise
                  recommendation for the general public. Strenghtening exercise
                  helps to increase their metabolic rate. Please ensure that
                  these individuals undergo evaluation to exclude
                  contraindications and to assess other comorbidities that may
                  require special considerations and modifications.
                </div>
                <div className="faq-ans">
                  The following are recommendations strategies for obese
                  patients:
                  <br />
                  1) Reduce sedentary time and increase physical activity - can
                  use pedometers to count steps. Aim for >8000 steps/day.
                  <br />
                  2) Go for a walk after meals Strive for aerobic exercise >5
                  days /week to maximise caloric expenditure.
                  <br />
                  3) Type of exercise - No specific type. It can be lower body
                  like walking, brisk walking, cycling or upper body like
                  rowing, arm ergometry. In place exercise - pls refer to
                  example in my slides. Generally speaking lower body exercise
                  burns more calories because of the bigger muscle bulk. Perform
                  activity in bouts of 10 min before undertaking 1 continous
                  session ( to minimise joint stress and reduce potential muscle
                  soreness ).
                  <br />
                  4) Aim for 45 - 60 mins/day gradually over a period of upto 12
                  weeks. Strengthening exercise - e.g push up, squats, dumbbell
                  exercise for upper limbs, calf raise, lunges etc. Alternate
                  the workouts to avoid boredom. i.e alternate the types of
                  aerobic and strengthening exercise so as to avoid doing the
                  same exercise daily
                </div>
                <div className="faq-ans">
                  It is important that we emphasize to the patients that
                  exercise alone may not lead to weight loss ( at least in the
                  initial stages when we haven't reached the targets ). The
                  importance of improving CRF and that numerous health benefits
                  occur in the abscence of weight loss. Maintenance of an active
                  lifestyle will reduce risk of future weight gain. This is
                  important so that they will have an appreciation of the role
                  of exercise and not be discouraged from the lack of weight
                  loss. The important thing is consistency in their exercise
                  program.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Can physiotherapists in Klinik Kesihatan prescribe exercise
                  for DM patients?
                </div>
                <div className="faq-question">
                  Are physiotherapists stationed at Klinik Kesihatan trained to
                  prescribe exercises for DM patients?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Our physiotherapy collegues can certainly prescribe exercises
                  for most diabetes patients. However, in your evaluation, if
                  the patient has complications, co-morbids or any signs or
                  symptoms that suggest cardiac involvement please perform a
                  detailed asessment and determine what are the precautions or
                  modifications that may be needed. This information must then
                  be communicated to the physiotherapist so that the most
                  suitable program taking into account the precautions,
                  modifications and limitations of the patients can be
                  prescribed to ensure safety of exercise participation for the
                  patient.
                </div>
                <div className="faq-ans">
                  Consider the following example: A 55 year old male T2DM
                  patient with obesity, hypertension, knee osteoarthritis and
                  having peripheral neuropathy
                </div>

                <div className="faq-ans">
                  Medications: Metformin, Beta blocker
                </div>
                <div className="faq-ans">
                  Evaluation: No symptoms suggestive of cardiac involvement
                </div>

                <div className="faq-ans">
                  Issues: Knee osteoarthritis - considering patient is obese,
                  excessive weight bearing may accelerate degeneration
                  peripheral neuropathy - prone to foot ulcers with excessive
                  weight bearing activities Beta blocker - may cause
                  hypoglycemia unawareness, blunt heart rate response and cause
                  sudden reductions in post-exercise blood pressure
                </div>

                <div className="faq-ans">
                  Recommendations:
                  <br />
                  1) Low impact activities - walking or NWB e.g cycling, arm
                  ergometer, chair exercises depending on patient's condition
                  <br />
                  2) Rehab program for knee osteoarthritis - stretching,
                  strengthening and neuromotor exercises may need to monitor
                  exercise intensity using BORG scale or talk test instead of HR
                  <br />
                  3) In view of blunted heart rate response, please advise to
                  check blood glucose before and after exercise for a few days
                  to look for BG trend and especially if increasing exercise
                  intensity
                  <br />
                  4) In view of hypoglycemia unawareness, may need an extended
                  cool down period
                  <br />
                  5) In view of possible reduction in post-exercise blood
                  pressure
                </div>
                <div className="faq-ans">
                  Plan: Inform physiotherapist collegues on recommendations.
                  They will be able to plan a suitable program specifically for
                  this patient. If patient has not started a formal exercise
                  program, always start low and go slow. Generally speaking
                  exercise at low intensity is safe for most people. To achieve
                  the benefits of an exercise program, aerobic exercise needs to
                  be progressed to moderate intensity over a period of time.
                  This needs to be done in a progressive manner taking into
                  account patient's tolerance and limitations.
                </div>
                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">Pre-breakfast cortisol</div>
                <div className="faq-question">
                  How does pre-breakfast cortisol increase/affect insulin
                  sensitivity?
                </div>
                <div className="py-3">
                  Answer:
                  <br />I am assuming this is related to exercise timing for
                  type 2 diabetic individuals. Circulating glucose
                  concentrations reflect the balance between the rate of arrival
                  ( from exogeneous and endogeneous sources ) and glucose
                  utilization. Based on a recent systematic review [Shaun et al
                  (2018). Exercise Timing in Type 2 Diabetes Mellitus: A
                  Systematic Review. Medicine & Science in Sports & Exercise.
                  2018; 50. 1. 10.] it was found that exercise performed in the
                  post-prandial period appeared to result in more consistent
                  reductions in blood gluose concentrations. However, there are
                  many studies that showed conflicting results with regards to
                  exercise timing in T2DM. Due to the complexities and
                  controversies surrounding the exercise timing, the 2019 ADA
                  Diabetes Diagnosis and Treatment standard recommendations on
                  physical activity have not specifically made any
                  recommendations with regards to that in patients with
                  diabetes. There are conflicting views regarding exercise in a
                  fasted state. One theory is that the glycogen depletion in the
                  fasted state improves insulin sensitivity. On another hand,
                  physiologically , exercising in the fasted state prolongs the
                  fasting period and may promote an even greater release in
                  counter-regulatory hormones like cortisol which antagonizes
                  the action of insulin. Cortisol stimulates gluconeogenesis and
                  increases mobilization of free fatty acids to ensure an
                  adequate fuel supply. According to research by Roden M et al
                  Mechanism of free fatty acid-induced insulin resistance in
                  humans. J Clin Invest. 1996;97:2859–2865 suggest that increase
                  FFA in plasma may cause reduced insulin sensitivity in
                  skeletal muscle.
                </div>
                <div className="faq-ans">
                  While exercising 1/2 hour post meal seems to provide more
                  consistent reductions in blood glucose, there seems to be pros
                  and cons for exercise at varying times of the day - both in
                  fasted , premeal and post meal. So, based on available
                  evidence it appears that manipulating exercise timing does not
                  appear to be critical for eliciting greater improvements in
                  glycemia control. Patients need to find timings that are
                  suitable for them and that they can adhere to to elicit the
                  long term benefits of a sustained exercise program. It will be
                  advisable for patients to monitor their blood glucose levels
                  pre and post exercise for a few days to observe the glucose
                  trend.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Difference between aerobic, flexibility & resistance exercise
                </div>
                <div className="faq-question">
                  How to differentiate aerobic, flexibility from resistance
                  exercise? What are their differences?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Aerobic, strengthening and flexibility are different
                  categories of exercise. For a comprehensive exericse program,
                  it will be beneficial to add all 3 components. There is a 4th
                  category of exercise, known as neuromotor exercise that can be
                  advised for a specific target group.
                </div>
                <div className="faq-ans">
                  I have prepared a brief summary of the three types of exercise
                  for better understanding.
                </div>

                <div className="faq-ans">
                  A) AEROBIC EXERCISE
                  <br />
                  These are activities that use large muscle groups and can be
                  maintained continuosly and is rhythmic in nature. It raises
                  your heart rate and breathing to cardiorespiratory system to
                  send more oxygen to the muscles. Main aim is to improve
                  cardiorespiratory fitness. Any movement pattern, involving
                  upper limb or lower limb, if done repetitively for 20-30
                  minutes can be considered aerobic.
                </div>

                <div className="faq-ans">
                  B) RESISTANCE EXERCISE
                  <br />
                  Exercise that causes the muscles to contract against an
                  external resistance. The main aim is to strengthen muscles and
                  improve function. It can be done using dumbbells, resistance
                  bands, machines or even our own body weight. The contraction
                  must be held for 5-10secs and perform 1-2 sets ( each set ~10
                  repetitions ).
                </div>

                <div className="faq-ans">
                  C) FLEXIBILITY EXERCISE
                  <br />
                  Exercises that stretch the muscles and improve range of motion
                  at the joints. Can be performed without any special equipment
                  though certain tools can help to enhance the stretch The
                  stretch has to be held for 10-30seconds with 2-4 repetitions
                  per muscle.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Patient said “I can’t exercise during covid pandemic."
                </div>
                <div className="faq-question">
                  Any tips in advising patients who give excuse that “I can’t
                  exercise during covid pandemic” because he insisted exercise
                  is outdoor activity only.
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Many people feel that they can exercise better outdoor ( e.g
                  walking, jogging, cycling ) due to the following reasons:
                  <br />
                  - ample space for them to exercise
                  <br />
                  - fresh air
                  <br />
                  - exposure to sunshine & natural surroundings
                  <br />
                  - less stress
                  <br />- can socialise
                </div>
                <div className="faq-ans">
                  While the experience of outdoor exercise can't be totally
                  replaced, he can still derive the benefits of exercise from
                  performing it indoors. It is especially important that in
                  current times of Covid pandemic where it may not be advisable
                  or allowable to exercise outdoors, to continue with the
                  exercise program as otherwise he risks losing whatever fitness
                  gains he has made if he stops his exercise sessions for too
                  long a time.
                </div>

                <div className="faq-ans">
                  Some of the benefits of indoor exercise include the following:
                  <br />
                  - can exercise even with unfavourable weather conditions
                  <br />
                  - can use commercial equipment ( if available ) - they can be
                  programmable to suit client needs
                  <br />
                  - can follow online exercise videos
                  <br />- can alternate between resistance and aerobic exercise
                </div>

                <div className="faq-ans">
                  Indoor aerobic exercises can range from beginner to
                  intermediate to advanced level ( depending on age, physical
                  condition and limitations ).
                </div>

                <div className="faq-ans">
                  Some examples are as follows:
                  <br />
                  i) Beginner - jumping jacks, jumping jills, lateral shuffle,
                  butt kicks, high knees
                  <br />
                  ii) Intemediate - squat jumps,box jums
                  <br />
                  iii) Advanced - burpees, mountain climbing
                </div>
                <div className="faq-ans">
                  There are also chair based exercises for those who need to
                  perform low impact exercise. The important thing to note is
                  that these exercise can be performed for few minutes and
                  alternated with strength exercises e.g lifitng weights,
                  squats, lunges etc. It is totally upto the creativity of the
                  individual and they can be mixed and matched! As with any
                  exercise, start low and go slow! You can visit this website to
                  get an idea of examples of exercises that can be performed at
                  home:-
                  <a href="https://www.healthline.com/health/cardio-exercises-at-home">
                    https://www.healthline.com/health/cardio-exercises-at-home
                  </a>
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Why do patient get headache 2 hours after aerobic exercise?
                </div>
                <div className="faq-question">
                  I have a patient that get headache 2 hours after aerobic
                  exercise, roughly once per week. May I know why?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  You mentioned that she gets headache after aerobic exercise
                  roughly once per week. More information is needed to better
                  understand the condition:
                </div>
                <div className="faq-ans">
                  - The nature of the headache and any associated symptoms?
                  <br />
                  - Does she have underlying migraine?
                  <br />
                  - Does this headache ONLY occur after exercise or does she
                  also experience it at other times?
                  <br />
                  - Does it occur after EVERY exercise session or only some
                  sessions?
                  <br />
                  - Is she exercising outdoor or indoor and at what time.
                  <br />
                  - Is it hot when she is exercising?
                  <br />
                  - Does she drink adequate quantities of water before she
                  exercises?
                  <br />- How long does she exercise and roughly at what
                  intensity?
                </div>

                <div className="faq-ans">
                  BACKGROUND
                  <br />
                  Post exercise headache may be a symptom of dehydration which
                  diabetics have an increased risk of. It may be associated with
                  thirst, light-headedness, dry mouth etc. Exercise can also be
                  a trigger for migranous headache. Headache post exercise is
                  known as exercise headache, though previously it was known as
                  exertional headache. It may be primary ( no underlying
                  abnormality ) or secondary ( malignancies, cerebrovascular
                  abnormalities etc ). That is why careful assessment and
                  evaluation is needed for people presenting with post-exercise
                  headache to rule out serious underlying pathologies.
                </div>

                <div className="faq-ans">
                  PRIMARY EXERCISE HEADACHE
                  <br />
                  It is a benign condition, characterised by throbbing or
                  pulsatile pain which is bilateral. The headache tends to build
                  up over minutes or longer. These usually start 30 min after
                  the start of exercise and can last 5 min to 48 h, although
                  half will resolve within 60 min. It is usually NOT accompanied
                  by nausea or vomiting. It may occur after sustained ,
                  strenuous physical activity especially during hot weather. For
                  patients with new onset or have never been investigated for
                  exercise headache- will need evaluation and further imaging if
                  warranted. Another interesting note to consider is evaluation
                  for CHD in patients who have cardiovascular risk factors as
                  there are a few instances ( mainly case reports ) of
                  myocardial ischemia in patients presenting with post-exercise
                  headache but without chest pain or evidence of ischemia on
                  ECG. Currently, there is scare evidence regarding treatment of
                  primary exercise headache. The following may be recommended to
                  the patient as prevention - extended warm up period, gradual
                  increase in activity level over weeks or months avoiding hot
                  weather and ensuring proper hydration.
                </div>

                <div className="faq-ans">
                  FOR THIS PATIENT, it will be hard to tell whether her headache
                  is due to dehydration, a part of migraine ( if she has ) or
                  Exercise headache ( which could be primary or secondary ).
                  Although most cases of exercise headache are primary and
                  benign, it would be prudent to evaluate this patient to
                  exclude secondary causes especially if it a new onset or
                  atypical presentation.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Why high intensity exercise cause hyperglycaemia?
                </div>
                <div className="faq-question">
                  Why high intensity exercise cause hyperglycaemia? Any caution
                  / advise we should tell our DM patient who do high intensity
                  exercise?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  {`Plasma glucose concentration during exercise depends on a balance between glucose uptake by exercising muscles and glucose release by the liver. As a result of a variety of unpredictable factors, exercise may cause either hypoglycemia or hyperglycemia in persons with diabetes. With regards to exercise induced hyperglycemia, it is more common in T1DM compared to T2DM. This could be due to insulin omission or reduction and overconsumption of carbohydrates. Hyperglycemia may also occur if the pre-exercise blood glucose levels are elevated. In T2DM, the effect is different for moderate intensity exercise ( < 60% VO2max ) and high intensity exercise ( >80% VO2max). The amount of glucose released by the liver depends on the exercise intensity and duration. As intensity increases, so does the rate of catecholamine release. With moderate intensity exercise ( <60% VO2max ) glucose uptake is matched by that of production. In intense exercise (>80% VO2max), glucose is the exclusive fuel for muscle and must be mobilized from muscle and liver glycogen. Catecholamine levels rise markedly, causing glucose production to rise 7-8 fold while glucose utilization is only increased 3-4 fold. This results in post-exercise hyperglycemia.`}
                </div>
                <div className="faq-ans">
                  The following actions can be advised to T2DM patients to
                  reduce the risk of hyperglycemia.
                  <br />
                  1) Check pre-exercise BG especially if going to perform high
                  intensity exercise.
                  <br />
                  2) Avoid intense exercise if BG levels >14mmol/L as BG levels
                  may rise further.
                  <br />
                  3) Advised to perform exercise at mild to moderate intensity.
                  <br />
                  4) Ensure proper hydration as dehydration can exacerbate the
                  hyperglycemia. This includes avoid exercising outdoor when it
                  is hot. They can also intersperse intense activities between
                  moderate intensity activities.
                  <br />
                  5) Corrections of insulin dose if they are on insulin.
                  <br />
                  6) Performing a proper cool down.
                </div>

                <div className="faq-ans">
                  In a nutshell, moderate intensity exercise does not usually
                  cause hyperglycemia in T2DM. However if exercise is performed
                  at high intensity, there is an increased risk of
                  hyperglycemia. Hence it is important to monitor BG levels when
                  starting or increasing the intensity of activity to see the
                  individual's response to exercise of varying intensities.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Are there any exercises that is NOT suitable for diabetic
                  patients?
                </div>
                <div className="faq-question">
                  Are there any exercises that is NOT suitable for diabetic
                  patients?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  As a general principle, most uncomplicated type 2 diabetic
                  patients can participate in most exercises without much
                  restriction. They can begin exercise at low intensity and
                  gradually progress to moderate intensity. However, certain
                  conditions may warrant modifications to the exercise program.
                  E.g Patients with peripheral neuropathy may need to avoid
                  weight bearing exercises such as walking or jogging and
                  instead do cycling or swimming or chair exercises. Patients
                  with arthritis also may need to avoid weight bearing exercise.
                  Patients with proliferative retinopathy will need to avoid
                  jumping and head down activities You may refer to the
                  following journal article for a more detailed discussion on
                  the above. 'Physical activity/Exercise and diabetes: A
                  position statement of the American Diabetes Association.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">What is aerobic capacity?</div>
                <div className="faq-question">What is aerobic capacity?</div>
                <div className="py-3">
                  Answer:
                  <br />
                  Aerobic capacity is also known as aerobic power. It refers to
                  the highest rate of oxygen consumption attainable during
                  maximal or exhaustive exercise. Maximal aerobic capacity is
                  the best objective measure of cardiorespiratory endurance.
                  Cardiorespiratory endurance is also known as aerobic endurance
                  refers to the ability to sustain prolonged, dynamic whole body
                  exercise using large muscle groups. In other words aerobic
                  capacity is a measurement that relates to our fitness level.
                  The higher the aerobic capacity, the higher the fitness level
                  and the better they are able to sustain prolonged exercise.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Examples of flexibility exercise
                </div>
                <div className="faq-question">
                  Pilates and Yoga, are they considered as flexibility exercise?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Both Pilates and Yoga do have components of flexibility but
                  they involve much more than that. They both include components
                  of strength, stability and flexibility with a focus on
                  breathing as well.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Snacks recommendations for diabetic patients for pre-workout
                  and post-workout.
                </div>
                <div className="faq-question">
                  My diabetic patient likes to cycle 20 km/day. However, during
                  the cycle he takes simple sugar drinks such as teh tarik
                  during the break. What snack should we recommend for diabetic
                  patients as pre-workout and post workout snack.
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  It is great that your patient is cycling regularly. For
                  diabetic patients who exercise, it is important that they fuel
                  up the right way before, during and after a workout. Generally
                  speaking most type 2 diabetic patients who exercise for less
                  than an hour need not take any supplemental snacks. However if
                  he cycles for more than an hour ( depending on his speed ) he
                  may need additional supplements to avoid hypoglycemia. However
                  there are other factors that may influence this:
                  <br />
                  1) Medications - whether he is on insulin or sulphonylureas
                  <br />
                  2) Timing of the meal Intensity of workout.
                  <br />
                  3) Whether he need to snack depends on his pre-exercise blood
                  glucose level and how long he plans to exercise.
                </div>
                <div className="faq-ans">
                  {`
                    The idea is to eat enough carbs to keep you from having a low reading but not so much as to cause his blood glucose level to spike. If he cycles more than an hour, ask him to check his blood glucose every 30 minutes, especially if he is increasing the intensity or duration of his activity. If pre-workout blood glucose is 5.5 - 15mmol/L - additional carbohydrate not likely needed if exercising for < 1hr. If exercising >1hr he may need slow acting carbohydrate supplementation and consider to include protein in the snack e.g a serving(15g) of peanut butter. Examples of 1 serving (15g) of slow acting carbohydrate like 250ml plain milk , 1 slice of bread 1 piece of fruit 2 sweet plain biscuits. Teh tarik ranges from 19-23g of carbohydrate. 
                    `}
                </div>

                <div className="faq-ans">
                  Ensure he is well hydrated before the exercise as diabetics
                  are prone to dehydration. Post-workout, he also needs to
                  monitor his blood glucose if he has been doing prolonged
                  exercise and to supplement accordingly if needed. Episodes of
                  hypoglycemia should be treated with fast acting carbohydrate.
                  It may take a few tries to figure out the right balance. Type
                  1 diabetic patients should consider discussing with a
                  dietitian regarding carbohydrate supplementation.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Stopping resistance training
                </div>
                <div className="faq-question">
                  If we stopped resistance training for a certain period, part
                  of body involved tend to gain easily eg core exercise. How to
                  approach this problem?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  My apologies, but the question isn't very clear. Do you mean,
                  if we stop doing resistance training like core exercises, we
                  will gain weight? If that is the question, here is my
                  response:-
                </div>
                <div className="faq-ans">
                  Core exercises train the muscles in your pelvis, lower back,
                  hips and abdomen. The core muscles transfer force from the
                  lower limb to the upper limb and vice versa as well as protect
                  the spine from excessive load. However, core stability
                  training for healthy and athletic populations has recently
                  been questioned and challenged in scientific literature. There
                  is also a lack of standardized agreement on the anatomical
                  structure and function of the core. However, having a stable
                  core leads to better balance and stability and can prevent
                  injuries. Regarding resistance training in general, it helps
                  to improve your muscular fitness, which is the strength and
                  endurance of the muscles. It also helps to prevent the natural
                  loss of lean muscle mass that occurs due to aging. Resistance
                  training, combined with aerobic exercise and a good diet may
                  help to reduce body fat. It does not however specifically lead
                  to "spot reduction" of fat in the targeted area. If you were
                  to stop your resistance training, you will begin to lose your
                  muscle mass and strength over a period of weeks to months
                  depending on your muscle fitness level at the beginning. The
                  longer you go without training, the more you will lose.
                  Ideally resistance training should be done 2-3 times a week if
                  you are working out the same muscle group. But if there is a
                  lack of time, then suggest to at least 1-2 days a week. This
                  may not be enough to increase the strength but at least it may
                  help to maintain their strength gains from the earlier
                  training sessions. In general, the more frequent the sessions,
                  the greater the strength gains.
                </div>

                <div className="faq-ans">
                  NOTE : Dear Dr, if this was not your intended question, please
                  do send in your question again and I will be happy to answer
                  accordingly.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Advice for patients with osteoarthritis or physically disabled
                  diabetics
                </div>
                <div className="faq-question">
                  How do we advice patients with osteoarthritis or physically
                  disabled diabetics?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Patients with osteoarthritis, especially of the knees will
                  benefit from performing both aerobic and strengthening
                  exercise. The aerobic exercise will help to improve their
                  fitness levels while resistance exercise will improve their
                  strength and reduce pain. These exercises will also help in
                  management of their other co-morbidities. In addition, they
                  should also perform stretching exercises which will help to
                  maintain and improve their joint range of motion as well as
                  perform exercises to improve their balance. Suggested aerobic
                  exercise will be low impact exercise - e.g walking , cycling,
                  swimming, water aerobics. If excessive walking exacerbates the
                  pain, then they may need to switch to other non-weight bearing
                  exercises as mentioned. They can also do multi-component
                  activities that include more than 1 type of activity like
                  yoga, tai chi etc.
                </div>
                <div className="faq-ans">
                  Patients with disability, are a heterogeneous group. For many
                  types of physical disabilities, physical activity reduces
                  pain, improves fitness, physical function and quality of life.
                  Generally even if they are confined to the wheelchair or use
                  assistive devices to ambulate, they should still try to
                  maintain an active lifestyle. They can perform arm cycling or
                  chair exercises which can be performed in multiple bouts of
                  shorter duration instead of a single session. You may refer to
                  the following website for further information.
                  <a href="https://www.cdc.gov/vitalsigns/disabilities/images/infographic_970px.jpg">
                    https://www.cdc.gov/vitalsigns/disabilities/images/infographic_970px.jpg
                  </a>
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  How does cotton socks lead to foot ulceration during exercise?
                </div>
                <div className="faq-question">
                  How does cotton socks lead to foot ulceration during exercise?
                  Have always thought cotton is the preferred material of
                  choice. TQ
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  There is controversy regarding the most suitable material for
                  socks in diabetic patients. But according to [ Physical
                  activity/Exercise and Diabetes. A position statement of ADA.
                  2016] it is not advisable to use pure cotton socks.
                </div>
                <div className="faq-ans">
                  Cotton is generally soft, breathable and comfortable which
                  makes it a very popular choice for socks. However, it becomes
                  a problem in diabetic patients especially in those with
                  peripheral neuropathy IF it becomes wet such as during
                  exercise.
                </div>

                <div className="faq-ans">
                  Diabetic patients especially with peripheral neuropathy have
                  to take proper care of their foot which includes keeping it
                  dry to prevent maceration of the skin. Maceration can occur at
                  anytime skin is exposed to moisture for extended periods. Pure
                  cotton socks are not advised as cotton absorbs moisture and
                  can easily become saturated in sweat making them vulnerable to
                  infections and ulceration. Hence polyester or blended socks
                  would be preferable. These materials will be able to wick away
                  the moisture. Other materials such as acrylic fiber, spandex
                  and nylon can also be used.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  What is resistance training?
                </div>
                <div className="faq-question">
                  Is weight lifting a resistance exercise or resistance bands
                  will do?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Resistence training also known as strength training is an
                  integral component of an exercise program for the general
                  public but more so for the diabetic patient. Resistance
                  training confers the following benefits:
                  <br />
                  - Increase in muscle mass
                  <br />
                  - Increase in muscle strength
                  <br />
                  - Improve BMD
                  <br />
                  - Improve glycemic control
                  <br />- Improved insulin sensitivity
                </div>
                <div className="faq-ans">
                  Infact, with regards to glycemic control, the impact of
                  resistance exercise is comparable to endurance exercise.
                  Resistance exercise can be done through the following ways:
                  <br />
                  1) Use of free weights - dumbbell or medicine ball
                  <br />
                  2) Use of resistance bands
                  <br />
                  3) Use of exercise machines - e.g leg press, knee extension
                  etc
                  <br />
                  4) Use of body weight - e.g squats , push ups etc
                </div>

                <div className="faq-ans">
                  RECOMMENDATION
                  <br />
                  The recommendation is to engage in resistance training 2-3
                  session/week. The aim is to have a 48 hour gap when training
                  the same group of muscles. Work out 8-10 different muscle
                  groups. Aim to perform at least 2 sets of 10 repetitions each
                  with 2-3 minute rest interval between sets. Just as with
                  aerobic exercise, the component of intensity is also important
                  in resistance exercise. This most commonly refers either the
                  magnitude of resistance ( commonly expressed as a % of 1RM )
                  the number of repetitions that can be performed. The number of
                  repetitions at a given load will determine the effect of
                  training. There are some differences between training with
                  resistance bands and free weights.
                </div>

                <div className="faq-ans">
                  FREE WEIGHTS ( LIFTING WEIGHTS E.g dumbbell )
                  <br />
                  Free weights exercise depends on the load and the resistance
                  is created by gravity. So, you must position yourself
                  correctly to work the muscle. You will not be able to work the
                  muscle if you lift the weight without opposing gravity.
                </div>

                <div className="faq-ans">
                  RESISTANCE BANDS
                  <br />
                  The resistance is created by the elastic force. Force is
                  created in the direction you are stretching the band. Because
                  of this, resistance band can provide force in any direction
                  and at any angle.
                </div>

                <div className="faq-ans">
                  COMPARISON
                  <br />
                  Briefly, these are a few of the diffferences between training
                  with free weights versus resistance bands. Resistance bands
                  works on the principle of variable resistance. As the band
                  gets stretched, it creates more resistance. Free weights have
                  a fixed weight, but they have a strength curve which means the
                  resistance will be different at different angles of the joint
                  range of motion. Principle of progressive overload. To make
                  strength gains, the workout must be made progressively
                  difficult ( i.e more sets, more repetitions or heavier weights
                  ). With free weight, this is easier as you know how much you
                  are lifting and it is easy to train progressively i.e by
                  increasing the weight. With resistance band, the resistance
                  depends on the degree of stretch and it it is hard to know how
                  much of load you are working with. One way however, is to use
                  bands of increasing resistance ( different colours ) to
                  progress. Resistance bands are safer than free weights.
                  Research has shown that heavier resistance training with free
                  weights and weight machines may improve glycemic control and
                  strength more. If you want to increase strength, resistance
                  bands are good way to start. However, you willl need to switch
                  to free weights as resistance bands won't be able to challenge
                  you in the same way as free weights. However, doing resistance
                  training of any intensity is recommended to improve strength,
                  balance and ability to engage in activities of daily living as
                  we age. With whatever method you chose, it is important to
                  begin the training program slowly. Progressing too quickly may
                  lead to injuries.
                </div>

                <div className="divider my-3"></div>
              </div>
            </Panel>
            <Panel
              header="Module 6: Engaging The Disengaged: Encouraging Behavior Change in People With Diabetes (Part 2)"
              key="6"
              onClick={() => handleSubmit2('6')}
              style={panelStyle}
            >
              <div>
                <div className="sub-header-faq">
                  Demotivation for healthcare provider when using Motivational
                  Interviewing method
                </div>
                <div className="faq-question">
                  Hi Dr. Jamilah, when an open ended question is asked about
                  behavioral change (eg: What do you think you can do to cut
                  down the simple sugar intake?), the patient would only respond
                  with "I don't know", "I am not sure", "No idea", "If God wants
                  it to happen, it will happen." How would you encourage the
                  patient to come up with their own solution? Thanks.
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Yes. Some of my patients, especially those who still have
                  treatment resistance and have yet to establish a therapeutic
                  alliance, gave me the same answers. I agree, sometimes it
                  makes us feel demotivated or stuck and do not know how to
                  progress. However, sometimes, it was a genuine answer due to
                  their basic knowledge or limited readings. So, I would
                  normally use the below tips to move forward:
                </div>
                <div className="faq-ans">
                  1. To explore further what they have been learned or informed
                  previously? 2. To ask if it's OK if you suggest few options
                  and we can discuss it. End the session by asking your clients
                  to summarize what they have taken from the session and their
                  action plan.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  HCPs have very limited time to counsel patients
                </div>
                <div className="faq-question">
                  Most of the time, it is pressure from hospital administrator
                  to see more patients, seeing more patients means spending less
                  time counsel. So how to go about this?
                </div>
                <div className="py-3">
                  Answer:
                  <br />I agree with you that this could be the hurdle and
                  barrier for effective communications with our patients. If you
                  can voice your concerns via your bosses, it would be good for
                  the authority to learn that it is important to have quality
                  time with our patients. However, if the above suggestions may
                  not be suitable, I suggest you break the skills you learned
                  from our webinar into smaller chunks and apply the
                  micro-skills. For example, by asking a simple question such as
                  "What is your thought about diabetes?" or "Apa yang Encik
                  fikirkan setiap kali Encik perlu makan ubat?" or "Do you think
                  'this' behavior helps attain your diabetes goal?". Do not
                  forget to have pause and listen effectively to their
                  responses. I believe in accumulated small positive behaviors
                  that are consistent.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  What to do when patient gives up managing their diabetes?
                </div>
                <div className="faq-question">
                  Sometimes patient just give up with his diabetes control and
                  say "takpelah doktor, saya pun dah lama hidup.." how to
                  response with this patient..?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  Thank you for sharing. I would suggest you see the non-verbal
                  communications shared by the patient. Does the patient showed
                  some despair due to diabetes while saying that, or is the
                  patient is trying to tell you that they have had enough with
                  the session? If you think that is genuine despair, I would
                  suggest that you explore further what the patient is thinking
                  and feeling when they said that to you. Show them that you
                  care and want to understand more if diabetes has made them
                  feel hopeless and helpless. However, if you see that the
                  patient has some issue with the session, I suggest you ask
                  their opinion about the current treatment/sessions that they
                  received. You may know their thought about the session and
                  probably their belief system about the treatment, which blocks
                  their mindset from changing.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">Application of CBT & MI</div>
                <div className="faq-question">
                  Can we use CBT & MI to deal with diabetes burnt out?
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  The simple is YES, absolutely! CBT was previously created to
                  deal with psychiatric disorders, and MI was initially created
                  for addiction/substance abuse. However, much literature has
                  found that both CBT and MI can now be used to help manage
                  other chronic illnesses, such as Diabetes (i.e., diabetes
                  distress and burnout).
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  Who is suitable to use CBT?
                </div>
                <div className="faq-question">
                  Based on your experience, does CBT work better in someone with
                  higher levels of educations or the opposite? Sometimes, the
                  educated ones justify their behavior
                </div>
                <div className="py-3">
                  Answer:
                  <br />
                  This is a critical question. Based on the training and
                  literature I have reviewed, CBT works better with people with
                  higher educational/academic backgrounds. For our patients who
                  are educated and love to justify their actions, I suggest you
                  listen more to their justifications and learn to use
                  Motivational Interviewing to create ambivalence to their
                  arguments.
                </div>

                <div className="divider my-3"></div>
              </div>

              <div>
                <div className="sub-header-faq">
                  How to prolong patient's positive change?
                </div>
                <div className="faq-question">
                  How do we make the positive change permanent or becomes a
                  habit (maintenance)?
                </div>
                <div className="py-3">
                  Answer:
                  <br />I want to start by stating - Nothing is permanent. It is
                  very normal to have lapses or relapses in our behavior change.
                  So yes, I agree to use the word maintaining the positive
                  behavioral change/habit. Below are some tips for maintaining
                  positive behavioral change:
                </div>
                <div className="faq-ans">
                  1. Make a goal and plan that are likely to stick. In other
                  words, more sustainable.
                  <br />
                  2. Start making small changes.
                  <br />
                  3. Change one behavior at a time. If we want our patients to
                  focus on changing their diet, focus on that first, Then,
                  slowly introduce the physical activity.
                  <br />
                  4. Involve other people in our plan and positive behavior
                  change.
                  <br />
                  5. Ask for support. This could include us, the healthcare
                  service providers (HCP).
                </div>

                <div className="faq-ans">
                  I am sharing with you the link for relevant reading material
                  on the above points.
                  <a href="https://www.apa.org/topics/behavioral-health/healthy-lifestyle-changes">
                    https://www.apa.org/topics/behavioral-health/healthy-lifestyle-changes
                  </a>
                </div>

                <div className="divider my-3"></div>
              </div>
            </Panel>
            <Panel
              header="Module 8: Discovering Strategies to Empower Patients in Self-monitoring (Part 2)"
              key="8"
              onClick={() => handleSubmit2('8')}
              style={panelStyle}
            >
              <div>
                <div className="sub-header-faq">
                  Cost of Continous Glucose Monitoring (CGM)
                </div>
                <div className="faq-question">
                  CGM is a good way to monitor sugar control. What is the
                  estimated cost per month of CGM?
                </div>
                <div className="faq-ans">
                  Answer:
                  <br />
                  Yes CGM is a good way to monitor BG and guide better lifestlye
                  choices, improve adherence to medications and lifestyle and
                  improve glycaemic control. However it is costly - there are 2
                  options in Malaysia. The flash glucose monitor with a 14 day
                  sensor cost is about RM 280 per sensor. The other option is
                  the guardian connect 7 day sensor. CGM can be used
                  intermittently.
                </div>

                <div className="divider my-3"></div>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
}

export default Faq;
