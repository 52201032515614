import React, { useState, useEffect } from 'react';
import Header from '../components/stickyHeader';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import questionApi from '../services/questionActions';
import { useSelector, useDispatch } from 'react-redux';
import { getModuleData } from '../reducer/userReducer';

function Submit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [searchParams] = useSearchParams();
  const curModule = searchParams.get('module');

  useEffect(() => {
    dispatch(getModuleData({ module: curModule }));
  }, [dispatch, curModule]);

  const { user, module } = useSelector((state) => state.user);

  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');

  const success = () => {
    messageApi.open({
      type: 'success',
      content:
        'Your question has been posted. The question will be reflected after the experts has answered your questions',
      duration: 10,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('data', data);
    try {
      const body = {
        subject: subject,
        description: description,
        course_id: module?.id,
      };
      const response = await questionApi.submitPost(body);
      // console.log(response.data);
      if (response) {
        // console.log('response', response);
        message.success({
          content:
            'Your question has been posted. The question will be reflected after the experts have answered your questions',
          duration: 10,
        });

        setTimeout(() => {
          navigate(-1);
        }, 1000);
      }
    } catch (error) {
      // console.error(error.response?.data.error);
      if (error.response?.data.error) {
        message.error(error.response?.data.error);
      }
    }
  };

  // console.log('module', module);

  return (
    <div className="submit-container">
      <Header user={user} />

      <div className="ask-expert-padding">
        <div className="container">
          <div className=" submit-question">
            <h2 className="text-center font-weight-bold dark-blue-title">
              Submit your question
            </h2>
            <div className="w-100 submit-topic-bg px-4 py-4 mt-4">
              <form style={{ textAlign: 'left' }} onSubmit={handleSubmit}>
                <input type="hidden" />
                <label className="text-white font-weight-bold">Subject</label>
                <input
                  className="w-100 form-control white-bg"
                  name="subject"
                  required
                  onChange={(e) => setSubject(e.target.value)}
                />
                <label className="text-white font-weight-bold mt-2">
                  Description
                </label>
                <textarea
                  className="w-100 form-control white-bg"
                  rows="8"
                  name="description"
                  required
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>

                <div className="d-flex justify-content-between align-items-end">
                  <div
                    className="btn btn-radius btn-yellow-bg dark-blue-title font-weight-boldest"
                    onClick={() => navigate(-1)}
                  >
                    BACK
                  </div>

                  <button
                    style={{ marginTop: '10%' }}
                    type="submit"
                    className="d-flex ml-auto btn btn-radius btn-yellow-bg px-4 dark-blue-title font-weight-boldest mt-custom-submit"
                  >
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Submit;
