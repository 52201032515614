import React from 'react';
import { useNavigate } from 'react-router-dom';

const TopCrumb = ({ module, type }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        className="d-flex justify-content-end fw-bold"
        style={{ color: '#426b91' }}
      >
        <div className="cursor-pointer" onClick={() => navigate('/modules')}>
          Modules
        </div>
        <div className="mx-2"> &gt;</div>
        <div className="cursor-pointer">{module?.title}</div>
        {type ? (
          <>
            <div className="mx-2"> &gt;</div>
            <div className="cursor-pointer me-5"> {type} </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TopCrumb;
