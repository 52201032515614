import React, { useState } from 'react';
import { Modal } from 'antd';

import finalCert from '../images/certs/final-cert.png';
import downloadIcon from '../images/documentdownload.png';
import jsPDF from 'jspdf';
import DisclaimerConsent from './DisclaimerConsent';
import CompletionCongrat from './CompletionCongrat';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CertificatePopup = ({ isModalOpen, setIsModalOpen, completedModule }) => {
  const navigate = useNavigate();
  const [isModalOpenDisclaimer, setIsModalOpenDisclaimer] = useState(false);
  const [isModalOpenAdvocate, setIsModalOpenAdvocate] = useState(false);

  const { user } = useSelector((state) => state.user);
  // console.log('user', user);
  const generatePDF = (userName) => {
    // Create a new instance of jsPDF
    const doc = new jsPDF('landscape', 'px', 'a4');

    // Load the image onto the document
    const imageWidth = doc.internal.pageSize.getWidth(); // Desired width of the image in the PDF
    const imageHeight = doc.internal.pageSize.getHeight(); // Desired
    doc.addImage(finalCert, 'PNG', 0, 0, imageWidth, imageHeight); // Adjust the position and size as per your requirement
    doc.setFontSize(18);
    doc.setTextColor('#3b628d'); // Set the text color (here, it's red)
    doc.setFont('PT Serif');
    doc.text(userName, doc.internal.pageSize.getWidth() / 2, 215, {
      align: 'center',
    });
    // console.log('completedModule', completedModule);

    doc.setTextColor('#3b628d');
    doc.setFont('Poppins');
    doc.text(completedModule, doc.internal.pageSize.getWidth() / 2, 315, {
      align: 'center',
    });

    // Set the text color (here, it's red)
    // Save the PDF as a file
    doc.save(`${userName}-completion-certificate.pdf`);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDisclaimer = () => {
    setIsModalOpenDisclaimer(true);
  };

  const hideDisclaimerShowAdvocate = () => {
    setIsModalOpenDisclaimer(false);
    setIsModalOpenAdvocate(true);
  };

  const closeAllModals = () => {
    setIsModalOpen(false);
    setIsModalOpenDisclaimer(false);
    setIsModalOpenAdvocate(false);
  };

  const clickedConsent = localStorage.getItem('dlp-doctor-consent');

  // todo Can we allow the HCP that completed last year on 2021 & 2022 to get the module completion certificates, just don't need the final completion certificate.

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      footer={null}
    >
      <DisclaimerConsent
        isModalOpen={isModalOpenDisclaimer}
        setIsModalOpen={setIsModalOpenDisclaimer}
        hideDisclaimerShowAdvocate={hideDisclaimerShowAdvocate}
      />

      <CompletionCongrat
        isModalOpen={isModalOpenAdvocate}
        setIsModalOpen={setIsModalOpenAdvocate}
        closeAllModals={closeAllModals}
        user={user}
      />

      <div>
        <div className="congratulation-modal">Congratulations</div>
        <div className="congratulation-sub-modal">
          You have completed the Diabetes Lifestyle Advocates training course!
          You are part of DLP 2023 Batch 1 and here's your certificate:
        </div>
        <div className="text-center py-4 position-relative">
          <img
            alt="cert"
            style={{ objectFit: 'contain', width: '100%' }}
            src={finalCert}
          />
          <div
            style={{
              position: 'absolute',
              top: '46.5%',
              left: '50%',
              transform: 'translateX(-50%)',
              fontStyle: 'PT Serif',
            }}
            className="small-text-size"
          >
            {user?.name}
          </div>

          <div
            style={{
              position: 'absolute',
              top: '66%',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
            className="small-text-size"
          >
            {completedModule}
          </div>
        </div>
        <div className="congratulation-desc-modal">
          By completing this course, you are now an official Diabetes Lifestyle
          Advocate. You've expanded your knowledge and created new opportunities
          to start making difference on your diabetes patients.
        </div>
        <div
          className="congratulation-desc-modal"
          style={{ marginTop: '15px' }}
        >
          <div className="pb-2">
            Click <b>next </b> to get FREE access to the DLP web-based app.
          </div>
          Click{' '}
          <b style={{ cursor: 'pointer' }} onClick={() => navigate('/about')}>
            {' '}
            here
          </b>{' '}
          to know more about DLP.
        </div>
        <div className="d-flex d-flex justify-content-start justify-content-md-between mt-5">
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-sm"
              style={{
                borderRadius: '8px',
                padding: '5px 30px',
                backgroundColor: 'transparent',
                border: '1px solid #f0d747',
                color: '#3b638c',
              }}
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn btn-sm ms-3"
              style={{
                borderRadius: '8px',
                padding: '5px 30px',
                backgroundColor: '#f0d747',
                color: '#3b638c',
              }}
              onClick={() =>
                clickedConsent ? setIsModalOpenAdvocate(true) : showDisclaimer()
              }
            >
              Next
            </button>
          </div>
          <div
            className="d-flex fw-bold"
            style={{ color: '#3b638c', cursor: 'pointer' }}
            onClick={() => generatePDF(user?.name)}
          >
            <img
              style={{ height: '30px' }}
              src={downloadIcon}
              alt="downloadIcon"
            />
            <div style={{ lineHeight: '15px', fontSize: '13px' }}>
              <div> Download</div>
              <div>Certificate</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CertificatePopup;
