import React, { useEffect, useState } from 'react';
import { Input, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import fetch from 'node-fetch';

function ThreeMonthsSurveySection9() {
  const location = useLocation();
  const navigate = useNavigate();
  const [answerData, setAnswerData] = useState(location.state?.answerData);
  const [question, setQuestion] = useState(''); // eslint-disable-line no-unused-vars
  const [other, setOther] = useState(null);
  const [otherValue, setOtherValue] = useState('');
  const answerLength = location.state?.answerLength;
  const queryQuestions = location.state?.queryQuestions;

  const surveyToken = localStorage.getItem('survey_user_token');
  const user_id = surveyToken?.split('-')[1];
  if (!user_id) {
    message.error('Please start this survey from the email that you received.');
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          '/api/survey/surveys?section=9&type=post3mth',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const { data } = await response.json();

        if (data.length) {
          setQuestion(data[0]);
        } else {
          setQuestion(data);
        }
      } catch (error) {
        // console.error('Error submitting survey:', error.response.data);
        if (error.response?.data) {
          message.error('An error occurred. Please try again.');
        }
      }
    };

    fetchData();
  }, [navigate]);

  // console.log('queryQuestions', queryQuestions);

  const handleChange = (option) => {
    const specify = option.details.includes('No (');
    if (specify) {
      setOther(true);
      setOtherValue(option);
    } else {
      setOther(false);
      setOtherValue('');
    }
    const existingAns = answerData.answers.find(
      (each) => each.survey_question_id === option.survey_question_id
    );

    if (!existingAns) {
      let newAnswer = {
        survey_question_id: question.id,
        option_id: option.id,
      };

      setAnswerData({
        ...answerData,
        answers: [...answerData.answers, newAnswer],
      }); // Add the new answer to the answers array
    } else {
      let newAnswer = answerData.answers.map((each) => {
        if (each.survey_question_id === option.survey_question_id) {
          if (specify) {
            delete each.details;
          }
          each.option_id = option.id;
        }
        return {
          survey_question_id: each.survey_question_id,
          option_id: each.option_id,
        };
      });

      setAnswerData({
        ...answerData,
        answers: newAnswer,
      }); // Add the new answer to the answers array
    }
  };
  const updateOtherValue = (e) => {
    const updatedAnswers = answerData.answers.map((each) => {
      if (each.option_id === otherValue.id) {
        each.details = e;
        return each;
      } else {
        return each;
      }
    });

    setAnswerData({ ...answerData, answers: updatedAnswers }); // Update only the answers array
  };

  const submitForm = async () => {
    try {
      if (!user_id) {
        return message.error(
          'Please start this survey by clicking on the link in the email that you received.'
        );
      }

      const answer = {
        user_id: user_id,
        ...answerData,
        queryQuestions,
      };
      const response = await fetch('/api/survey/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(answer), // Send answerData as the request body
      });
      const data = await response.json();
      // console.log('data', data);

      if (data.message) {
        message.success('Survey submitted successfully');
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else if (data.error) {
        // console.log('data', data);
        // If the response is not successful, log the error message
        if (response.statusText) {
          message.error(data.error);
        }
      }
    } catch (error) {
      console.log('first error', error);
      message.error('An error occurred. Please try again.');
    }
  };
  // console.log('answerData', answerData);

  return (
    <div style={{ overflowX: 'auto' }}>
      <div className="margin-big-width primary-text-color">
        <div className="survey-title pt-5 pb-3">Take a Survey</div>
        <div>
          <div style={{ overflow: 'hidden' }} className="w-100 questions-bg">
            <div>
              <span>9.</span> {question?.description}
            </div>
            <div className="options" style={{ marginTop: '20px' }}>
              {question?.options
                ?.sort((a, b) => a.sequence - b.sequence)
                .map((option) => (
                  <div key={option.id} className="ms-3">
                    <label>
                      <div className="d-flex">
                        <input
                          className="align-self-start radio-btn"
                          type="radio"
                          name={'question'}
                          value={option.id}
                          onChange={() => handleChange(option)}
                        />
                        <div style={{ textAlign: 'start' }}>
                          {option.details}
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
            </div>

            {other ? (
              <div className="ms-4 w-50">
                <Input
                  onChange={(e) => {
                    updateOtherValue(e.target.value);
                  }}
                  type="text"
                ></Input>
              </div>
            ) : null}
          </div>

          <div className=" d-flex justify-content-center">
            <button
              className="btn btn-radius btn-yellow-bg px-5 font-weight-boldest dark-blue-title btn-next"
              onClick={() => {
                if (answerData.answers.length <= answerLength) {
                  message.error('Please select an option to continue');
                } else {
                  if (other) {
                    const existValue = answerData.answers
                      .filter((each) => each.survey_question_id === question.id)
                      .find((each) => each.details);

                    if (!existValue) {
                      return message.error('Please specify why!');
                    }
                  }
                  submitForm();
                }
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThreeMonthsSurveySection9;
