import React, { useEffect, useState } from 'react';
import Header from '../components/stickyHeader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import questionApi from '../services/questionActions';
import { message, Pagination } from 'antd';
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';
import { getModuleData } from '../reducer/userReducer';
function AskExpert() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const curModule = searchParams.get('module');

  const [posts, setPosts] = useState([]);
  const [postsWithPage, setPostsWithPage] = useState([]);

  useEffect(() => {
    dispatch(getModuleData({ module: curModule }));
  }, [dispatch, curModule]);

  const { user, module } = useSelector((state) => state.user);

  useEffect(() => {
    if (module) {
      const fetchPosts = async () => {
        try {
          const res = await questionApi.getAskTheExpertsPosts(module?.id);
          if (res) {
            setPostsWithPage(res.data.data.slice(0, 10));
            setPosts(res.data.data);
          }
        } catch (error) {
          if (error.response?.data.error) {
            message.error(error.response?.data.error);
          }
        }
      };
      fetchPosts();
    }
  }, [module]);

  const [current, setCurrent] = useState(1);
  const onChange = (page) => {
    // console.log(page);
    setCurrent(page);
    if (page === 1) {
      const currentPosts = posts.slice(0, 10);
      setPostsWithPage(currentPosts);
    } else {
      const pageNumbers = (page - 1) * 10;
      const currentPosts = posts.slice(pageNumbers, pageNumbers + 10);
      setPostsWithPage(currentPosts);
    }
  };

  // console.log('postsWithPage', postsWithPage);

  return (
    <div className="ask-container">
      <Header user={user} />
      <div className="ask-expert-padding">
        <div className="container">
          <div>
            <div
              className="question-btn"
              onClick={() =>
                navigate(`/ask-the-expert/submit?module=${curModule}`)
              }
            >
              + New Question
            </div>
            <div className="w-100 forum-topics-bg px-4 py-4 mt-3">
              {postsWithPage.map((post, index) => {
                const formattedDate = moment(post.created_at).format(
                  'MMM Do [at] HH:mm'
                );
                return (
                  <div
                    key={index}
                    className="row px-2 py-2"
                    style={{ cursor: 'pointer' }}
                  >
                    <div
                      className="forum-topic-bg cursor-pointer mb-3"
                      onClick={() =>
                        navigate(`/question?module=${curModule}`, {
                          state: post,
                        })
                      }
                    >
                      <div className="row p-2 py-3">
                        <div
                          className=" px-0 px-md-3"
                          style={{ textAlign: 'left' }}
                        >
                          <h4 className="my-1 theme-blue font-weight-bold">
                            {post.subject}
                          </h4>
                        </div>
                        <div
                          className=" px-0 px-md-3"
                          style={{ textAlign: 'left' }}
                        >
                          <p className="my-auto theme-blue">
                            {formattedDate} by {post.user.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="text-center">
                <Pagination
                  current={current}
                  onChange={onChange}
                  total={posts.length}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AskExpert;
