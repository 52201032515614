import React from 'react';
import { Modal } from 'antd';
import warning from '../images/warning-icon.png';
import success from '../images/green-tick.png';
import { useNavigate } from 'react-router-dom';

const SuccessRegister = ({ setIsModalOpen, isModalOpen, statusChecker }) => {
  const navigate = useNavigate();
  const handleOk = () => {
    setIsModalOpen(false);
    navigate('/login');
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
    >
      <div className="text-center">
        <img
          alt="cert"
          style={{ objectFit: 'contain', width: '100%', height: '60px' }}
          src={statusChecker === 'success' ? success : warning}
        />

        <div style={{ fontWeight: '700', fontSize: '20px' }} className="mt-4">
          {statusChecker === 'success' ? 'Success!' : 'Failed!'}
        </div>

        <div className="mt-3">
          {statusChecker === 'success' ? (
            <>
              You have been successfully registered.
              <br />
              You may login with the credential that
              <br />
              is sent to your email.
            </>
          ) : (
            <>
              You have registered before.
              <br />
              Please login to your account.
            </>
          )}
        </div>

        <div className="text-center">
          <button
            className="custom-button mt-5"
            style={{
              background: '#1B2E48',
              padding: '5px 20%',
              color: '#fff',
            }}
            onClick={() => handleOk()}
          >
            Ok
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessRegister;
