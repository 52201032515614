import React from 'react';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

const DisclaimerConsent = ({
  setIsModalOpen,
  isModalOpen,
  hideDisclaimerShowAdvocate,
}) => {
  const navigate = useNavigate();
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('data', data);
    localStorage.setItem('dlp-doctor-consent', 1);

    hideDisclaimerShowAdvocate();
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
    >
      <div>
        <div className="text-center">
          <div style={{ fontWeight: '700', fontSize: '20px' }}>
            “Disclaimer & Consent”
          </div>
          <div className="mt-3">
            By using the web based app.
            <br />
            You agree for the practices described in
            <br />
            this "{' '}
            <span
              onClick={() => navigate('/privacy')}
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: 'blue',
              }}
            >
              Privacy Notice
            </span>{' '}
            " and agree to <br />"
            <span
              onClick={() => navigate('/privacy')}
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: 'blue',
              }}
            >
              Abbott's Privacy Policy
            </span>
            "
          </div>
          <form onSubmit={handleSubmit}>
            <div className="checkbox-container " style={{ marginTop: '15px' }}>
              <label className="d-flex">
                <input
                  className="align-self-start"
                  type="checkbox"
                  name="checkbox"
                  required
                />
                <div className="ms-3">
                  I have read and understood the terms of this "
                  <span
                    onClick={() => navigate('/privacy')}
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: 'blue',
                    }}
                  >
                    Privacy Notice
                  </span>
                  " and "
                  <span
                    onClick={() => navigate('/privacy')}
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: 'blue',
                    }}
                  >
                    Abbott's Privacy Policy
                  </span>
                  " and consent to the processing of my Personal Data as
                  described.
                </div>
              </label>
              <label className="d-flex mt-3">
                <input
                  className="align-self-start"
                  type="checkbox"
                  name="checkbox2"
                  required
                />{' '}
                <div className="ms-3">
                  I would also like to receive communication from Abbott by
                  phone / email / SMS / WhatsApp regarding Abbott products and
                  services, or for research purposes and DLP activities.
                </div>
              </label>
            </div>

            <div className="text-center">
              <button
                className="custom-button my-3"
                style={{
                  background: '#F0D474',
                  padding: '7px 5rem',
                  color: '#3B638C',
                }}
                type="submit"
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default DisclaimerConsent;
