import React from 'react';
import allLogo from '../assets/logos2.png';
import dlpLogo from '../assets/dlp_logo.png';
function SurveyHeader() {
  return (
    <div className="top-nav-survey">
      <div className="d-flex align-items-center py-4">
        <div className="mx-3">
          <div>
            <img style={{ width: '8vw' }} src={dlpLogo} alt="DLP Logo" />
          </div>
        </div>

        <div className="show-logo-in-mobile">
          <img
            style={{ width: '62vw', objectFit: 'contain' }}
            src={allLogo}
            alt="All Logo"
          />
        </div>

        <div className="hide-logo-in-mobile">
          <img
            style={{ width: '40vw', objectFit: 'contain' }}
            src={allLogo}
            alt="All Logo"
          />
        </div>
      </div>
    </div>
  );
}

export default SurveyHeader;
