import React from 'react';
import Header from '../components/stickyHeader';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';
import m7Ans1 from '../../src/images/m7-pic-1.png';
import m7Ans2 from '../../src/images/m7-pic-2.png';

function Question() {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const post = location.state;

  // console.log('post', post);

  return (
    <div className="ask-container">
      <Header user={user} />
      <div className="ask-expert-padding">
        <div className="container">
          <div className=" forum-topics-bg  mt-3" style={{ textAlign: 'left' }}>
            <div className="px-4 py-4">
              <h2 className="my-1 theme-yellow font-weight-bold">
                {post?.subject}
              </h2>

              <div className="my-auto theme-yellow">
                {moment(post.created_at).format('MMM Do [at] HH:mm')} by{' '}
                {post?.user.name}
              </div>

              <p className="my-auto theme-yellow">{post?.description}</p>
            </div>
          </div>
          <div className="forum-topic-bg mt-3 px-4 py-4">
            <h4 className="theme-blue font-weight-bold">
              {parse(post?.post_comments?.comment)}
            </h4>

            {post.id === '190' ? (
              <div className="py-3 text-center">
                <img
                  src={m7Ans1}
                  style={{ objectFit: 'contain', width: '90%' }}
                  alt="m7Ans1"
                  className="py-4"
                />
                <img
                  style={{ objectFit: 'contain', width: '90%' }}
                  src={m7Ans2}
                  alt="m7Ans1"
                />
              </div>
            ) : null}

            <p className="my-auto theme-blue">
              {moment(post?.post_comments?.created_at).format(
                'MMM Do [at] HH:mm'
              )}{' '}
              by {post?.commenter}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Question;
