import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import fetch from 'node-fetch';

function ThreeMonthsSurveySection3() {
  const location = useLocation();
  const navigate = useNavigate();
  const [answerData, setAnswerData] = useState(location.state?.answerData);
  const [question, setQuestion] = useState(''); // eslint-disable-line no-unused-vars
  const [surveySubmit, setSurveySubmit] = useState(false);

  const surveyToken = localStorage.getItem('survey_user_token');
  const user_id = surveyToken?.split('-')[1];

  if (!user_id) {
    message.error(
      'Please start this survey by clicking on the link in the email that you received.'
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          '/api/survey/surveys?section=3&type=post3mth',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const { data } = await response.json();
        if (data.length) {
          setQuestion(data[0]);
        } else {
          setQuestion(data);
        }
      } catch (error) {
        // console.error('Error submitting survey:', error.response.data);
        if (error.response.data) {
          message.error('An error occurred. Please try again.');
        }
      }
    };

    fetchData();
  }, [navigate]);

  const handleChange = (option) => {
    setSurveySubmit(false);
    if (option.details.includes('I do not see any')) {
      setSurveySubmit(true);
    }

    const existingIndex = answerData.answers.findIndex(
      (answer) => answer.survey_question_id === question.id
    );

    if (existingIndex !== -1) {
      // If an existing answer is found, update its option_id
      const updatedAnswers = [...answerData.answers];
      updatedAnswers[existingIndex] = {
        ...updatedAnswers[existingIndex],
        option_id: option.id,
      };
      setAnswerData({ ...answerData, answers: updatedAnswers }); // Update only the answers array
    } else {
      // If no existing answer is found, add a new answer to the array
      const newAnswer = {
        survey_question_id: question.id,
        option_id: option.id,
      };
      setAnswerData({
        ...answerData,
        answers: [...answerData.answers, newAnswer],
      }); // Add the new answer to the answers array
    }
  };

  const submitForm = async () => {
    try {
      if (!user_id) {
        return message.error(
          'Please start this survey by clicking on the link in the email that you received.'
        );
      }

      const answer = {
        user_id: user_id,
        ...answerData,
        surveyThreePartialSubmit: true,
      };
      const response = await fetch('/api/survey/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(answer), // Send answerData as the request body
      });
      const data = await response.json();
      // console.log('data', data);

      if (data.message) {
        message.success('Survey submitted successfully');
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else if (data.error) {
        // console.log('data', data);
        // If the response is not successful, log the error message
        if (response.statusText) {
          message.error(data.error);
        }
      }
    } catch (error) {
      console.log('first error', error);
      message.error('An error occurred. Please try again.');
    }
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <div className="margin-big-width primary-text-color">
        <div className="survey-title pt-5 pb-3">
          DLP 3-month Post-training Survey
        </div>
        <div>
          <div style={{ overflow: 'hidden' }} className="w-100 questions-bg">
            <div>
              <span> 3. </span> {question?.description}
            </div>
            <div className="options" style={{ marginTop: '20px' }}>
              {question?.options
                ?.sort((a, b) => a.sequence - b.sequence)
                .map((option) => (
                  <div key={option.id} className="ms-3">
                    <label>
                      <div className="d-flex">
                        <input
                          className="align-self-start radio-btn"
                          type="radio"
                          name="question"
                          value={option.id}
                          onChange={() => handleChange(option)}
                        />
                        <div style={{ textAlign: 'start' }}>
                          {option.details}
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
            </div>
          </div>

          <div className=" d-flex justify-content-center">
            {surveySubmit ? (
              <button
                className="btn btn-radius btn-yellow-bg px-5 font-weight-boldest dark-blue-title btn-next"
                onClick={() => {
                  if (answerData.answers.length < 7) {
                    message.error('Please select an option to continue');
                  } else {
                    submitForm();
                  }
                }}
              >
                Submit
              </button>
            ) : (
              <button
                className="btn btn-radius btn-yellow-bg px-5 font-weight-boldest dark-blue-title btn-next"
                onClick={() => {
                  if (answerData.answers.length < 7) {
                    message.error('Please select an option to continue');
                  } else {
                    navigate('/survey/post3months/four', {
                      state: { data: location.state?.data, answerData },
                    });
                  }
                }}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThreeMonthsSurveySection3;
