import React, { useEffect, useState } from 'react';
import { Input, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import fetch from 'node-fetch';
import { useSelector } from 'react-redux';
import axios from 'axios';

function SurveyPostSection3() {
  const navigate = useNavigate();
  const location = useLocation();
  const [answerData, setAnswerData] = useState(location.state);
  const [question, setQuestion] = useState([]);
  const [questionData, setQuestionData] = useState(''); // eslint-disable-line no-unused-vars

  const userData = useSelector((state) => state.user);
  const [iAmLess, setIamLess] = useState('');
  const [iAmUnsure, setIamUnsure] = useState('');
  // console.log('tokenConfig().config', tokenConfig());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          '/api/survey/surveys?section=3&type=post',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const { data } = await response.json();

        if (data.length) {
          setQuestion(
            data[0]?.options?.sort((a, b) => a.sequence - b.sequence)
          );
          setQuestionData(data[0]);
        } else {
          setQuestion('');
        }
      } catch (error) {
        // console.error('Error submitting survey:', error.response.data);
        if (error.response?.data) {
          message.error('An error occurred. Please try again.');
        }
      }
    };

    fetchData();
  }, [navigate]);

  // console.log('user', user);
  // console.log('iAmUnsure', iAmUnsure);

  const submitPreSurvey = async () => {
    try {
      if (iAmLess) {
        const gotDetails = answerData.answers.find(
          (each) => each.option_id === iAmLess.id && each.details
        );
        if (!gotDetails) {
          return message.error(
            'Please specify why you are less likely to incorporate diabetes'
          );
        }
      }

      if (iAmUnsure) {
        // console.log('answerData.answers', answerData.answers);
        const gotDetails = answerData.answers.find(
          (each) => each.option_id === iAmUnsure.id && each.details
        );

        if (!gotDetails) {
          return message.error(
            'Please specify why you are unsure if you will be incorporating'
          );
        }
      }

      if (!userData.user) {
        return message.error('Please login to submit survey');
      }

      const answer = {
        user_id: userData.user?.id,
        ...answerData,
      };
      if (!userData.user?.id) {
        return message.error('Please login to submit survey');
      }
      const response = await axios.post('/api/survey/submit', answer);
      // console.log('response', response);
      // Check if the response is successful (status code 200)
      if (response.data) {
        message.success('Survey submitted successfully!');
        // Parse the JSON response body
        setTimeout(() => {
          navigate('/modules');
        }, 2000);
      }
    } catch (error) {
      // Handle any errors that occur during the fetch request
      console.log('error.response.data', error.response.data);
      if (error.response.data) {
        message.error(
          error.response.data.error || 'An error occurred. Please try again.'
        );
      }
    }
  };

  const handleChange = (option) => {
    // console.log(option);

    if (option?.details.includes('I am less likely to incorporate diabetes')) {
      setIamUnsure('');
      setIamLess(option);
    } else if (
      option?.details.includes('I am unsure if I will be incorporating')
    ) {
      setIamLess('');
      setIamUnsure(option);
    } else {
      setIamLess('');
      setIamUnsure('');
    }

    const existingAns = answerData.answers.find(
      (each) => each.survey_question_id === questionData.id
    );

    if (!existingAns) {
      let newAnswer = {
        survey_question_id: questionData.id,
        option_id: option.id,
      };

      setAnswerData({
        ...answerData,
        answers: [...answerData.answers, newAnswer],
      }); // Add the new answer to the answers array
    } else {
      const updatedAnswers = answerData.answers.map((each) => {
        if (each.survey_question_id === questionData.id) {
          each.option_id = option.id;
          return each;
        } else {
          return each;
        }
      });

      setAnswerData({ ...answerData, answers: updatedAnswers }); // Update only the answers array
    }
  };

  const updateOtherValue = (e, otherValue) => {
    const updatedAnswers = answerData.answers.map((each) => {
      if (each.option_id === otherValue.id) {
        each.details = e;
        return each;
      } else {
        return each;
      }
    });

    setAnswerData({ ...answerData, answers: updatedAnswers }); // Update only the answers array
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <div className="margin-big-width primary-text-color mt-5">
        <div>
          <div style={{ overflow: 'hidden' }} className="w-100 questions-bg">
            <div>
              <span>3.</span> {questionData?.description}
            </div>
            <div className="options" style={{ marginTop: '20px' }}>
              {question && question[0] ? (
                <>
                  <div key={question[0].id} className="ms-3">
                    <label>
                      <div className="d-flex">
                        <input
                          className="align-self-start radio-btn"
                          type="radio"
                          name="question"
                          value={question[0].id}
                          onChange={() => handleChange(question[0])}
                        />
                        <div style={{ textAlign: 'start' }}>
                          {question[0].details}
                        </div>
                      </div>
                    </label>
                  </div>
                  <div key={question[1].id} className="ms-3">
                    <label>
                      <div className="d-flex">
                        <input
                          className="align-self-start radio-btn"
                          type="radio"
                          name="question"
                          value={question[1].id}
                          onChange={() => handleChange(question[1])}
                        />
                        <div style={{ textAlign: 'start' }}>
                          {question[1].details}
                        </div>
                      </div>
                    </label>
                  </div>

                  {iAmLess ? (
                    <div className="ms-4 mb-2">
                      <Input
                        placeholder="(please specify why)"
                        type="text"
                        onChange={(e) =>
                          updateOtherValue(e.target.value, iAmLess)
                        }
                      ></Input>
                    </div>
                  ) : null}

                  <div key={question[2].id} className="ms-3">
                    <label>
                      <div className="d-flex">
                        <input
                          className="align-self-start radio-btn"
                          type="radio"
                          name="question"
                          value={question[2].id}
                          onChange={() => handleChange(question[2])}
                        />
                        <div style={{ textAlign: 'start' }}>
                          {question[2].details}
                        </div>
                      </div>
                    </label>
                  </div>

                  {iAmUnsure ? (
                    <div className="ms-4 mb-2">
                      <Input
                        placeholder="(please specify why)"
                        type="text"
                        onChange={(e) =>
                          updateOtherValue(e.target.value, iAmUnsure)
                        }
                      ></Input>
                    </div>
                  ) : null}

                  <div key={question[3].id} className="ms-3">
                    <label>
                      <div className="d-flex">
                        <input
                          className="align-self-start radio-btn"
                          type="radio"
                          name="question"
                          value={question[3].id}
                          onChange={() => handleChange(question[3])}
                        />
                        <div style={{ textAlign: 'start' }}>
                          {question[3].details}
                        </div>
                      </div>
                    </label>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <div className=" d-flex justify-content-center">
            <button
              onClick={submitPreSurvey}
              className="btn btn-radius btn-yellow-bg px-5 font-weight-boldest dark-blue-title btn-next"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SurveyPostSection3;
