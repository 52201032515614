import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header';
import MainLogo from '../assets/dlp_logo.png';
import axios from 'axios';
import { message } from 'antd';

function Recover() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('data', data);
    try {
      const response = await axios.post('/api/auth/password/forgot', { email });
      // console.log(response.data);
      if (response) {
        message.success('Password has been sent.');
      }
    } catch (error) {
      console.error(error.response?.data.error);
      if (error.response?.data.error) {
        message.error(error.response?.data.error);
      }
    }
  };

  return (
    <div className="main-container">
      <Header />

      <div className="text-center">
        <img className="mainLogo" alt="logo" src={MainLogo} />
        <h1
          style={{
            textAlign: 'center',
            marginTop: '50px',
            color: 'white',
            marginBottom: '30px',
          }}
        >
          FORGOT PASSWORD
        </h1>

        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="text"
              placeholder="Email"
              className="form-input py-2"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="custom-btn">
            Submit
          </button>
        </form>

        <h6 className="forgot-password" onClick={() => navigate('/login')}>
          Back to login
        </h6>
      </div>
    </div>
  );
}

export default Recover;
