import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import SurveyRating1to5 from '../../../components/SurveyRating1to5';

function SurveyPreSection5() {
  const navigate = useNavigate();
  const location = useLocation();
  const [answerData, setAnswerData] = useState(location.state?.answerData);
  const answerLength = location.state?.answerLength;
  const [questions, setQuestions] = useState(''); // eslint-disable-line no-unused-vars

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/survey/surveys?section=5&type=pre', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const { data } = await response.json();
        if (data.length) {
          setQuestions(data);
        } else {
          setQuestions(data);
        }
      } catch (error) {
        // console.error('Error submitting survey:', error.response.data);
        if (error.response.data) {
          message.error('An error occurred. Please try again.');
        }
      }
    };

    fetchData();
  }, [navigate]);

  // console.log('answerData', answerData);
  // console.log('answerLength', answerLength);
  return (
    <div style={{ overflowX: 'auto' }}>
      <div className="margin-big-width mb-5">
        <div className="survey-title pt-5 pb-3">Take a Survey</div>
        <div>
          <div style={{ overflow: 'hidden' }} className="w-100 questions-bg">
            <div className="mx-5">
              <span>5.</span> How do you feel about the following statements on
              advocating healthy lifestyle in your clinical practice with
              patients?
            </div>
            <div className="options" style={{ marginTop: '20px' }}>
              {questions?.length
                ? questions
                    .sort((a, b) => a.sequence - b.sequence)
                    .map((question, idx) => (
                      <div key={idx}>
                        <SurveyRating1to5
                          setAnswerData={setAnswerData}
                          answerData={answerData}
                          index={question.id}
                          description={question.description}
                          options={question.options}
                          leftDescription={'Strongly Agree'}
                          rightDescription={'Strongly Disagree'}
                          leftWidth={'105px'}
                          textAlign={'end'}
                        />
                      </div>
                    ))
                : null}
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <button
              className="btn btn-radius btn-yellow-bg px-5 font-weight-boldest dark-blue-title btn-next"
              onClick={() => {
                if (answerData.answers.length <= answerLength) {
                  message.error('Please select an option to continue');
                } else if (
                  answerData.answers.length <
                  Number(answerLength) + 4
                ) {
                  message.error('Please select all options to continue');
                } else {
                  navigate('/survey/pre/six', {
                    state: {
                      data: location.state?.data,
                      answerData,
                      answerLength: answerData.answers.length,
                    },
                  });
                }
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SurveyPreSection5;
