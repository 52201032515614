import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import fetch from 'node-fetch';
import SurveyRating1to5 from '../../../components/SurveyRating1to5';

function ThreeMonthsSurveySection5() {
  const location = useLocation();
  const navigate = useNavigate();
  const [answerData, setAnswerData] = useState(location.state?.answerData);
  const [questions, setQuestions] = useState(''); // eslint-disable-line no-unused-vars

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          '/api/survey/surveys?section=5&type=post3mth',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const { data } = await response.json();

        if (data.length) {
          setQuestions(data);
        } else {
          setQuestions(data);
        }
      } catch (error) {
        // console.error('Error submitting survey:', error.response.data);
        if (error.response.data) {
          message.error('An error occurred. Please try again.');
        }
      }
    };

    fetchData();
  }, [navigate]);

  // console.log('questions', question);

  return (
    <div style={{ overflowX: 'auto' }}>
      <div className="margin-big-width mb-5">
        <div className="survey-title pt-5 pb-3">
          DLP 3-month Post-training Survey
        </div>
        <div>
          <div style={{ overflow: 'hidden' }} className="w-100 questions-bg">
            <div>
              <span>5. </span>How often do you provide education to your
              patients with diabetes on the following?
            </div>
            <div className="options" style={{ marginTop: '20px' }}>
              {questions?.length &&
                questions
                  .sort((a, b) => a.sequence - b.sequence)
                  .map((question, idx) => (
                    <div key={idx}>
                      <SurveyRating1to5
                        setAnswerData={setAnswerData}
                        answerData={answerData}
                        index={question.id}
                        description={question.description}
                        options={question.options}
                        leftDescription={'Never'}
                        rightDescription={'Always'}
                      />
                    </div>
                  ))}
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <button
              className="btn btn-radius btn-yellow-bg px-5 font-weight-boldest dark-blue-title btn-next"
              onClick={() => {
                if (answerData.answers.length < 12) {
                  message.error('Please select an option to continue');
                } else if (answerData.answers.length < 22) {
                  message.error('Please select all options to continue');
                } else {
                  navigate('/survey/post3months/six', {
                    state: { data: location.state?.data, answerData },
                  });
                }
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThreeMonthsSurveySection5;
