import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { checkUserSession } from '../reducer/userReducer';

const StateManager = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('dlp-hcp-doctor-token');
    if (token) {
      dispatch(checkUserSession());
    }
  }, [dispatch]);

  const { error } = useSelector((state) => state.user);

  useEffect(() => {
    if (error) {
      // console.log('error', error);
      if (
        error === 'Token expired' ||
        error === 'Token expired, please login again' ||
        error === 'Unauthorized Request'
      ) {
        navigate('/login');
      }
    }
  }, [error, navigate]);

  return <>{children}</>;
};

export default StateManager;
