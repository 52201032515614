import axios from 'axios';
import { tokenConfig, returnError } from '../services/helperFunc';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

const SET_LOADING = 'SET_LOADING';
const CLEAR_ERROR = 'CLEAR_ERROR';

const FAIL_DATA = 'FAIL_DATA';
const CHECK_SESSION_SUCCESS = 'CHECK_SESSION_SUCCESS';
const GET_MODULE_DATA = 'GET_MODULE_DATA';
const userUrlPath = '/api/auth';

export const checkUserSession = (currentPath) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: SET_LOADING });

    const { data } = await axios.post(
      `${userUrlPath}/session`,
      { path: currentPath },
      tokenConfig()
    );

    dispatch({ type: CHECK_SESSION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const getModuleData = (queryData) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `/api/question/get/module`,
      queryData,
      tokenConfig()
    );
    dispatch({ type: GET_MODULE_DATA, payload: data });
  } catch (error) {
    dispatch({ type: FAIL_DATA, payload: returnError(error) });
  }
};

export const clearError = (values) => (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};

const initialState = {
  user: null,
  error: null,
  loading: false,
  doctorToken: localStorage.getItem('dlp-hcp-doctor-token') || null,
  module: '',
};

export const userReducer = function (state = initialState, action) {
  switch (action.type) {
    // this is for fetching loading time setter
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        message: null,
        error: null,
        loading: false,
      };

    case CHECK_SESSION_SUCCESS:
      return {
        ...state,
        user: action.payload.data,
        loading: false,
        error: null,
      };

    case GET_MODULE_DATA:
      return {
        ...state,
        module: action.payload.data,
        loading: false,
        error: null,
      };
    case LOGOUT_SUCCESS:
      return initialState;

    case FAIL_DATA:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
