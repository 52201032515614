import React from 'react';
import Header from './header';

const PrivacyNotice = () => {
  return (
    <div
      className="text-white"
      style={{ backgroundColor: '#3b628d', minHeight: '100vh' }}
    >
      <Header />
      <div className="h3 py-5 text-center">
        PRIVACY NOTICE IN MALAYSIA – PRIVACY DATA PROTECTION ACT 2010
      </div>

      <div className="text-start container">
        <p>
          In accordance with the Personal Data Protection Act 2010 ,your
          personal data as provided in this form and any other personal data
          obtained thereafter by Abbott Laboratories (M) Sdn Bhd (“Abbott”)
          which may include information deemed to be sensitive personal data
          under applicable laws (collectively, “Personal Data”) may be processed
          for purposes of providing you with Abbott's product samples, to
          provide you with information and news relating to health and
          nutrition, to send you communications regarding Abbott's products,
          services, marketing, promotional or educational events, to receive any
          feedback or queries that you may have on Abbott's products and to
          improve our products and services (“Purpose”). Abbott may disclose and
          transfer the Personal Data to third parties including its affiliates /
          service providers, who may be located within or outside Malaysia.
        </p>

        <p>
          You may request for access to or correction of the Personal Data,
          submit inquiries or complaints, or limit the processing of the
          Personal Data at any time hereafter by submitting such request via
          email or contacting your Abbott sales representative .
        </p>

        <p>
          Please note that it is required for you to supply the Personal Data
          for the Purpose, without which Abbott will not be able to provide you
          with the full range of the services described. By providing us with
          your Personal Data and signature, you expressly consent and agree to
          the terms above with respect to the processing of your Personal Data.
        </p>
      </div>
    </div>
  );
};

export default PrivacyNotice;
