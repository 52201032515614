import React from 'react';
import { Modal } from 'antd';
import surveyIcon from '../images/survey-icon.png';

const TakeSurveyModal = ({
  isModalSurveyOpen,
  navigate,
  description,
  action,
}) => {
  return (
    <Modal centered open={isModalSurveyOpen} footer={null} closable={false}>
      <div className="text-center">
        <img
          alt="cert"
          style={{ objectFit: 'contain', width: '60%', height: '60px' }}
          src={surveyIcon}
        />

        <div style={{ fontWeight: '700', fontSize: '20px' }} className="mt-4">
          Take a survey
        </div>

        {description}
        <div className="mt-3">
          <button
            className="btn btn-primary btn-sm"
            style={{
              borderRadius: '15px',
              padding: '10px 80px',
              backgroundColor: '#1B2E48',
              color: '#fff',
            }}
            onClick={() => {
              action();
            }}
          >
            <b>Proceed</b>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TakeSurveyModal;
