import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import questionApi from '../services/questionActions';
import { message } from 'antd';
import TopCrumb from '../components/TopCrumb';

import pdf0 from '../pdf/resources/Blank Cognitive-Behavioral Framework.pdf';
import pdf1 from '../pdf/resources/DLP Module 1 Resources 20220920.pdf';
import pdf2 from '../pdf/resources/DLP Module 2 Resources 20220920.pdf';
import pdf3 from '../pdf/resources/DLP Module 3 & 4 Resources 20220920.pdf';
import pdf4 from '../pdf/resources/DLP Module 5 & 6 Resources 20220920.pdf';
import pdf5 from '../pdf/resources/DLP Module 7 & 8 Resources 20220920.pdf';
import pdf6 from '../pdf/resources/Sample Questions for Cognitive-Behavioral Framework.pdf';
import pdfIcon from '../images/login/pdf.png';
import { useSelector } from 'react-redux';

const pdfFiles = {
  'pdf/resources/Blank Cognitive-Behavioral Framework.pdf': pdf0,
  'pdf/resources/DLP Module 1 Resources 20220920.pdf': pdf1,
  'pdf/resources/DLP Module 2 Resources 20220920.pdf': pdf2,
  'pdf/resources/DLP Module 3 & 4 Resources 20220920.pdf': pdf3,
  'pdf/resources/DLP Module 5 & 6 Resources 20220920.pdf': pdf4,
  'pdf/resources/DLP Module 7 & 8 Resources 20220920.pdf': pdf5,
  'pdf/resources/Sample Questions for Cognitive-Behavioral Framework.pdf': pdf6,
};

function Resources() {
  const [resources, setResources] = useState([]);

  const { module } = useSelector((state) => state.user);

  useEffect(() => {
    if (module) {
      const fetchResources = async () => {
        try {
          const res = await questionApi.getResources(module.id);
          if (res) {
            setResources(res.data.data);
          }
        } catch (error) {
          if (error.response?.data.error) {
            message.error(error.response?.data.error);
          }
        }
      };
      fetchResources();
    }
  }, [module]);

  // console.log('resources', resources);

  const submitMaterialAction = (material_id) => {
    let data = {
      course_id: module.id,
      material_id: material_id,
    };

    const materialStatusUpdate = async () => {
      try {
        await questionApi.updateMaterialStatus(data);
      } catch (error) {
        // console.log('error', error);
        if (error.response?.data.error) {
          message.error(error.response?.data.error);
        }
      }
    };
    materialStatusUpdate();
  };

  return (
    <div className="container">
      <div className="py-4">
        <TopCrumb className="py-4" module={module} type="Resources" />
      </div>

      <div
        style={{ overflow: 'hidden' }}
        id="material-content"
        className="w-100 materials-bg px-3"
      >
        {resources.map((resource, idx) => {
          return (
            <a
              onClick={() => submitMaterialAction(resource.id)}
              key={idx}
              href={pdfFiles[resource.file]}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', color: '#3b628d' }}
              className="resource-link"
            >
              <div className="my-3 material-content-bg cursor-pointer">
                <div className="d-flex align-items-center p-2">
                  <img style={{ width: '50px' }} src={pdfIcon} alt="pdf" />

                  <h5 className="ms-4">{resource.title} </h5>
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default Resources;
