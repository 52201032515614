import React from 'react';
import allLogo from '../assets/logos2.png';
import dlpLogo from '../assets/dlp_logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import userApi from '../services/userActions';
import { message } from 'antd';

const StickyHeader = () => {
  const navigate = useNavigate();

  const logout = async () => {
    try {
      const logoutUser = await userApi.logout();
      if (logoutUser) {
        localStorage.removeItem('dlp-hcp-doctor-token');
        navigate('/login');
      }
    } catch (error) {
      // console.log('error', error.response?.data.error);
      if (error.response?.data.error) {
        message.error(error.response?.data.error);
      }
    }
  };

  return (
    <div className="stick-top">
      <div className="py-2 header-bg d-flex flex-column flex-md-row  justify-content-around align-items-center">
        <div className="d-flex align-items-center py-1">
          <div className="mx-3">
            <div onClick={() => navigate('/modules')}>
              <img style={{ width: '125px' }} src={dlpLogo} alt="DLP Logo" />
            </div>
          </div>

          <div className="show-logo-in-mobile">
            <img
              style={{ width: '57vw', objectFit: 'contain' }}
              src={allLogo}
              alt="All Logo"
            />
          </div>

          <div className="hide-logo-in-mobile">
            <img
              style={{ width: '35vw', objectFit: 'contain' }}
              src={allLogo}
              alt="All Logo"
            />
          </div>
        </div>

        <div className="d-flex  align-items-center">
          <div
            onClick={() => navigate('/modules')}
            style={{ color: '#3b638c', cursor: 'pointer' }}
          >
            <b>Home</b>
          </div>
          <div
            className="mx-3"
            onClick={() => navigate('/about')}
            style={{ color: '#3b638c', cursor: 'pointer' }}
          >
            <b>About DLP</b>
          </div>
          <div
            onClick={() => navigate('/faq')}
            style={{ color: '#3b638c', cursor: 'pointer' }}
          >
            <b>FAQ</b>
          </div>
          <div
            className="btn btn-radius  sign-out"
            style={{ cursor: 'pointer' }}
            onClick={() => logout()}
          >
            <div className="signout-btn">Sign out</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickyHeader;
