import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TopCrumb from '../components/TopCrumb';
import questionApi from '../services/questionActions';
import { Result, message } from 'antd';
import jsPDF from 'jspdf';
import moment from 'moment';
import { checkUserSession } from '../reducer/userReducer';

import certificate1 from '../images/certs/DLP22-Certificate-FA-01.jpg';
import certificate2 from '../images/certs/DLP22-Certificate-FA-02.jpg';
import certificate3 from '../images/certs/DLP22-Certificate-FA-03.jpg';
import certificate4 from '../images/certs/DLP22-Certificate-FA-04.jpg';
import certificate5 from '../images/certs/DLP22-Certificate-FA-05.jpg';
import certificate6 from '../images/certs/DLP22-Certificate-FA-06.jpg';
import certificate7 from '../images/certs/DLP22-Certificate-FA-07.jpg';
import certificate8 from '../images/certs/DLP22-Certificate-FA-08.jpg';
import { useSelector } from 'react-redux';
import { certificateCheckModuleCompletion } from '../services/helperFunc';
import { useDispatch } from 'react-redux';

// const mods = {
//   1: '2024/0027',
//   2: '2024/0028',
//   3: '2024/0029',
//   4: '2024/0030',
//   5: '2024/0032',
//   6: '2024/0033',
//   7: '2024/0034',
//   8: '2024/0035',
// };

const allCerts = [
  '',
  certificate1,
  certificate2,
  certificate3,
  certificate4,
  certificate5,
  certificate6,
  certificate7,
  certificate8,
];

function Certificate() {
  //
  const dispatch = useDispatch();

  const { user, module } = useSelector((state) => state.user);
  const [completedModule, setCompletedModule] = useState('');
  const [notCompletedModule, setNotCompletedModule] = useState('');
  const [searchParams] = useSearchParams();
  const curModule = searchParams.get('module');

  useEffect(() => {
    dispatch(checkUserSession());
  }, [dispatch, curModule]);

  useEffect(() => {
    const getCompletedModules = async () => {
      try {
        const res = await questionApi.getModuleCompletion();
        if (res) {
          // console.log('res.data.data', res.data.data);
          const completedModules = res.data.data;
          certificateCheckModuleCompletion(
            completedModules,
            module,
            setNotCompletedModule,
            setCompletedModule
          );
        }
      } catch (error) {
        if (error.response?.data.error) {
          message.error(error.response?.data.error);
        }
      }
    };
    if (curModule && module) {
      getCompletedModules();
    }
  }, [curModule, module]);

  const generatePDF = (userName, certificate) => {
    // Create a new instance of jsPDF
    const doc = new jsPDF('portrait', 'px', 'a4');

    // Load the image onto the document
    const imageWidth = 447; // Desired width of the image in the PDF
    doc.addImage(certificate, 'JPEG', 0, 0, imageWidth, 628); // Adjust the position and size as per your requirement
    doc.setFontSize(20);
    doc.setTextColor('#3b628d'); // Set the text color (here, it's red)
    doc.setFont('PT Serif');
    doc.text(userName, doc.internal.pageSize.getWidth() / 2, 240, {
      align: 'center',
    });

    const convertedDate = moment(completedModule.assessment_completion).format(
      'DD/MM/YYYY'
    );

    doc.setTextColor('#fff');
    doc.setFont('Poppins');
    doc.text(convertedDate, doc.internal.pageSize.getWidth() / 2, 470, {
      align: 'center',
    });
    // Set the text color (here, it's red)
    // Save the PDF as a file
    doc.save(`${userName}-${module.title}-certificate.pdf`);
  };

  async function onSubmit() {
    await generatePDF(user.name, allCerts[curModule]);
  }

  // const UserTypeChecker = () => {
  //   //
  //   if (user?.mmc_number) {
  //     // return relation div
  //     return (
  //       <div>
  //         <div style={{ textDecoration: 'underline' }}>MMA CPD</div>
  //         <ul>
  //           <li>This module has been accorded with 1 CPD point.</li>
  //           <li>Your completion will be submitted to MMA for record.</li>
  //         </ul>
  //       </div>
  //     );
  //   }

  //   if (user?.mps_number) {
  //     // return relation div
  //     return (
  //       <div>
  //         <div style={{ textDecoration: 'underline' }}>MPS CPD</div>

  //         <ul>
  //           <li>This module has been accorded with 1 CPD point.</li>
  //           <li>
  //             Please log into the MPS CPD website to claim.
  //             <div>
  //               {' '}
  //               Picklist code: <b>{mods[curModule]}</b>{' '}
  //             </div>
  //           </li>
  //           <li>
  //             All the event details are already uploaded on the pick list. You
  //             may refer to the video provided on the MPS website on how to claim
  //             CPD points.
  //           </li>
  //           <li>Your completion will also be submitted for record. </li>
  //         </ul>
  //       </div>
  //     );
  //   }

  //   if (user?.myCpd_number) {
  //     return (
  //       <div>
  //         <div style={{ textDecoration: 'underline' }}>MyCPD</div>
  //         <ul>
  //           <li> This module has been accorded with 1 CPD point.</li>
  //           <li>
  //             Please log into the MyCPD website to claim the points for this
  //             event.
  //           </li>

  //           <li>
  //             All modules are already uploaded accordingly under the event name
  //             ‘2024 Diabetes Lifestyle Programme (DLP) Online Course.’
  //           </li>
  //           <li>Your completion will also be submitted for record. </li>
  //         </ul>
  //       </div>
  //     );
  //   }
  // };

  const RenderUpdatedInfo = () => {
    return (
      <div>
        <div>
          <div style={{ textDecoration: 'underline' }}>MMA CPD</div>
          <ul>
            <li>
              Your completion will be submitted to MMA for record on a monthly
              basis.
            </li>
          </ul>
        </div>

        <div className="py-4">
          <div style={{ textDecoration: 'underline' }}>MPS CPD</div>

          <ul>
            <li>This event is recorded under the Pick List.</li>
            <li>You may log into the MPS CPD website to claim.</li>
            <li>
              Your completion will also be submitted to MPS for record on a
              monthly basis.
            </li>
          </ul>
        </div>

        <div>
          <div style={{ textDecoration: 'underline' }}>MyCPD</div>
          <ul>
            <li> The MyCPD portal is currently under maintenance.</li>
            <li>
              You may use the e-certificate as proof of completion to claim CPD
              points once the portal is available.
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <div className="container">
        <div className="py-4">
          <TopCrumb className="py-4" module={module} type="Certificate" />
        </div>

        {notCompletedModule ? (
          <Result status="warning" title={notCompletedModule} />
        ) : (
          <div className="d-flex flex-column flex-md-row justify-content-around ">
            <div className="mt-4 text-white certificate-left-text-container ">
              <div>
                <div className="congratulation-text">
                  Congratulations on
                  <div style={{ textTransform: 'lowercase' }}>
                    completing {module.title}!
                  </div>
                </div>
                <div className="certificate-text mt-4">
                  Here is your e-certificate for this module. This module has
                  been accorded with 1 CPD point.
                </div>
                <div className="pt-4">
                  <RenderUpdatedInfo />
                </div>
              </div>
            </div>
            <div
              className="mt-4 position-relative text-center"
              style={{ minWidth: '375px', width: '375px' }}
            >
              <div>
                <img
                  alt="cert"
                  style={{ width: '100%' }}
                  src={allCerts[curModule]}
                />
              </div>

              <div
                style={{
                  position: 'absolute',
                  top: '31%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  fontSize: '10px',
                  fontStyle: 'PT Serif',
                }}
              >
                {user?.name || ''}
              </div>

              <div
                style={{
                  position: 'absolute',
                  top: '63%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  fontSize: '8px',
                  fontStyle: 'PT Serif',
                  color: '#fff',
                }}
                className="small-text-size"
              >
                {moment(completedModule?.assessment_completion).format(
                  'DD/MM/YYYY'
                )}
              </div>
              <div className="my-3 text-center">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  style={{
                    borderRadius: '25px',
                    padding: '10px 25px',
                    backgroundColor: '#f0d747',
                    color: '#3b638c',
                  }}
                  onClick={onSubmit}
                >
                  <b>DOWNLOAD</b>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Certificate;
