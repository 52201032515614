import React, { useEffect, useState } from 'react';
import { Input, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import fetch from 'node-fetch';

function SurveyPreSection4() {
  const location = useLocation();
  const navigate = useNavigate();
  const [answerData, setAnswerData] = useState(location.state?.answerData);
  const [question, setQuestion] = useState(''); // eslint-disable-line no-unused-vars
  const [other, setOther] = useState(null);
  const [otherValue, setOtherValue] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/survey/surveys?section=4&type=pre', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const { data } = await response.json();
        if (data.length) {
          setQuestion(data[0]);
        } else {
          setQuestion(data);
        }
      } catch (error) {
        // console.error('Error submitting survey:', error.response.data);
        if (error.response?.data) {
          message.error('An error occurred. Please try again.');
        }
      }
    };

    fetchData();
  }, [navigate]);

  // console.log('answerData', answerData);

  const handleChange = (option) => {
    if (option.details.includes('Others')) {
      setOther(true);
      setOtherValue(option);
    }

    const existingAns = answerData.answers.find(
      (each) => each.option_id === option.id
    );

    if (!existingAns) {
      let newAnswer = {
        survey_question_id: question.id,
        option_id: option.id,
      };

      setAnswerData({
        ...answerData,
        answers: [...answerData.answers, newAnswer],
      }); // Add the new answer to the answers array
    }
  };

  // console.log('answerData', answerData);

  const unSelectAnswer = (option) => {
    if (option.details.includes('Others')) {
      setOther(false);
    }

    const existingAns = answerData.answers.find(
      (each) => each.option_id === option.id
    );
    if (existingAns) {
      const updatedAnswers = answerData.answers.filter(
        (each) => each.option_id !== option.id
      );
      setAnswerData({ ...answerData, answers: updatedAnswers }); // Update only the answers array
    }
  };

  const updateOtherValue = (e) => {
    const updatedAnswers = answerData.answers.map((each) => {
      if (each.option_id === otherValue.id) {
        each.details = e;
        return each;
      } else {
        return each;
      }
    });

    setAnswerData({ ...answerData, answers: updatedAnswers }); // Update only the answers array
  };

  // console.log('other', other, answerData);

  return (
    <div style={{ overflowX: 'auto' }}>
      <div className="margin-big-width primary-text-color">
        <div className="survey-title pt-5 pb-3">Take a Survey</div>
        <div>
          <div style={{ overflow: 'hidden' }} className="w-100 questions-bg">
            <div>
              <span>4. </span>
              {question?.description}
            </div>
            <div className="options" style={{ marginTop: '20px' }}>
              {question?.options
                ?.sort((a, b) => a.sequence - b.sequence)
                .map((option) => (
                  <div key={option.id} className="ms-3">
                    <label>
                      <div className="d-flex">
                        <input
                          className="align-self-start radio-btn"
                          type="radio"
                          name={'question' + option.id}
                          value={option.id}
                          onChange={() => handleChange(option)}
                          onClick={() => unSelectAnswer(option)}
                          checked={
                            answerData.answers.find(
                              (each) => each.option_id === option.id
                            )
                              ? true
                              : false
                          }
                        />
                        <div style={{ textAlign: 'start' }}>
                          {option.details}
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
            </div>

            {other ? (
              <div className="ms-4 w-50">
                <Input
                  onChange={(e) => {
                    updateOtherValue(e.target.value);
                  }}
                  type="text"
                ></Input>
              </div>
            ) : null}
          </div>

          <div className=" d-flex justify-content-center">
            <button
              className="btn btn-radius btn-yellow-bg px-5 font-weight-boldest dark-blue-title btn-next"
              onClick={() => {
                if (answerData.answers.length < 8) {
                  message.error('Please select an option to continue');
                } else {
                  if (other) {
                    const existValue = answerData.answers
                      .filter((each) => each.survey_question_id === '8')
                      .find((each) => each.details);

                    if (!existValue) {
                      return message.error('Please specify!');
                    }
                  }
                  navigate('/survey/pre/five', {
                    state: {
                      data: location.state?.data,
                      answerData,
                      answerLength: answerData.answers.length,
                    },
                  });
                }
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SurveyPreSection4;
