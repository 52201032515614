import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import SurveyRating1to5 from '../../../components/SurveyRating1to5';

function SurveyPostSection2() {
  const navigate = useNavigate();
  const location = useLocation();
  const [questions, setQuestions] = useState('');
  const [answerData, setAnswerData] = useState(location.state);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          '/api/survey/surveys?section=2&type=post',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const { data } = await response.json();
        if (data.length) {
          setQuestions(data);
        } else {
          setQuestions(data);
        }
      } catch (error) {
        // console.error('Error submitting survey:', error.response.data);
        if (error.response.data) {
          message.error('An error occurred. Please try again.');
        }
      }
    };

    fetchData();
  }, [navigate]);

  // console.log('question', questions);

  // console.log(answerData);

  return (
    <div className="container">
      <div className="mt-4">
        <div className="margin-big-width pb-5 mt-5">
          <div>
            <div style={{ overflow: 'hidden' }} className="w-100 questions-bg">
              <div className="mx-5">
                <span>2.</span> Kindly rate your confidence level in advising
                patients about making diabetes lifestyle change.
              </div>
              <div className="options" style={{ marginTop: '20px' }}>
                {questions?.length &&
                  questions
                    .sort((a, b) => a.sequence - b.sequence)
                    .map((question, idx) => (
                      <div key={idx}>
                        <SurveyRating1to5
                          setAnswerData={setAnswerData}
                          answerData={answerData}
                          index={question.id}
                          description={question.description}
                          options={question.options}
                          leftDescription={'Not Confident At All'}
                          rightDescription={'Very Confident'}
                        />
                      </div>
                    ))}
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <button
                className="btn btn-radius btn-yellow-bg px-5  dark-blue-title btn-next"
                onClick={() => {
                  if (answerData.answers.length < 2) {
                    message.error('Please select an option to continue');
                  } else if (answerData.answers.length < 6) {
                    message.error('Please select all options to continue');
                  } else {
                    navigate('/survey/post/three', { state: answerData });
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SurveyPostSection2;
