import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import hamburgerIcon from '../assets/hamburger.png';
import dlpDroplet from '../assets/dlp_logo_dropLet.png';
import userApi from '../services/userActions';
import { message } from 'antd';
import { useSelector } from 'react-redux';

function Sidebar({
  modulesLength,
  module,
  handleNext,
  handlePrevious,
  currentIndex,
  isCollapsed,
  toggleCollapse,
}) {
  const navigate = useNavigate();

  const location = useLocation();
  const path = location.pathname;

  const { user } = useSelector((state) => state.user);

  const logout = async (user) => {
    if (!user) {
      navigate('/login');
    }
    try {
      const logoutUser = await userApi.logout();
      if (logoutUser) {
        localStorage.removeItem('dlp-hcp-doctor-token');
        navigate('/login');
      }
    } catch (error) {
      // console.log('error', error.response?.data.error);
      if (error.response?.data.error) {
        message.error(error.response?.data.error);
      }
    }
  };
  // console.log('path', path);
  return (
    <div className="">
      <div className="top-sidebar">
        <div className="d-flex justify-content-between w-100">
          <div></div>
          <div className="d-flex align-items-center">
            <div
              className="mx-1"
              onClick={() => navigate('/modules')}
              style={{ color: '#3b638c', cursor: 'pointer' }}
            >
              <b className="small-font-mobile">Home</b>
            </div>
            <div
              className="mx-3"
              onClick={() => navigate('/about')}
              style={{ color: '#3b638c', cursor: 'pointer' }}
            >
              <b className="small-font-mobile">About DLP</b>
            </div>
            <div
              onClick={() => navigate('/faq')}
              style={{ color: '#3b638c', cursor: 'pointer' }}
            >
              <b className="small-font-mobile">FAQ</b>
            </div>
            <div
              className="btn btn-radius px-4 sign-out"
              onClick={() => logout(user)}
            >
              <div className="signout-btn small-font-mobile">
                {' '}
                {user ? 'Sign Out' : 'Sign In'}{' '}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}
        style={{ zIndex: '10' }}
      >
        <button
          className="hamburger"
          aria-label="Open sidebar menu"
          onClick={toggleCollapse}
        >
          <span>
            <img
              className="mb-3"
              style={{ width: '20px' }}
              src={hamburgerIcon}
              alt="hamburger"
            />
          </span>
        </button>
        <div onClick={() => navigate('/modules')}>
          <img
            className="pos-abs drop-shadow side-logo"
            style={{ marginTop: '21px' }}
            src={dlpDroplet}
            alt="hambdlpDropleturger"
          />
        </div>
        <h3 className="sidebar-item mt-150 border-rad-tr border-rad-br">
          <b> MODULE {currentIndex}</b>
        </h3>
        <div className="sidebar-parent">
          <div
            className={
              path === '/video'
                ? 'sidebar-item-active'
                : 'sidebar-hover sidebar-item '
            }
            onClick={() => navigate(`/video?module=${currentIndex}`)}
            style={{ cursor: 'pointer' }}
          >
            <b>Video</b>
          </div>

          {currentIndex == 0 ? null : (
            <>
              <div
                className={
                  path === '/assessment'
                    ? 'sidebar-item-active'
                    : 'sidebar-hover sidebar-item '
                }
                onClick={() => navigate(`/assessment?module=${currentIndex}`)}
                style={{ cursor: 'pointer' }}
              >
                <b>Assessment</b>
              </div>

              <div
                className={
                  path === '/assessment/answer'
                    ? 'sidebar-item-active'
                    : 'sidebar-hover sidebar-item '
                }
                onClick={() =>
                  navigate(`/assessment/answer?module=${currentIndex}`)
                }
                style={{ cursor: 'pointer' }}
              >
                <b>Assessment Answer</b>
              </div>

              <div
                id="certificate"
                className={
                  path === '/certificate'
                    ? 'sidebar-item-active'
                    : 'sidebar-hover sidebar-item '
                }
                onClick={() => navigate(`/certificate?module=${currentIndex}`)}
                style={{ cursor: 'pointer' }}
              >
                <b>Certificate</b>
              </div>
              <div
                id="materials"
                className={
                  path === '/resources'
                    ? 'sidebar-item-active'
                    : 'sidebar-hover sidebar-item '
                }
                onClick={() =>
                  currentIndex === 2
                    ? console.log('')
                    : navigate(`/resources?module=${currentIndex}`)
                }
                style={{ cursor: currentIndex === 2 ? 'text' : 'pointer' }}
              >
                {currentIndex === 2 ? (
                  <b style={{ color: 'rgba(0, 0, 0, 0.3)' }}>Resources</b>
                ) : (
                  <b>Resources</b>
                )}
              </div>
            </>
          )}

          <div
            className={
              path === '/ask-the-expert'
                ? 'sidebar-item-active'
                : 'sidebar-hover sidebar-item '
            }
            onClick={() => navigate(`/ask-the-expert?module=${currentIndex}`)}
            style={{ cursor: 'pointer' }}
          >
            <b>Ask The Experts</b>
          </div>
          <a
            id="patient"
            className="sidebar-hover sidebar-item"
            href="https://previewcontent.mydlp.my/"
            style={{ textDecoration: 'none' }}
          >
            <b>Preview Patient Module</b>
          </a>
          <div className="ms-2">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              style={{
                borderRadius: '25px',
                padding: '10px 25px',
                backgroundColor: '#f0d747',
                color: '#3b638c',
              }}
              onClick={handlePrevious}
              disabled={currentIndex === 0}
            >
              <b>PREVIOUS</b>
            </button>

            <button
              type="button"
              className="ms-2 btn btn-primary btn-sm"
              style={{
                borderRadius: '25px',
                padding: '10px 25px',
                backgroundColor: '#f0d747',
                color: '#3b638c',
              }}
              onClick={handleNext}
              disabled={currentIndex === modulesLength - 1}
            >
              <b>NEXT</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
